import { ServiceCard as _ServiceCard } from "@2po-dpam/components";
import { RichText } from "@components";
import { graphql } from "gatsby";
import React from "react";

type ServiceCardData = {
    icon: {
        icon: string;
    };
    name: string;
    description: {
        text: {
            raw: string;
        };
    };
};

type Props = {
    data: ServiceCardData;
};

const ServiceCard = ({ data: { icon, name, description } }: Props) => (
    <_ServiceCard
        description={<RichText textNode={description.text} />}
        icon={icon.icon}
        title={name}
    />
);

export default ServiceCard;

export const query = graphql`
    fragment ServiceCard on ContentfulTopicService {
        id
        __typename
        contentful_id
        name
        icon {
            icon
        }
        description {
            text {
                raw
            }
        }
    }
`;
