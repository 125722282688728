import { IconNumberCard as Card } from "@2po-dpam/components";
import { Image, RichText } from "@components";
import { graphql } from "gatsby";
import React from "react";

import { type ContentfulImageData } from "../Image";

type Props = {
    data: {
        number?: string;
        icon?: {
            icon: string;
        };
        image?: ContentfulImageData;
        text?: {
            text: {
                raw: string;
            };
        };
    };
    options?: any;
};

const IconNumberCard = ({
    data: { number, icon, image, text },
    options,
}: Props) => {
    const _Image = image ? (
        <Image
            alt={image?.alt}
            image={image?.image}
            src={image?.image?.url || image?.image?.file?.url}
        />
    ) : null;

    return (
        <Card
            icon={icon?.icon}
            image={_Image}
            noMargin={options?.columnSection}
            number={number}
        >
            <RichText textNode={{ text: text?.text }} />
        </Card>
    );
};

export default IconNumberCard;

export const query = graphql`
    fragment IconNumberCard on ContentfulAssemblyNumberedCard {
        id
        __typename
        contentful_id
        number
        icon {
            icon
        }
        image {
            ...ImageThumbnail
        }
        text {
            ...RichText
        }
    }
`;
