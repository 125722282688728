import { Investor } from "@types";

export const INVESTORS = {
    RETAIL: "retail",
    INTERMEDIARY: "professional-intermediary",
    END_INVESTOR: "professional-end-investor",
};

export const INVESTORS_ARR = Object.values(INVESTORS) as Investor[];

export const INVESTOR_MAP = {
    retailContent: INVESTORS.RETAIL,
    professionalIntermediaryContent: INVESTORS.INTERMEDIARY,
    professionalEndInvestorContent: INVESTORS.END_INVESTOR,
} as { [key in string]: Investor };
