import { Carousel, MediaGrid, Section as _Section } from "@2po-dpam/components";
import { CONTENT_TYPES } from "@constants";
import { useRichText } from "@hooks";
import { IMediaGridSection } from "@types";
import { componentMapper, shortenTypename } from "@utils/componentMapper";
import { kebabCase } from "@utils/kebabCase";
import { removeKeyFromObject } from "@utils/objects";
import { graphql } from "gatsby";
import React from "react";

import * as styles from "./style.module.scss";

type Props = {
    data: IMediaGridSection;
};

const MediaGridSection = ({
    data: { title, description, id, anchorLinkText, content },
}: Props) => {
    const { handleTitle, handleDescription } = useRichText();

    const media = content.map(entry => {
        const mediaOnly = removeKeyFromObject(
            "caption",
            removeKeyFromObject("title", entry),
        );

        const additionalProps =
            shortenTypename(entry) === CONTENT_TYPES.VIDEO
                ? {
                      buttonPosition: "bottomLeft",
                      variableHeight: true,
                  }
                : {};

        return componentMapper(mediaOnly, {
            className: styles.imageWrapper,
            ...additionalProps,
        });
    });

    return (
        <>
            <_Section
                anchorLink={kebabCase(anchorLinkText || id)}
                description={handleDescription(description)}
                title={handleTitle(title)}
            >
                <MediaGrid className={styles.mediaGridSection} media={media} />
                <Carousel className={styles.carousel}>{media}</Carousel>
            </_Section>
        </>
    );
};

export default MediaGridSection;

export const query = graphql`
    fragment MediaGridSection on ContentfulAssemblyMediaGridSection {
        id
        __typename
        anchorLink
        title {
            raw
        }
        description {
            ...RichText
        }
        content {
            ...ImageFullWidth
            ...Video
        }
    }
`;
