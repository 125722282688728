import { SimpleNewsCard as _SimpleNewsCard } from "@2po-dpam/components";
import Image, { type ContentfulImageData } from "@components/Image";
import RichText from "@components/RichText";
import { useContextStore, useInvestorContentWithSlug } from "@hooks";
import { Link as GatsbyLink, graphql } from "gatsby";
import React from "react";

import { getPublishDateLongDateFormatted } from "@utils/pages";
import * as style from "./style.module.scss";

type Content = {
    updatedAt: string;
    publishDate: string;
    shortTitle: {
        raw: string;
    };
    hero: {
        backgroundImage: ContentfulImageData;
    };
};

type Props = {
    data: {
        id: string;
        __typename: string;
        contentful_id: string;
        generalContent: Content;
        professionalIntermediaryContent: {
            content: Content;
        };
        retailContent: {
            content: Content;
        };
        professionalEndInvestorContent: {
            content: Content;
        };
    };
    preview?: boolean;
    options?: any;
};

const SimpleNewsCard = ({ data, preview, options }: Props) => {
    const { language } = useContextStore();
    const invContent = useInvestorContentWithSlug(data);

    const content = preview ? data : invContent;
    const image = content?.hero?.backgroundImage;
    const publicationDate = getPublishDateLongDateFormatted(
        content.publishDate,
        content.createdAt,
        content.updatedAt,
        language,
    );
    return (
        <>
            {content && (
                <GatsbyLink className={style.link} to={content.destination}>
                    <_SimpleNewsCard
                        hideContentMobile={options?.hideContentMobile}
                        image={
                            options?.renderedInList ? null : (
                                <Image
                                    {...image}
                                    alt={image.alt || "News alt"}
                                    caption={null}
                                    title={null}
                                />
                            )
                        }
                        publishDate={publicationDate}
                        title={
                            <RichText
                                options={{
                                    variant: "card-title",
                                    inline: true,
                                    noRichStyling: true,
                                }}
                                textNode={content.shortTitle}
                            />
                        }
                    />
                </GatsbyLink>
            )}
        </>
    );
};

export const query = graphql`
    fragment SimpleNewsCard on ContentfulPageTypeNewsOrAnnouncementPage {
        id
        contentful_id
        __typename
        generalContent {
            ...SimpleNewsCardContent
        }
        professionalEndInvestorContent {
            content {
                ...SimpleNewsCardContent
            }
        }
        professionalIntermediaryContent {
            content {
                ...SimpleNewsCardContent
            }
        }
        retailContent {
            content {
                ...SimpleNewsCardContent
            }
        }
    }

    fragment SimpleNewsCardContent on ContentfulPageContentNewsAndAnnouncements {
        hero {
            ...Hero
        }
        shortTitle {
            raw
        }
        publishDate
        createdAt
        updatedAt
    }
`;
export default SimpleNewsCard;
