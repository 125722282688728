import { SubAssetCard as _SubAssetCard } from "@2po-dpam/components";
import Image, { ContentfulImageData } from "@components/Image";
import RichText from "@components/RichText";
import { graphql } from "gatsby";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import React from "react";

import * as style from "./style.module.scss";

type SubAssetCardData = {
    id: string;
    image: ContentfulImageData;
    text: {
        text: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    };
};

type Props = {
    data: SubAssetCardData;
    options?: any;
};

const SubAssetCard = ({ data: { id, text, image }, options }: Props) => {
    if (!text) return null;

    const imageEl = <Image {...image} alt={image?.alt || "Card image"} />;
    return (
        <_SubAssetCard
            ImageComponent={imageEl}
            className={options?.className}
            id={id}
            stretch={options.stretch}
        >
            <RichText
                options={{
                    embedClassNames: {
                        callToAction: style.embeddedCta,
                    },
                }}
                textNode={text}
            />
        </_SubAssetCard>
    );
};

export const query = graphql`
    fragment SubAssetClassCard on ContentfulAssemblySubAssetClassCard {
        id
        __typename
        image {
            ...ImageFullWidth
        }
        text {
            ...RichText
        }
    }
`;

export default SubAssetCard;
