export const translations = {
    categories: {
        current_emissions: "Current emissions",
        projected_emissions: "Projected emissions",
        net_zero: "Net zero target",
        adaptation: "Adaptation",
        means_of_implementation: "Means of implementation",
        multilateral_transparency: "Multilateral transparency",
        ndc_gap_2022: "NDC alignment gap",
        paris_gap_2022: "Paris alignment gap",
        fair_gap_2022: "Fair share alignment gap",
        ndc_gap_2030: "Projected NDC alignment gap",
        paris_gap_2030: "Projected Paris alignment gap",
        fair_gap_2030: "Projected fair share alignment gap",
        target_year: "Target year",
        gas_coverage: "Gas coverage",
        legal_status: "Legal status",
        nap: "National adaptation plan",
        cra: "Climate risk assessment",
        mre: "Monitoring and reporting",
        climate_finance: "Climate finance",
        capacity_building: "Capacity building",
        technology_transfer: "Technology transfer",
        completeness: "Completeness of reports",
        transparency: "Transparency of reports",
        on_schedule: "Timeliness of reports",
        overall_score: "Overall score",
        overall_rank: "Overall rank",
    },
    // Add other translation categories as needed
};
