import { ThumbnailLink as _ThumbnailLink } from "@2po-dpam/components";
import { Image, RichText } from "@components";
import { THEMES } from "@constants";
import { usePageContext, useRouting } from "@hooks";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import React from "react";

import * as style from "./style.module.scss";

type Props = {
    data: any;
};

const ThumbnailLink = ({ data }: Props) => {
    const { getPathForSlug } = useRouting();
    const destination = getPathForSlug(data?.link?.pageLink?.slug);
    const { pages } = usePageContext();
    const destinationPage = pages.find(
        page => page.slug === data.link.pageLink.slug,
    );
    const themeClass = destinationPage?.pageTheme
        ? THEMES[destinationPage.pageTheme]
        : null;
    const thumbnail = (
        <Image
            {...data.thumbnailImage}
            style={{ height: "100%", width: "100%" }}
        />
    );
    return (
        <_ThumbnailLink
            LinkComponent={Link}
            className={classNames(style[themeClass])}
            destination={destination}
            thumbnail={thumbnail}
        >
            <RichText
                options={{ noParagraphWrapper: true }}
                textNode={data.text}
            />
        </_ThumbnailLink>
    );
};

export default ThumbnailLink;

export const query = graphql`
    fragment ThumbnailLink on ContentfulNavigationThumbnailWLink {
        id
        updatedAt
        contentful_id
        __typename
        thumbnailImage {
            ...ImageThumbnail
        }
        text {
            raw
        }
        link {
            pageLink {
                slug
            }
        }
    }
`;
