import { useEffect, useState } from "react";

const useHasScrolled = (position = 50) => {
    const [hasScrolled, setHasScrolled] = useState(false);

    const checkScrollPosition = () => {
        if (typeof window !== "undefined") {
            setHasScrolled(window.scrollY > position);
        }
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            checkScrollPosition();
            window.addEventListener("scroll", checkScrollPosition);

            // cleanup function
            return () => {
                window.removeEventListener("scroll", checkScrollPosition);
            };
        }
        // eslint-disable-next-line
    }, []);

    return [hasScrolled];
};

export default useHasScrolled;
