import { HrInfoCard as _HrInfoCard } from "@2po-dpam/components";
import { RichText } from "@components";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import React from "react";

type Props = {
    data: {
        number: string;
        title: { text: any };
        content: any;
    };
    options?: {
        margins?: {
            numberMargin: string;
            titleMargin: string;
        };
        ref?: any;
        [x: string]: any;
    };
};
const HrInfoCard = ({ data: { title, number, content }, options }: Props) => {
    const titleEl = !isEmptyDocument(title) ? (
        <RichText options={{ noParagraphWrapper: true }} textNode={title} />
    ) : null;
    const contentEl =
        content && !isEmptyDocument(content.text) ? (
            <RichText textNode={content} />
        ) : null;

    return (
        <_HrInfoCard
            content={contentEl}
            number={number}
            ref={options?.ref}
            title={titleEl}
            {...options?.margins}
        />
    );
};

export default HrInfoCard;

export const query = graphql`
    fragment HrInfoCard on ContentfulContentHrInformation {
        content {
            ...RichText
        }
        title {
            raw
        }
        number
        contentful_id
        __typename
        id
    }
`;
