import { CTARound, Hero as _Hero } from "@2po-dpam/components";
import { ErrorBoundary, RichTextReset } from "@components";
import { NewsType } from "@constants";
import { mapContentfulNewsType } from "@utils/newsTypes";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useScrollNextSection } from "@hooks";
import * as style from "./style.module.scss";

type Category = {
    mainCategory: string;
    subCategory: string;
    angleHomepage: {
        slug: string;
    };
};

type Props = {
    data: any;
    landingPage?: boolean;
    categories?: Category[];
    newsType?: NewsType[];
    underline?: boolean;
};

const Hero = ({
    data,
    landingPage,
    categories,
    newsType,
    underline,
}: Props) => {
    const heroRefContainer = useRef<HTMLDivElement>(null);
    const { handleScroll } = useScrollNextSection(heroRefContainer);
    const { t } = useTranslation();
    const category = categories?.[0].mainCategory;
    const mappedNewsType = mapContentfulNewsType(newsType) || "";

    return (
        <div className={style.heroContainer} ref={heroRefContainer}>
            <_Hero
                contentClassName={style.heroContent}
                description={
                    !isEmptyDocument(data?.description) && (
                        <RichTextReset
                            className={style.inheritColor}
                            text={data.description}
                        />
                    )
                }
                dimmed={data?.enableDarkerOverlay}
                image={
                    data?.backgroundImage?.image?.gatsbyImageData ? (
                        <GatsbyImage
                            alt={data?.backgroundImage?.alt || ""}
                            image={data.backgroundImage.image.gatsbyImageData}
                            objectPosition="center top"
                        />
                    ) : (
                        <img src={data?.backgroundImage?.image?.url} />
                    )
                }
                landingPage={landingPage}
                subtitle={category || t(mappedNewsType)}
                title={
                    !isEmptyDocument(data?.title) && (
                        <RichTextReset
                            className={style.inheritColor}
                            text={data.title}
                        />
                    )
                }
                underline={underline}
                wrapperClassName={style.hero}
            />
            <CTARound
                className={style.scrollButton}
                color="white"
                iconSize={30}
                onClick={handleScroll}
                variant="outlined"
            />
        </div>
    );
};

const WrappedHero = (props: Props) => (
    <ErrorBoundary componentName="Hero">
        <Hero {...props} />
    </ErrorBoundary>
);

export const query = graphql`
    fragment Hero on ContentfulAssemblyHero {
        __typename
        id
        title {
            raw
        }
        description {
            raw
        }
        enableDarkerOverlay
        backgroundImage {
            alt
            image {
                gatsbyImageData(
                    layout: FULL_WIDTH
                    outputPixelDensities: 2
                    placeholder: BLURRED
                )
                mimeType
                height
                width
                url
            }
        }
    }
`;

export default WrappedHero;
