import { graphql } from "gatsby";
import React, { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

const PageContent = ({ children }: Props) => <main>{children}</main>;

export default PageContent;

export const query = graphql`
    fragment GeneralContent on Node {
        ...OverlapSection
        ...Section
        ...SplitSection
        ...ColumnsSection
        ...AngleCollectionSection
        ...DocumentSection
        ...NewsCollectionSection
        ...CookieSection
        ...HighlightedArticleSection
        ...LatestArticlesSection
        ...SwitchInvestorTypeSection
        ...ImageWithOverlay
        ...ImageWithCtaOverlay
        ...TimelineSection
        ...MediaGridSection
        ...OptionalSection
    }
`;
