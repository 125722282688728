import { SearchAndFilter, Section, Typography } from "@2po-dpam/components";
import { isArrayWithContent } from "@utils/arrays";
import { graphql } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as style from "./style.module.scss";

type DocumentCategory = {
    title: string;
};
type CFDocument = {
    title: string;
    description?: {
        description: string;
    };
    slug: string;
    node_locale: string;
    categories: DocumentCategory[];
};
type DocumentListData = {
    text: string;
    documents: CFDocument[];
    documentCategories: DocumentCategory[];
};

type Props = {
    data: DocumentListData;
};

type Option = { label: string; value: string } | string;

const mapDocumentsToCategories = (
    documents: CFDocument[],
    allowedCategories?: DocumentCategory[],
) => {
    const categoriesMap = new Map<string, CFDocument[]>();
    documents?.forEach(doc => {
        doc.categories.forEach(category => {
            if (!allowedCategories?.some(cat => cat.title === category.title)) {
                return;
            }
            const existingCategory = categoriesMap.get(category.title);
            if (existingCategory) {
                categoriesMap.set(category.title, [...existingCategory, doc]);
            } else categoriesMap.set(category.title, [doc]);
        });
    });
    return categoriesMap;
};

const filterForQueryAndCategory =
    (category: string, query: string) => (doc: CFDocument) => {
        const categoryMatch =
            isArrayWithContent(doc.categories) &&
            doc.categories.some(
                docCategory => !category || docCategory.title === category,
            );
        if (!query) return categoryMatch;

        const regEx = new RegExp(query, "gim");
        const titleMatch = regEx.test(doc?.title);
        const descriptionMatch =
            !!doc.description?.description &&
            regEx.test(doc.description?.description);
        return categoryMatch && (titleMatch || descriptionMatch);
    };

const DocumentListItem = ({
    slug,
    node_locale,
    title: documentTitle,
}: CFDocument) => {
    const { t } = useTranslation();
    const locale = node_locale.replace(/-./g, x => x[1].toUpperCase());
    const documentUrl = `/documents/${slug}-${locale}`;

    return (
        <li key={slug}>
            <div className={style.header}>
                <div className={`${style.icon} icon-file`} />
                <span>{documentTitle}</span>
            </div>
            <span className={style.actions}>
                <a download href={documentUrl}>
                    {t("download")}
                </a>
                <a href={documentUrl} rel="noopener noreferrer" target="_blank">
                    {t("open")}
                </a>
            </span>
        </li>
    );
};

type CategorySectionProps = {
    categoryTitle: string;
    documents?: CFDocument[];
};

const CategorySection = ({
    categoryTitle: title,
    documents,
}: CategorySectionProps) => {
    if (!documents?.length) return null;
    return (
        <Section className={style.documentList} key={title} withBorder>
            <Typography className={style.title} color="darkGrey" variant="h2">
                {title}
            </Typography>
            <ul>
                {documents?.map(doc => (
                    <DocumentListItem key={doc.slug} {...doc} />
                ))}
            </ul>
        </Section>
    );
};

const DocumentList = ({
    data: { documents, text, documentCategories: categories },
}: Props) => {
    const [activeCategory, setActiveCategory] = useState("");
    const [selectableCategories, setSelectableCategories] = useState<Option[]>(
        [],
    );
    const [query, setQuery] = useState("");
    const { t } = useTranslation();

    const [mappedCategories, setMappedCategories] = useState(
        mapDocumentsToCategories(documents, categories),
    );
    const [filteredCategories, setFilteredCategories] =
        useState(mappedCategories);

    useEffect(() => {
        setMappedCategories(mapDocumentsToCategories(documents, categories));
    }, [documents, categories]);

    useEffect(() => {
        const emptyOption = { label: t("all-documents"), value: "" };
        const categoryOptions = Array.from(mappedCategories, ([key]) => ({
            label: key,
            value: key,
        }));
        setSelectableCategories([emptyOption, ...categoryOptions]);
    }, [mappedCategories, t]);

    useEffect(() => {
        if (activeCategory === "") {
            setFilteredCategories(mappedCategories);
        } else if (mappedCategories.has(activeCategory))
            setFilteredCategories(
                new Map().set(
                    activeCategory,
                    mappedCategories.get(activeCategory),
                ),
            );
        else setFilteredCategories(new Map());
    }, [mappedCategories, activeCategory]);

    return (
        <Fragment>
            <Section className={style.filterSection} withBorder>
                <SearchAndFilter
                    dropdownLabel={t("search.documents.dropdownLabel")}
                    onCategoryChange={setActiveCategory}
                    onQueryChange={setQuery}
                    options={selectableCategories}
                    queryLabel={t("search.documents.queryLabel")}
                    queryPlaceholder={t("search.documents.placeholder")}
                    resetQueryLabel={t("search.documents.resetQuery")}
                    showDropdown={selectableCategories.length > 2}
                    title={text}
                />
            </Section>
            {Array.from(filteredCategories.keys()).map(category => (
                <CategorySection
                    categoryTitle={category}
                    documents={filteredCategories
                        .get(category)
                        ?.filter(filterForQueryAndCategory(category, query))}
                    key={category}
                />
            ))}
        </Fragment>
    );
};

export default DocumentList;

export const query = graphql`
    fragment DocumentSection on ContentfulAssemblyDocumentSectionWithSearchFilter {
        id
        __typename
        text
        documents {
            title
            description {
                description
            }
            slug
            node_locale
            categories {
                ... on ContentfulContentDocumentCategory {
                    title
                }
            }
        }
        documentCategories {
            title
        }
    }
`;
