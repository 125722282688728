import {
    Button,
    Icon,
    Input,
    Select,
    Typography,
    Section as _Section,
} from "@2po-dpam/components";
import CallToAction from "@components/CallToAction";
import { ContentfulImageData } from "@components/Image";
import NewsCard from "@components/NewsCard";
import { NewsType } from "@constants";
import { useContextStore, usePageContext, useSearch } from "@hooks";
import { CTAVariations } from "@types";
import { isArrayWithContent } from "@utils/arrays";
import classnames from "classnames";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Button as RsButton } from "reactstrap";

import contentfulTypenames from "@constants/contentfulTypenames";
import { getInvestorSpecificContent } from "@utils/investors";
import { sortPages } from "@utils/pages";
import * as style from "./style.module.scss";

type NewsCard = {
    id: string;
    contentful_id: string;
    generalContent: NewsCardData;
    professionalEndInvestorContent: {
        content: NewsCardData;
    };
    professionalIntermediaryContent: {
        content: NewsCardData;
    };
    retailContent: {
        content: NewsCardData;
    };
};

type NewsCardData = {
    id: string;
    updatedAt: string;
    publishDate: string;
    createdAt: string;
    contentful_id: string;
    shortTitle: {
        raw: string;
    };
    introText: {
        raw: string;
    };
    hero: {
        backgroundImage: ContentfulImageData;
    };
    type: NewsType[];
};

type CFNewsCategory = {
    id: string;
    name: string;
    values: string[];
    page_content__news__and_announcements_: {
        page_type__news__or_announcement__page: NewsCard[];
    }[];
};
type NewsCategory = {
    id: string;
    name: string;
    values: string[];
    newsCards: NewsCard[];
};

type NewsCollectionSectionProps = {
    newsCategories: CFNewsCategory[];
    cta?: {
        cta: any;
        variation?: CTAVariations;
    };
};

type Props = {
    data: NewsCollectionSectionProps;
};

const NewsCollectionSection = ({ data: { newsCategories, cta } }: Props) => {
    const refs = useRef<(HTMLSpanElement | null)[]>([]);
    const lastActiveCategory = useRef<NewsCategory | null>(null);
    const [searchQ, setSearchQ] = useState("");
    const [newsPages, setNewsPages] = useState<any>(null);

    const { t } = useTranslation();
    const { investor, language, market } = useContextStore();
    const { pages } = usePageContext();
    const { lazyLoadSearch, search } = useSearch();

    const categories = newsCategories
        .filter(cat =>
            isArrayWithContent(cat.page_content__news__and_announcements_),
        )
        .map<NewsCategory[]>(category => {
            const newsCards =
                category.page_content__news__and_announcements_.flatMap(
                    pageContent =>
                        pageContent.page_type__news__or_announcement__page[0],
                );

            return [
                {
                    id: category.id,
                    name: category.name,
                    values: category.values,
                    newsCards: newsCards ?? [],
                },
            ];
        })
        .flat();

    const [activeCategory, setActiveCategory] = useState<NewsCategory>(
        categories[0],
    );
    const [totalCardsToShow, setTotalCardsToShow] = useState<number>(6);

    //get all Pages for the search input field
    const allNewsPages = pages
        .filter(
            page =>
                (page.node_locale === `${language}-${market.toUpperCase()}` &&
                    page.pageType?.__typename) ===
                contentfulTypenames.NEWS_PAGE,
        )
        ?.map(page => ({
            ...page,
            pageContent: getInvestorSpecificContent(investor, page.pageType),
        }));

    const newsPagesStore: Record<string, any> = allNewsPages.reduce(
        (store, page) => {
            store[page.contentful_id] = page;
            return store;
        },
        {},
    );

    const clearSearchInput = () => {
        setActiveCategory(categories[0]);
        setNewsPages(null);
        setSearchQ("");
    };

    const handleSearchInput = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const query = e.target.value;
        if (!query) {
            clearSearchInput();
            return;
        }

        const results = await search(query);

        const _newsPages = results
            ?.filter(
                result => result.contentType === contentfulTypenames.NEWS_CARD,
            )
            .map(result => newsPagesStore[result.id]);
        setNewsPages(_newsPages);
        setSearchQ(query);
    };

    const loadMore = () => setTotalCardsToShow(totalCardsToShow + 6);

    useEffect(() => {
        if (!isArrayWithContent(refs.current)) return;
        if (
            !!lastActiveCategory.current &&
            lastActiveCategory.current !== activeCategory
        ) {
            refs?.current?.[totalCardsToShow - 6]?.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
        if (isArrayWithContent(refs.current)) {
            lastActiveCategory.current = activeCategory;
        }
    }, [refs.current.length, totalCardsToShow, activeCategory]);

    useEffect(() => {
        if (!newsPages) return;
        const hasResults = newsPages.length;

        const newsCardsMatch: Map<string, NewsCard> = new Map();
        categories.forEach(category => {
            if (hasResults) {
                for (const page of newsPages) {
                    const founded = category.newsCards.find(newsCard => {
                        return (
                            newsCard?.contentful_id ===
                            page.pageType.contentful_id
                        );
                    });

                    if (!founded) continue;
                    newsCardsMatch.set(founded.id, founded);
                }
            }

            return {
                ...category,
                newsCards: Array.from(newsCardsMatch.values()),
            };
        });

        const allResultsFromAllCategories = {
            ...categories[0],
            newsCards: Array.from(newsCardsMatch.values()),
        };
        // Only update the state if the new activeCategory is different from the current one
        if (
            JSON.stringify(allResultsFromAllCategories) !==
            JSON.stringify(activeCategory)
        ) {
            setActiveCategory(allResultsFromAllCategories);
        }
    }, [categories, newsPages]);

    return (
        <_Section withBorder>
            {/* hidden categories if user is searching */}
            {!searchQ.length && (
                <Select
                    className={`d-block d-xl-none`}
                    items={categories.map(category => ({
                        value: category.name,
                        label: category.name,
                    }))}
                    onChange={id =>
                        setActiveCategory(
                            categories.find(item => item.name === id) ||
                                categories[0],
                        )
                    }
                    value={activeCategory?.name}
                />
            )}
            <div className={style.categories}>
                <ul className="d-none d-xl-block">
                    {!searchQ.length &&
                        activeCategory &&
                        categories.map(category => (
                            <li
                                className={classnames({
                                    [style.activeCategory]:
                                        category.name === activeCategory.name,
                                })}
                                key={category.name}
                            >
                                <RsButton
                                    color="link"
                                    onClick={() => setActiveCategory(category)}
                                    title={category.name}
                                >
                                    {category.name}
                                </RsButton>
                            </li>
                        ))}
                </ul>
                <div className={style.searchAndCta}>
                    <div className={style.searchWrapper}>
                        <Icon
                            className={style.searchIcon}
                            color="darkGrey"
                            name="searchSimple"
                            size={20}
                        />
                        <Input
                            className={style.searchInput}
                            onChange={handleSearchInput}
                            onFocus={lazyLoadSearch}
                            type="search"
                            value={searchQ}
                        />
                        {!!searchQ.length && (
                            <Button
                                className={style.resetButton}
                                iconName="close"
                                iconSize={25}
                                onClick={clearSearchInput}
                                variant="icon"
                            />
                        )}
                    </div>
                    {cta && (
                        <div className={style.ctaWrapper}>
                            <CallToAction
                                data={{ ...cta.cta, variation: cta.variation }}
                                options={{ className: style.cta, inline: true }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Row>
                {activeCategory?.newsCards.length ? (
                    sortPages(
                        activeCategory.newsCards.filter(
                            (newsContent: NewsCard, index: number) =>
                                index < totalCardsToShow && newsContent,
                        ),
                        investor,
                    ).map((newsContent: NewsCard, index: number) => (
                        <Col
                            className={`${style.newsCard} d-flex`}
                            key={index}
                            xs="12"
                        >
                            <NewsCard data={newsContent} />
                            <span
                                ref={(element: HTMLSpanElement | null) => {
                                    refs.current[index] = element;
                                }}
                            />
                        </Col>
                    ))
                ) : (
                    <Typography variant="h4">
                        {t("no results")}
                        <b>{searchQ}</b>.
                    </Typography>
                )}
            </Row>
            {totalCardsToShow < activeCategory?.newsCards?.length && (
                <div className={style.loadMore}>
                    <Button onClick={loadMore} variant="outlined">
                        {t("loadMore")}
                    </Button>
                </div>
            )}
        </_Section>
    );
};

export default NewsCollectionSection;

export const query = graphql`
    fragment NewsCollectionSection on ContentfulAssemblyNewsCollectionSection {
        id
        contentful_id
        __typename
        cta {
            ...EmbeddedCTA
        }
        newsCategories {
            id
            name
            values
            page_content__news__and_announcements_ {
                page_type__news__or_announcement__page {
                    ...NewsCard
                }
            }
        }
    }
`;
