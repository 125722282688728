import { useEffect, useState } from "react";

import useWindowDimensions from "./use-window-dimensions";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { windowWidth } = useWindowDimensions();
    const mobileBreakpoint = 768;

    useEffect(() => {
        let _mobile = false;
        if (windowWidth <= mobileBreakpoint) _mobile = true;
        setIsMobile(_mobile);
    }, [windowWidth]);

    return isMobile;
};

export default useIsMobile;
