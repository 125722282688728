/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import "@2po-dpam/components/build/esm/index.css";
import "./src/styles/index.scss";

export const onInitialClientRender = () => {
    window.scrollTo(0, 0);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (!prevLocation && !location.hash) {
        setTimeout(
            () => window.scrollTo({ top: 0, left: 0, behavior: "instant" }),
            0,
        );
    }
};
const pixelsFromTopUntilItem = 200;
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    window.history.scrollRestoration = "manual";
    const currentPosition = getSavedScrollPosition(location) || [0, 0];

    setTimeout(() => {
        if (location.hash) {
            // If hash (`/#something`), smooth scroll to that position
            const querySelector = `[id='${location.hash}']`.replace("#", "");
            const item = document.querySelector(querySelector);
            if (item) {
                const rect = item.getBoundingClientRect();
                window.scrollTo({
                    top: rect.top + window.pageYOffset - pixelsFromTopUntilItem,
                    behavior: "smooth",
                });
            }
        } else {
            // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
            window.scrollTo({
                top: currentPosition[1],
                left: currentPosition[0],
                behavior: "instant",
            });
        }
    }, 200);

    // do not let gatsby do anything more
    return false;
};
