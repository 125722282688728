import { useLocation } from "@reach/router";
import { ICta, ILink } from "@types";
import { kebabCase } from "@utils/kebabCase";

import useRouting from "./use-routing";

const useLink = (cta?: ICta) => {
    const { getPathForSlug } = useRouting();
    const location = useLocation();

    const getInternalDestination = (internalLink?: ILink) => {
        if (!internalLink) return "";
        const pageLink = getPathForSlug(internalLink.pageLink?.slug);
        const anchor = internalLink?.anchorLink;

        if (anchor) {
            const { anchorLink, id } = anchor as any; // 1 page doesn't support anchorLink atm
            return `${pageLink}#${kebabCase(anchorLink || id)}`;
        }

        return pageLink;
    };

    const getCTADestination = (cta: ICta) => {
        if (location?.pathname && cta?.internalLink?.pageLink?.slug) {
            return getInternalDestination(cta.internalLink);
        }

        return cta.externalLink;
    };

    return {
        getInternalDestination,
        getCTADestination,
        internalLink: getInternalDestination(cta?.internalLink),
        externalLink: cta?.externalLink,
    };
};

export default useLink;
