import { CardIcon as _CardIcon, Icon as _Icon } from "@2po-dpam/components";
import CallToActionModal from "@components/CallToActionModal";
import { type ContentfulImageData } from "@components/Image";
import { IAssemblyEmbeddedCtaModal } from "@types";
import { graphql } from "gatsby";
import React from "react";
import * as style from "./style.module.scss";

export type Props = {
    data: {
        id: string;
        image: ContentfulImageData;
        icon: {
            icon: string;
        };
        title: string;
        button: IAssemblyEmbeddedCtaModal;
    };
};

const CardIcon = ({ data }: Props) => {
    const color = "white";

    const icon = <_Icon color={color} name={data.icon?.icon} size={80} />;

    const btn = <CallToActionModal data={data.button}></CallToActionModal>;
    return (
        <div className={style.cardContainer}>
            <_CardIcon
                backgroundImage={data.image}
                button={btn}
                color={color}
                icon={icon}
                id={data.id}
                title={data.title}
            />
        </div>
    );
};

export default CardIcon;

export const query = graphql`
    fragment EmbeddedCtaModal on ContentfulAssemblyEmbeddedCtaModal {
        id
        __typename
        contentful_id
        cta {
            ...CallToActionModal
        }
        variation
    }

    fragment CardIcon on ContentfulAssemblyCardIcon {
        id
        contentful_id
        __typename
        internalName
        image {
            id
            alt
            image {
                url
                file {
                    fileName
                    url
                }
            }
        }
        title
        icon {
            icon
        }
        button {
            ...EmbeddedCtaModal
        }
    }
`;
