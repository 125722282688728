import { graphql } from "gatsby";
import React from "react";
import CountryRanking from "../CountryRanking";
type Props = {
    type: string;
    basePath: string;
};

const ContentWidget = ({ type, basePath }: Props) => {
    if (type === "Paris Performance Overall Ranking Section") {
        return (
            <div>
                <CountryRanking
                    basePath={basePath}
                    translations={{
                        rank: "Rank",
                        move: "Move",
                        score: "Score",
                    }}
                />
            </div>
        );
    }
    return null;
};

export default ContentWidget;

export const query = graphql`
    fragment ContentWidget on ContentfulAssemblyContentWidget {
        id
        __typename
        internalName
        type
    }
`;
