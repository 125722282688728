import { Button, Icon, SocialButton, Typography } from "@2po-dpam/components";
import { type MetaData } from "@components/PageHead";
import {
    useContextStore,
    useDataLayer,
    useElementScrollThreshold,
    useSiteMetaData,
    useWhitepaper,
} from "@hooks";
import { useLocation } from "@reach/router";
import { isArrayWithContent } from "@utils/arrays";
import { componentMapper } from "@utils/componentMapper";
import { EmailTemplate, interpolateEmail } from "@utils/email";
import { getDateTimeFormatter } from "@utils/INTL";
import { getNewsTypeLabel, mapContentfulNewsType } from "@utils/newsTypes";
import truncate from "@utils/truncate";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, UncontrolledTooltip } from "reactstrap";

import { getPublishDateLongDateFormatted } from "@utils/pages";
import ErrorBoundary from "../ErrorBoundary";
import * as style from "./style.module.scss";

const getTwitterHashtags = (
    tweetText: string,
    keywords: string[],
    limit: number,
): string[] => {
    if (tweetText.length === limit || !isArrayWithContent(keywords)) return [];
    const charactersLeft = limit - tweetText.length;
    let charactersUsed = 0;
    return keywords.filter(word => {
        const fits = word.length <= charactersLeft - charactersUsed;
        charactersUsed += word.length;
        return fits;
    });
};

const TWEET_LIMIT = 100;

type Props = {
    readingTime?: string;
    metaData: MetaData;
    siteName?: string;
    emailTemplate?: EmailTemplate;
    pageContent: any;
    preview?: boolean;
    backlinkPresent?: boolean;
};

const AngleSidebar = ({
    readingTime,
    metaData,
    siteName,
    emailTemplate,
    pageContent,
    preview = false,
    backlinkPresent = false,
}: Props) => {
    const { pushEvent } = useDataLayer();
    const { siteUrl } = useSiteMetaData();
    const { authors, publishDate, updatedAt, whitepaperUrl, createdAt } =
        pageContent;
    const asideRef = useRef<any>(null);
    const shareList = useRef<any>(null);
    const [showTooltip, toggleTooltip] = useState(false);
    const location = useLocation();
    const { language } = useContextStore();
    const { t } = useTranslation();
    const { handle } = useWhitepaper();
    const shareLimit = useElementScrollThreshold(130, shareList);
    const asideLimit = useElementScrollThreshold(130, asideRef);
    const timeFormatter = getDateTimeFormatter(language, "time");
    const newsType = mapContentfulNewsType(pageContent.type) || "";

    const copyPageLink = () => {
        if (typeof navigator === "undefined") return;
        navigator.clipboard.writeText(location.href);
        toggleTooltip(true);
        setTimeout(() => toggleTooltip(false), 1000);
        pushEvent({
            name: "copy-page-url-to-clipboard-of-a-blogpost",
            data: { link_domain: siteUrl, link_url: location.href },
        });
    };

    const twitterText = truncate(
        metaData.description,
        TWEET_LIMIT - (location?.href?.length || 0),
        true,
    );
    const twitterHashTags = getTwitterHashtags(
        twitterText,
        metaData.keywords,
        TWEET_LIMIT - (location?.href?.length || 0),
    );

    const { subject, body } = interpolateEmail(
        emailTemplate,
        {
            ...pageContent,
            url: location?.href,
            type: t(getNewsTypeLabel(newsType)).toLowerCase(),
        },
        preview,
    );

    useEffect(() => {
        document.body.classList.add("sidebar-shown");

        return () => {
            document.body.classList.remove("sidebar-shown");
        };
    }, []);

    const copyText = t("share.copy");
    const shareText = t("share.whitepaper");
    const publicationDate = getPublishDateLongDateFormatted(
        publishDate,
        createdAt,
        updatedAt,
        language,
    );
    return (
        <aside
            className={classnames(style.wrapper, {
                [style.scrolled]: shareLimit && asideLimit,
                [style.backlinkPresent]: backlinkPresent,
            })}
            ref={asideRef}
            style={
                {
                    "--height":
                        shareLimit && asideLimit
                            ? `${shareList.current?.clientHeight}px`
                            : "auto",
                } as React.CSSProperties
            }
        >
            {isArrayWithContent(authors) &&
                authors.map((author, idx) =>
                    componentMapper(
                        author,
                        undefined,
                        idx % 2 === 0 ? author.id : ~author.id,
                    ),
                )}
            <div className={style.timeAndDateWrapper}>
                {publicationDate && (
                    <Typography
                        className={style.textWithIcon}
                        removeMargin
                        variant="para-small"
                    >
                        <div className="icon-calendar" />
                        {publicationDate}
                    </Typography>
                )}
                {readingTime && (
                    <Typography
                        className={style.textWithIcon}
                        removeMargin
                        variant="para-small"
                    >
                        <Icon color="darkGrey" name="time" size={15} />
                        {timeFormatter(readingTime)}
                    </Typography>
                )}
            </div>
            <ul
                className={style.shareList}
                key="list-sharebuttons"
                ref={shareList}
            >
                <li>
                    <SocialButton
                        id="btn-linkedin"
                        key="btn-linkedin"
                        linkedinSource={siteName}
                        linkedinTitle={metaData.title}
                        pageUrl={location.href}
                        shareText={metaData.description}
                        social="linkedin"
                    />
                    <UncontrolledTooltip placement="top" target="btn-linkedin">
                        {t("share.linkedin")}
                    </UncontrolledTooltip>
                </li>
                <li>
                    <SocialButton
                        id="btn-twitter"
                        key="btn-twitter"
                        pageUrl={location?.href}
                        shareText={twitterText}
                        social="twitter"
                        twitterHashtags={twitterHashTags}
                    />
                    <UncontrolledTooltip placement="top" target="btn-twitter">
                        {t("share.twitter")}
                    </UncontrolledTooltip>
                </li>
                <li>
                    <SocialButton
                        emailSubject={subject}
                        id="btn-email"
                        key="btn-email"
                        pageUrl={location?.href}
                        shareText={body}
                        social="mail"
                    />
                    <UncontrolledTooltip placement="top" target="btn-email">
                        {t("share.email")}
                    </UncontrolledTooltip>
                </li>
                <li>
                    <Button
                        aria-label={copyText}
                        asSocialButton
                        iconName="link"
                        iconSize={30}
                        id="btn-copy"
                        name={copyText}
                        onClick={copyPageLink}
                        type="button"
                        variant="icon"
                    />
                    <Tooltip
                        isOpen={showTooltip}
                        placement="bottom"
                        target="btn-copy"
                        toggle={() => toggleTooltip(!showTooltip)}
                        trigger="manual"
                    >
                        {t("share.copied")}
                    </Tooltip>
                    <UncontrolledTooltip placement="top" target="btn-copy">
                        {copyText}
                    </UncontrolledTooltip>
                </li>
                {whitepaperUrl && (
                    <li>
                        <Button
                            aria-label={shareText}
                            asSocialButton
                            iconName="fileList"
                            iconSize={30}
                            id="btn-whitepaper"
                            key="btn-whitepaper"
                            name={shareText}
                            onClick={() => window.open(handle(whitepaperUrl))}
                            variant="icon"
                        />
                        <UncontrolledTooltip
                            placement="top"
                            target="btn-whitepaper"
                        >
                            {shareText}
                        </UncontrolledTooltip>
                    </li>
                )}
            </ul>
        </aside>
    );
};

const WrappedAngleSidebar = (props: Props) => (
    <ErrorBoundary componentName="AngleSidebar">
        <AngleSidebar {...props} />
    </ErrorBoundary>
);

export default WrappedAngleSidebar;
