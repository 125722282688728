import {
    CTA,
    CountryDetailBlock,
    CountryList,
    Icon,
    Modal,
    Select,
    Typography,
} from "@2po-dpam/components";
import SpiderGraph from "@components/SpiderGraph";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { translations } from "../../utils/translations/en";

import {
    COP_7_COUNTRIES,
    countryKeyToName,
    EMERGING_COUNTRIES,
    EU_COUNTRIES,
    OECD_COUNTRIES,
} from "@utils/countryCodes";
import * as style from "./style.module.scss";

/**
 * Country ranking properties
 *
 * @interface CountryRankingProps
 * @typedef {CountryRankingProps}
 * @property Object translations - translations for the component
 */
interface CountryRankingProps {
    translations: {
        rank: string;
        move: string;
        score: string;
    };
    basePath: string;
}

interface FilterOption {
    label: string;
    value: string;
}

const allSubcategories = new Set([
    "ndc_gap_2022",
    "paris_gap_2022",
    "fair_gap_2022",
    "ndc_gap_2030",
    "paris_gap_2030",
    "fair_gap_2030",
    "target_year",
    "gas_coverage",
    "legal_status",
    "nap",
    "cra",
    "mre",
    "climate_finance",
    "capacity_building",
    "technology_transfer",
    "completeness",
    "transparency",
    "on_schedule",
]);

const categoryTranslations = {
    en: translations.categories,

    // Add other languages as needed
};

const CountryRanking: FC<CountryRankingProps> = ({
    translations,
    basePath,
}) => {
    const [activeCountry, setActiveCountry] = useState<any | null>(null);
    const [selectedCountries, setSelectedCountries] = useState<any | null>([]);

    const [chosenCountryGroup, setChosenCountryGroup] =
        useState<string>("OECD");
    const [tempCountryGroupValue, setTempCountryGroupValue] =
        useState<string>("");
    const [filteredCountries, setFilteredCountries] = useState<any[]>([]);
    const [chosenCategory, setChosenCategory] = useState<string>("");
    const [chosenSort, setChosenSort] = useState<string | null>("highest");
    const [chosenCountry, setChosenCountry] = useState<string | null>("");
    // If other countrygroups are needed, add them here. They will be looped.
    const countryGroups = {
        OECD: OECD_COUNTRIES,
        // EUROPE: EU_COUNTRIES,
    };

    const [isCountryGroupModalOpen, setisCountryGroupModalOpen] =
        useState(false);

    const MAX_SELECTED_COUNTRIES = 5;

    const parisPerformanceData = useStaticQuery(graphql`
        query {
            parisPerformance: parisPerformanceSourceFile {
                mergeData
            }
        }
    `);

    const parisPerformance = JSON.parse(
        parisPerformanceData.parisPerformance.mergeData,
    );

    const { i18n } = useTranslation();
    const formatTitle = (title: string) => {
        const currentLang = i18n.language || "en";
        const translations =
            categoryTranslations[currentLang] || categoryTranslations["en"];
        return translations[title] || title;
    };

    const mappedCountries = Object.keys(parisPerformance).map(
        (country: any) => {
            const trend = parisPerformance[country].overall_rank.trend;
            const overallScoreKey = Object.keys(parisPerformance[country]).find(
                key => key === "overall_score",
            );

            let moveIcon;

            if (trend === -1) {
                moveIcon = "arrowDownCircle";
            } else if (trend === 1) {
                moveIcon = "arrowUpCircle";
            } else {
                moveIcon = "subtractCircle";
            }

            return {
                name: countryKeyToName(country),
                ranking: parisPerformance[country].overall_rank.ranking,
                move: moveIcon,
                score: parisPerformance[country]["overall_score"].percentage,
                categories: Object.keys(parisPerformance[country])
                    .filter(
                        category =>
                            category !== "overall_rank" &&
                            category !== "overall_score" &&
                            !allSubcategories.has(category),
                    )
                    .map((category: string) => {
                        const subcategories = (() => {
                            switch (category) {
                                case "current_emissions":
                                    return [
                                        "ndc_gap_2022",
                                        "paris_gap_2022",
                                        "fair_gap_2022",
                                    ];
                                case "projected_emissions":
                                    return [
                                        "ndc_gap_2030",
                                        "paris_gap_2030",
                                        "fair_gap_2030",
                                    ];
                                case "net_zero":
                                    return [
                                        "target_year",
                                        "gas_coverage",
                                        "legal_status",
                                    ];
                                case "adaptation":
                                    return ["nap", "cra", "mre"];
                                case "means_of_implementation":
                                    return [
                                        "climate_finance",
                                        "capacity_building",
                                        "technology_transfer",
                                    ];
                                case "multilateral_transparency":
                                    return [
                                        "completeness",
                                        "transparency",
                                        "on_schedule",
                                    ];
                                default:
                                    return [];
                            }
                        })();

                        return {
                            title: formatTitle(category),
                            score: parisPerformance[country][category]
                                .percentage,
                            evolution:
                                parisPerformance[country][category].trend,
                            rank: parisPerformance[country][category].ranking,
                            subcategories: subcategories.map(sub => {
                                const subcategoryData =
                                    parisPerformance[country][sub];

                                return {
                                    title: formatTitle(sub),
                                    score: subcategoryData?.percentage || 0,
                                    evolution: subcategoryData?.trend || 0,
                                    rank: subcategoryData?.ranking || 0,
                                };
                            }),
                        };
                    }),
                overallScore: overallScoreKey
                    ? {
                          title: formatTitle(overallScoreKey),
                          score: parisPerformance[country][overallScoreKey]
                              .percentage,
                          evolution:
                              parisPerformance[country][overallScoreKey].trend,
                      }
                    : undefined,
            };
        },
    );

    const handleSetActiveCountry = useCallback((country: any) => {
        setActiveCountry(country);
    }, []);

    const handleClickCountry = useCallback(() => {
        const countrySlug = activeCountry.name
            .toLowerCase()
            .replace(/\s+/g, "-");
        navigate(`/${basePath}paris-performance-ranking/${countrySlug}`, {
            state: {
                countryData: activeCountry,
            },
        });
    }, [activeCountry, basePath]);

    const handleCompare = () => {
        if (
            selectedCountries.length >= MAX_SELECTED_COUNTRIES &&
            !selectedCountries.includes(activeCountry.name)
        ) {
            setSelectedCountries(selectedCountries);
        } else {
            setSelectedCountries(prevSelectedCountries => {
                if (prevSelectedCountries.includes(activeCountry.name)) {
                    return prevSelectedCountries.filter(
                        selectedCountry =>
                            selectedCountry !== activeCountry.name,
                    );
                } else {
                    return [...prevSelectedCountries, activeCountry.name];
                }
            });
            const graphContainer = document.getElementById("graph-container");
            if (graphContainer) {
                graphContainer.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    const checkCountryGroupForExistence = (countryGroup: Array<string>) => {
        return countryGroup.some(country =>
            mappedCountries.map(country => country.name).includes(country),
        );
    };

    const createCountryGroupFilters = () => {
        const returnArray: Array<FilterOption> = [];

        Object.keys(countryGroups).map(countryGroup => {
            if (checkCountryGroupForExistence(countryGroups[countryGroup])) {
                returnArray.push({
                    label:
                        countryGroup === "OECD"
                            ? "Annex II Countries"
                            : countryGroup.replace(/_/g, " "),
                    value: countryGroup,
                });
            }
        });

        return returnArray;
    };

    const possibleFilters = {
        countrygroup: {
            options: createCountryGroupFilters(),
            defaultvalue: chosenCountryGroup,
        },
        category: {
            options: [
                {
                    value: "",
                    label: "Overall Score",
                },
                ...mappedCountries[0].categories.map(category => ({
                    value: category.title,
                    label: category.title,
                })),
            ],
            defaultvalue: chosenCategory,
        },
        sort: {
            options: [
                { value: "highest", label: "Highest rank" },
                { value: "lowest", label: "Lowest rank" },
            ],
            defaultvalue: chosenSort,
        },
        countries: {
            options: [
                {
                    value: "",
                    label: "All countries",
                },
                ...mappedCountries
                    .filter(country =>
                        countryGroups[chosenCountryGroup ?? "OECD"]?.includes(
                            country.name,
                        ),
                    )
                    .map(country => ({
                        value: country.name,
                        label: country.name,
                    })),
            ],
            hasInputFilter: true,
            defaultvalue: chosenCountry,
        },
    };

    const handleSorting = (value: string) => {
        setChosenSort(value);
    };

    const setTempCountryGroupAsChosen = () => {
        setChosenCountryGroup(tempCountryGroupValue);
        setisCountryGroupModalOpen(false);
        setTempCountryGroupValue("");
        setChosenCountry(null);
        setChosenCategory("");
    };

    const handleCountryGroupFilter = (value: string) => {
        setisCountryGroupModalOpen(true);
        setTempCountryGroupValue(value);
    };

    const handleCategoryFilter = (value: string) => {
        setChosenCategory(value);

        // Filter and sort countries based on the selected category
        const updatedFilteredCountries =
            value === ""
                ? mappedCountries.sort((a, b) => {
                      return b.overallScore?.score - a.overallScore?.score; // Changed to sort from highest to lowest
                  })
                : mappedCountries
                      .filter(country =>
                          country.categories.some(
                              category => category.title === value,
                          ),
                      )
                      .sort((a, b) => {
                          return (
                              a.categories.find(cat => cat.title === value)
                                  ?.rank -
                              b.categories.find(cat => cat.title === value)
                                  ?.rank
                          );
                      });

        setFilteredCountries(updatedFilteredCountries);
    };

    const setActiveCountryByCountryName = (countryName: string) => {
        const country = mappedCountries.find(
            country => country.name === countryName,
        );

        setActiveCountry(country || null);
    };

    const handleCountryFilter = (value: string) => {
        setChosenCountry(value);
        if (value) {
            setActiveCountryByCountryName(value);
        } else {
            setActiveCountry(filteredCountries[0] || null);
        }
    };

    const handleFilter = (value: any, filter: string) => {
        switch (filter) {
            case "countrygroup":
                handleCountryGroupFilter(value);
                break;
            case "category":
                handleCategoryFilter(value);
                break;
            case "sort":
                handleSorting(value);
                break;
            case "countries":
                handleCountryFilter(value);
                break;
            default:
                break;
        }
    };

    // Modify the useEffect to avoid resetting filteredCountries
    useEffect(() => {
        const filterCountries = () => {
            let filtered = [...mappedCountries];

            if (chosenCountryGroup) {
                const countryGroup = {
                    OECD: OECD_COUNTRIES,
                    EUROPE: EU_COUNTRIES,
                    COP_7_COUNTRIES: COP_7_COUNTRIES,
                    EMERGING_COUNTRIES: EMERGING_COUNTRIES,
                }[chosenCountryGroup];

                filtered = filtered.filter(country =>
                    countryGroup?.includes(country.name),
                );
            }

            if (chosenSort) {
                filtered.sort((a, b) => {
                    if (chosenSort === "highest") {
                        return a.ranking - b.ranking;
                    } else if (chosenSort === "lowest") {
                        return b.ranking - a.ranking;
                    }
                    return 0;
                });
            }

            if (chosenCountry) {
                filtered = filtered.filter(
                    country => country.name === chosenCountry,
                );
            }

            setFilteredCountries(filtered);
            setSelectedCountries(prevSelectedCountries =>
                prevSelectedCountries.filter(selectedCountry =>
                    filtered.some(country => country.name === selectedCountry),
                ),
            );
        };

        filterCountries();
    }, [chosenCountryGroup, chosenSort, chosenCountry]);

    const isBrowser = typeof window !== "undefined";

    const rightColumnRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (activeCountry && isBrowser) {
            const isMobile = window.innerWidth <= 768;
            if (isMobile && rightColumnRef.current) {
                rightColumnRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [activeCountry, isBrowser]);

    return (
        <div className={style.container}>
            <Modal
                closable={false}
                id="countrygroup-modal"
                isOpen={isCountryGroupModalOpen}
                onClose={() => setisCountryGroupModalOpen(false)}
                static
            >
                <div className={style.modalContainer}>
                    <Icon name="info" size={64} />
                    <Typography
                        className={style.modalTitle}
                        color="main"
                        variant="p"
                    >
                        You are about to change the Country Group. <br />
                        This will reshuffle the data to a different country pool
                        on the full page.
                    </Typography>
                    <div className={style.actionContainer}>
                        <CTA
                            color={"main"}
                            fullWidth
                            onClick={() => setTempCountryGroupAsChosen()}
                            type="button"
                            variant="filled"
                        >
                            Confirm
                        </CTA>
                        <CTA
                            color={"multiBlue"}
                            fullWidth
                            onClick={() => setisCountryGroupModalOpen(false)}
                            type="button"
                            variant="outlined"
                        >
                            Cancel
                        </CTA>
                    </div>
                </div>
            </Modal>
            <div className={style.filterContainer}>
                {Object.keys(possibleFilters).map(filter => {
                    return (
                        <div className={style.filterBlock} key={filter}>
                            <Select
                                floatingOptions
                                hasInputFilter={
                                    possibleFilters[filter].hasInputFilter
                                }
                                items={possibleFilters[filter].options}
                                noBoldStyling={true}
                                onChange={value => handleFilter(value, filter)}
                                shouldSort={false}
                                showCheckIcon={true}
                                value={possibleFilters[filter].defaultvalue}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={style.leftColumn}>
                <CountryList
                    activeCountry={activeCountry}
                    chosenCategory={chosenCategory}
                    countries={filteredCountries}
                    handleClick={() => handleClickCountry()}
                    handleCompare={() => handleCompare()}
                    setActiveCountry={handleSetActiveCountry}
                />
            </div>

            <div className={style.rightColumn}>
                {activeCountry && (
                    <div className={style.stickyContainer}>
                        <div className={style.headerContainer}>
                            <div className={style.ranking}>
                                {translations.rank || "Rank"}
                            </div>
                            <div className={style.moveAndScore}>
                                <span className={style.move}>
                                    {translations.move || "Move"}
                                </span>
                                <span>{translations.score || "Score"}</span>
                            </div>
                        </div>
                        <div className={style.countryDetailBlockWrapper}>
                            <CountryDetailBlock
                                categories={activeCountry.categories}
                                handleClick={() => handleClickCountry()}
                                handleCompare={() => handleCompare()}
                                overallScore={activeCountry.overallScore}
                                view={"Expanded"}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={style.divider}></div>
            <div className={style.graphContainer} id="graph-container">
                <div className={style.graphTypography}>
                    <Typography variant="h2">
                        Country Comparison Tool
                    </Typography>
                    <Typography>
                        This tool enables you to compare countries&apos;
                        performance on the six central topics of the ranking.
                        <br />
                        Select the countries you wish to compare below. A
                        maximum of five countries can be compared.
                    </Typography>
                </div>
                {isBrowser && (
                    <SpiderGraph
                        countries={mappedCountries}
                        maxSelectedCountries={MAX_SELECTED_COUNTRIES}
                        selectedCountries={selectedCountries}
                        setActiveCountry={setActiveCountryByCountryName}
                        setSelectedCountries={setSelectedCountries}
                    />
                )}
            </div>
        </div>
    );
};

export default CountryRanking;
