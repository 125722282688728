import { EXCLUDE_CANONICALS } from "@constants";
import { useLocation } from "@reach/router";

const useCanonicalUrl = (excludeUrls = EXCLUDE_CANONICALS) => {
    const { pathname } = useLocation();
    const splitPath = pathname?.split("/") || [];

    const shouldDisplayCanonical = () =>
        splitPath.every(pathItem => !excludeUrls?.includes(pathItem));

    return {
        displayCanonical: shouldDisplayCanonical(),
    };
};

export default useCanonicalUrl;
