import { HeroCarousel as _Hero } from "@2po-dpam/components";
import Image, { ContentfulImageData } from "@components/Image";
import RichText from "@components/RichText";
import { NewsType } from "@constants";
import { useContextStore, usePageContext, useRouting } from "@hooks";
import { isArrayWithContent } from "@utils/arrays";
import { InvestorContent, getInvestorSpecificContent } from "@utils/investors";
import { getNewsTypeLabel, mapContentfulNewsType } from "@utils/newsTypes";
import { getPublishDateLongDateFormatted } from "@utils/pages";
import { Link as GatsbyLink, graphql } from "gatsby";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
export type Category = {
    mainCategory: string;
    subCategory: string;
};
export interface GeneralContent {
    id: string;
    introText: {
        raw: string;
    };
    shortTitle: {
        raw: string;
    };
    publishDate: string;
    updatedAt: string;
    formatIcon: {
        icon: string;
    };
    hero: {
        backgroundImage: ContentfulImageData;
    };
    type?: NewsType[]; //for news
    category?: Category[]; //for angel
    contentful_id: string;
}

export interface Item {
    id: string;
    contentful_id: string;
    generalContent: GeneralContent;
}

export interface CarouselData {
    amountOfItems: number;
    strategy: string;
    typeOfItems: string;
    items: InvestorContent[];
}

type Data = {
    articles: CarouselData;
};

type Props = {
    data: Data;
};

const getCarouselItems = (investor: string, articles: CarouselData) => {
    return articles?.items?.map(item => {
        const investorContent = getInvestorSpecificContent(investor, item);
        const { hero, formatIcon } = investorContent;
        const { backgroundImage } = hero;
        const {
            shortTitle,
            introText,
            publishDate,
            updatedAt,
            category,
            type,
            createdAt,
        } = investorContent;
        const { contentful_id, id } = item;

        return {
            shortTitle,
            introText,
            publishDate,
            updatedAt,
            category,
            id,
            contentful_id,
            formatIcon,
            type,
            backgroundImage,
            createdAt,
        };
    });
};

const getCategories = (categories: Category[]) => {
    if (!isArrayWithContent(categories)) return;
    return categories
        .map(cat => `${cat.mainCategory} `)
        .join("")
        .trimEnd();
};

const HeroCarouselVariant = ({ data: { articles } }: Props) => {
    const context = useContextStore();
    const { pages } = usePageContext();
    const { investor } = useContextStore();
    const { getPathForSlug } = useRouting();

    const { t } = useTranslation();

    const heroItems = getCarouselItems(investor, articles);

    const backgroundImages = heroItems?.map(
        heroItem => heroItem.backgroundImage,
    );

    const heroes = useMemo(
        () =>
            backgroundImages &&
            heroItems?.map(
                (
                    {
                        shortTitle,
                        introText,
                        publishDate,
                        updatedAt,
                        createdAt,
                        category,
                        id,
                        contentful_id,
                        formatIcon,
                        type,
                    },
                    i,
                ) => {
                    const newsType = mapContentfulNewsType(type) || "";
                    const page = pages.find(
                        page =>
                            page.pageType?.contentful_id === contentful_id &&
                            page.node_locale === context.language,
                    );

                    const slug = page?.slug;
                    const publicationDate = getPublishDateLongDateFormatted(
                        publishDate,
                        createdAt,
                        updatedAt,
                        context.language,
                    );

                    return {
                        image: <Image {...backgroundImages[i]} />,
                        title: (
                            <RichText
                                options={{ removeMargin: true }}
                                textNode={shortTitle}
                            />
                        ),
                        description: (
                            <RichText
                                options={{ removeMargin: true, maxLines: 2 }}
                                textNode={introText}
                            />
                        ),
                        date: publicationDate,
                        icon: formatIcon?.icon,
                        subtitle:
                            (category && getCategories(category)) ||
                            t(getNewsTypeLabel(newsType)),
                        destination: getPathForSlug(slug),
                        id: id,
                        ctaLabel: t("read_more"),
                    };
                },
            ),
        [articles],
    );

    return <_Hero LinkComponent={GatsbyLink} heroes={heroes} />;
};

export default HeroCarouselVariant;
export const query = graphql`
    fragment HeroCarousel on ContentfulAssemblyHeroCarouselVariation {
        id
        __typename
        articles {
            items {
                ...AngleCard
                ...NewsCard
            }
        }
    }
`;
