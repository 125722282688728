// extracted by mini-css-extract-plugin
export var alignCenter = "style-module--align-center--d5862";
export var alignRight = "style-module--align-right--661a6";
export var content = "style-module--content--de6e1";
export var cta = "style-module--cta--90f45";
export var divider = "style-module--divider--13a2d";
export var embeddedCta = "style-module--embedded-cta--bb4b8";
export var firstDivider = "style-module--first-divider--d76ca";
export var image = "style-module--image--aca2e";
export var lastDivider = "style-module--last-divider--ec5a2";
export var main = "style-module--main--7165b";
export var sustainabilityCard = "style-module--sustainability-card--81523";
export var title = "style-module--title--d3df7";
export var visible = "style-module--visible--6a762";