import { Icon, ThemeContext } from "@2po-dpam/components";
import RichText from "@components/RichText";
import classNames from "classnames";
import React, { useContext } from "react";

import * as style from "./style.module.scss";

type Props = {
    data: {
        icon: {
            icon?: string;
        };
        text: {
            raw?: string;
            json?: string;
        };
    };
    options: any;
};
const LabelWIcon = ({ data: { icon, text }, options }: Props) => {
    const theme = useContext(ThemeContext);
    const Component = options?.inline ? "span" : "div";

    return (
        <Component className={classNames(style.labelWIcon, options?.className)}>
            <Icon
                className={style.icon}
                color={theme === "primary" ? "white" : "primary"}
                name={icon?.icon}
            />
            <RichText
                className={style.text}
                options={{
                    color: theme === "primary" ? "white" : "primary",
                    variant: "h5",
                    component: options?.inline ? "span" : undefined,
                    removeMargin: true,
                }}
                textNode={text}
            />
        </Component>
    );
};

export default LabelWIcon;
