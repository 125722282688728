import { ALT_MARKETS } from "@constants";
import { Investor } from "@types";
import { useTranslation } from "react-i18next";

import useAvailablePageLocales from "./use-available-page-locales";
import useContextStore from "./use-context-store";

interface InvestorLabels {
    default:
        | {
              [key in Investor]: string;
          }
        | Record<string, unknown>;
    alt:
        | {
              [key in Investor]: string;
          }
        | Record<string, unknown>;
}

const useInvestorLabels = () => {
    const { market, investor } = useContextStore();
    const { investors: availableInvestors } = useAvailablePageLocales();
    const { t } = useTranslation();

    const labels: InvestorLabels = availableInvestors.reduce(
        (prev, curr) => ({
            default: {
                ...prev.default,
                [curr]: t(`investors.${curr}`),
            },
            alt: {
                ...prev.alt,
                [curr]: t(`investors.${curr}-alt`),
            },
        }),
        { default: {}, alt: {} },
    );

    const currentMarketLabels = ALT_MARKETS.includes(market)
        ? labels.alt
        : labels.default;
    const currentInvestorLabel = currentMarketLabels?.[investor];

    const getLabels = (market: string) =>
        ALT_MARKETS.includes(market) ? labels.alt : labels.default;

    return { labels, currentInvestorLabel, currentMarketLabels, getLabels };
};

export default useInvestorLabels;
