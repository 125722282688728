import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetaData = (): { siteUrl: string } => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `,
    );

    return site.siteMetadata;
};

export default useSiteMetaData;
