import { CTA, Icon, Input, Label } from "@2po-dpam/components";
import RichText from "@components/RichText";
import { INPUT_CHAR_MAX, MESSAGE_CHAR_LIMIT } from "@constants";
import { useFormValidation } from "@hooks";
import { useFormik } from "formik";
import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Input as RsInput, Row, Spinner } from "reactstrap";
import * as Yup from "yup";

import * as style from "./style.module.scss";

export interface FormData {
    name: string;
    firstName: string;
    email: string;
    subject: string;
    message: string;
}

interface Props {
    formTitle?: { raw: string };
    onSubmit: (formData: FormData) => void;
    submitted: boolean;
    loading: boolean;
    error: boolean;
    options?: any;
    id?: string;
}

const ContactForm = ({
    formTitle,
    onSubmit: handleSubmit,
    submitted,
    loading,
    error,
    options,
    id,
}: Props) => {
    const { t } = useTranslation();
    const {
        nameValidation,
        termsValidation,
        firstNameValidation,
        emailValidation,
        subjectValidation,
        messageValidation,
    } = useFormValidation();

    const formik = useFormik({
        initialValues: {
            name: "",
            firstName: "",
            email: "",
            subject: "",
            message: "",
            termsAgreed: false,
        },
        validationSchema: Yup.object().shape({
            name: nameValidation(),
            firstName: firstNameValidation(),
            email: emailValidation(),
            subject: subjectValidation(),
            message: messageValidation(),
            termsAgreed: termsValidation(),
        }),
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    const InputError = ({ field }: Record<string, string>) => (
        <>
            {formik.touched[field] && formik.errors[field] && (
                <span className={style.errorMessage}>
                    {formik.errors[field]}
                </span>
            )}
        </>
    );
    const colWidths = options?.insideSection
        ? { xs: 12, sm: 10, md: 8, xxl: 6 }
        : { xs: 12, md: 11, lg: 11, xxl: 10 };

    const getCheckboxLabel = () => (
        <span>
            {t("form.checkbox.1")}
            <a
                href="mailto: dataprivacy@degroofpetercam.com"
                rel="noreferrer"
                target="_blank"
            >
                {t("form.checkbox.2")}
            </a>
            {t("form.checkbox.3")}
            <a
                href={t("form.checkbox.privacy_policy_link")}
                rel="noreferrer"
                target="_blank"
            >
                [{t("form.checkbox.4")}]
            </a>
            .
        </span>
    );

    return (
        <Row className={`${style.form} justify-content-center`}>
            <Col {...colWidths}>
                {!submitted ? (
                    <>
                        {formTitle && (
                            <RichText
                                options={{ variant: "h2", color: "main" }}
                                textNode={{ text: formTitle }}
                            />
                        )}
                        <form id={id} name={id} onSubmit={formik.handleSubmit}>
                            <FormGroup>
                                <Label className={style.label}>
                                    {t("form.fields.firstName.label")}
                                </Label>
                                <Input
                                    id="firstName"
                                    maxLength={INPUT_CHAR_MAX}
                                    name="firstName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    placeholder={t(
                                        "form.fields.firstName.placeholder",
                                    )}
                                    type="text"
                                    value={formik.values.firstName}
                                />
                                <InputError field="firstName" />
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("form.fields.name.label")}*</Label>
                                <Input
                                    id="name"
                                    maxLength={INPUT_CHAR_MAX}
                                    name="name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    placeholder={t(
                                        "form.fields.name.placeholder",
                                    )}
                                    type="text"
                                    value={formik.values.name}
                                />
                                <InputError field="name" />
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("form.fields.email.label")}*</Label>
                                <Input
                                    id="email"
                                    maxLength={INPUT_CHAR_MAX}
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    placeholder={t(
                                        "form.fields.email.placeholder",
                                    )}
                                    type="email"
                                    value={formik.values.email}
                                />
                                <InputError field="email" />
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("form.fields.subject.label")}*</Label>
                                <Input
                                    id="subject"
                                    maxLength={INPUT_CHAR_MAX}
                                    name="subject"
                                    onChange={formik.handleChange}
                                    placeholder={t(
                                        "form.fields.subject.placeholder",
                                    )}
                                    type="text"
                                    value={formik.values.subject}
                                />
                                <InputError field="subject" />
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("form.fields.message.label")}*</Label>
                                <Input
                                    id="message"
                                    maxLength={MESSAGE_CHAR_LIMIT}
                                    name="message"
                                    onChange={formik.handleChange}
                                    placeholder={t(
                                        "form.fields.message.placeholder",
                                    )}
                                    rows="3"
                                    type="textarea"
                                    value={formik.values.message}
                                />
                                <InputError field="message" />
                            </FormGroup>
                            <div className={style.checkbox}>
                                <FormGroup
                                    check
                                    className={style.checkboxWrapper}
                                >
                                    <RsInput
                                        id="termsAgreed"
                                        name="termsAgreed"
                                        onChange={formik.handleChange}
                                        type="checkbox"
                                        value={formik.values.termsAgreed.toString()}
                                    />
                                    <Label check>{getCheckboxLabel()}</Label>
                                </FormGroup>
                                <InputError field="termsAgreed" />
                            </div>
                            <CTA
                                disabled={loading}
                                fullWidth
                                onClick={() => null}
                                type="submit"
                            >
                                {!loading ? t("form.submit") : <Spinner />}
                            </CTA>
                            {
                                //@todo: add error message
                                error && <div>Error while submitting form</div>
                            }
                        </form>
                    </>
                ) : (
                    <div className={style.successMessage}>
                        <Icon color="primary" name="checkbox" size="80" />
                        <div className={style.title}>
                            {t("success.thankYou")}
                        </div>
                        <div className={style.message}>
                            {t("success.message")}
                        </div>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default ContactForm;

export const query = graphql`
    fragment Form on ContentfulContentForm {
        id
        node_locale
        __typename
        formTitle: title {
            raw
        }
    }
`;
