type IntlTypeUnion = "language" | "region" | "script" | "currency";
export const getDisplayNames = (language: string, type: IntlTypeUnion) => {
    if (language === "en") language = "en-GB";
    return new Intl.DisplayNames([language], { type });
};

type DateTimeFormat = "longDate" | "shortDate" | "time" | "dateAndTime";
const createFormatter = (
    language: string | undefined,
    formatType: DateTimeFormat,
) => {
    switch (formatType) {
        case "longDate":
            return new Intl.DateTimeFormat(language, { dateStyle: "long" });
        case "shortDate":
            return new Intl.DateTimeFormat(language, { dateStyle: "short" });
        case "time":
            return new Intl.DateTimeFormat(language, { timeStyle: "medium" });
        case "dateAndTime":
            return new Intl.DateTimeFormat(language, {
                dateStyle: "long",
                timeStyle: "medium",
            });
        default:
            return new Intl.DateTimeFormat(language);
    }
};

export const getDateTimeFormatter =
    (language: string | undefined, formatType: DateTimeFormat) =>
    (dateTimeString: string) => {
        if (!dateTimeString) return "";
        if (language === "en") language = "en-GB";
        try {
            const date = new Date(dateTimeString);
            const formatter = createFormatter(language, formatType);
            return formatter.format(date);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn("could not format date", err);
        }
    };
