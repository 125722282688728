// extracted by mini-css-extract-plugin
export var actionContainer = "style-module--action-container--65dcc";
export var container = "style-module--container--b26d4";
export var countryBlocks = "style-module--country-blocks--30f4f";
export var countryDetailBlockWrapper = "style-module--country-detail-block-wrapper--2cc66";
export var divider = "style-module--divider--80638";
export var filterBlock = "style-module--filter-block--fe284";
export var filterContainer = "style-module--filter-container--9a66a";
export var graphContainer = "style-module--graph-container--b7da0";
export var graphTypography = "style-module--graph-typography--77699";
export var headerContainer = "style-module--header-container--891f7";
export var leftColumn = "style-module--left-column--3caf6";
export var modalContainer = "style-module--modal-container--99fe2";
export var modalTitle = "style-module--modal-title--8c9e0";
export var move = "style-module--move--04f7a";
export var moveAndScore = "style-module--move-and-score--1211c";
export var ranking = "style-module--ranking--75f7f";
export var rightColumn = "style-module--right-column--8919b";
export var stickyContainer = "style-module--sticky-container--7f3a6";