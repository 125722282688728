import React from "react";

type Props = {
    fallback?: React.ReactNode;
    componentName?: string;
} & React.ComponentProps<any>;

type State = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    render() {
        const { fallback, componentName, children } = this.props;

        if (this.state.hasError)
            return fallback ? (
                fallback
            ) : (
                <p>
                    <em>
                        Oops, something went wrong
                        {componentName ? (
                            <strong> with {componentName}</strong>
                        ) : (
                            ""
                        )}
                    </em>
                </p>
            );
        return children;
    }
}

export default ErrorBoundary;
