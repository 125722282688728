import { Button, Typography, Section as _Section } from "@2po-dpam/components";
import { InvestorTypePopup } from "@components";
import {
    useAvailablePageLocales,
    useContextStore,
    useCookies,
    useRouting,
} from "@hooks";
import { Investor } from "@types";
import { replaceValues } from "@utils/email";
import { Link, graphql } from "gatsby";
import React, { useMemo, useState } from "react";

import * as style from "./style.module.scss";

type Props = {
    data: {
        viewingAsText: string;
        switchToText: string;
        id: string;
        __typename: string;
        possibleInvestorTypes: string[];
    };
    onClose?: () => void;
    options?: any;
};

const mapInvestor = (item: string) => item.replace(/\s/gim, "-").toLowerCase();

const SwitchInvestorTypeSection = ({
    data: { viewingAsText, switchToText, possibleInvestorTypes },
    onClose,
}: Props) => {
    const { setCookie } = useCookies();
    const [showModal, setShowModal] = useState(false);
    const { investor } = useContextStore();
    const { getPath } = useRouting();
    const { investors: availableInvestorsForPage } = useAvailablePageLocales();

    const onlyOption = useMemo(() => {
        const availableOptions = possibleInvestorTypes
            .map(mapInvestor)
            .filter(item =>
                availableInvestorsForPage.includes(item as Investor),
            );
        if (availableOptions.length > 2 || !availableOptions.includes(investor))
            return null;
        else return availableOptions.find(option => option !== investor);
    }, [investor, availableInvestorsForPage, possibleInvestorTypes]);

    const interpolatedText = viewingAsText
        ? replaceValues(viewingAsText, {
              investorType: `<strong>${investor || ""}</strong>`,
          })
        : "";

    const handleLinkClick = (investorType?: string) => {
        investorType && setCookie("investorType", investorType);
    };

    return (
        <_Section className={style.section} withBorder>
            <InvestorTypePopup
                isOpen={showModal}
                onClose={() => setShowModal(false)}
            />
            <div className={style.switchWrapper}>
                <Typography
                    className={style.text}
                    removeMargin
                    variant="para-small"
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: interpolatedText,
                        }}
                    />
                    {onlyOption ? (
                        <Link
                            className={style.textButton}
                            onClick={() => handleLinkClick(onlyOption)}
                            to={getPath({ investor: onlyOption })}
                        >
                            <Typography
                                color="primary"
                                component="span"
                                removeMargin
                                variant="para-small"
                            >
                                {switchToText}
                            </Typography>
                        </Link>
                    ) : (
                        <button
                            className={style.textButton}
                            onClick={() => setShowModal(true)}
                        >
                            <Typography
                                color="primary"
                                component="span"
                                removeMargin
                                variant="para-small"
                            >
                                {switchToText}
                            </Typography>
                        </button>
                    )}
                </Typography>
                {onClose && (
                    <Button
                        className={style.closer}
                        iconColor="darkGrey"
                        iconName="close"
                        iconSize={20}
                        onClick={onClose}
                        variant="icon"
                    />
                )}
            </div>
        </_Section>
    );
};

export default SwitchInvestorTypeSection;

export const query = graphql`
    fragment SwitchInvestorTypeSection on ContentfulAssemblySwitchInvestorTypeSection {
        id
        __typename
        contentful_id
        viewingAsText
        switchToText
        possibleInvestorTypes
    }
`;
