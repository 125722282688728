import { LatestArticlesSection as _LatestArticles } from "@2po-dpam/components";
import { CallToAction, Image, RichText } from "@components";
import { useContextStore } from "@hooks";
import { getInvestorSpecificContent } from "@utils/investors";
import { getPublishDateLongDateFormatted } from "@utils/pages";
import { Link as GatsbyLink, graphql } from "gatsby";
import React from "react";

type Article = {
    amountOfItems: number;
    typeOfItems: "Angle" | "News";
    items: any[];
    strategy: "Automatic" | "Manually";
    cta: any;
};

type Props = {
    data: {
        id: string;
        __typename: string;
        contentful_id: string;
        articles: Article;
        title: { raw: string };
    };
};

const articleContentMapper = (language, investor: string) =>
    function content(page: any, first?: boolean) {
        if (!page) return;
        const specificContent = getInvestorSpecificContent(investor, page);
        const image = first && (
            <Image {...specificContent.hero.backgroundImage} />
        );

        const publicationDate = getPublishDateLongDateFormatted(
            specificContent.publishDate,
            specificContent.createdAt,
            specificContent.updatedAt,
            language,
        );

        return {
            icon: specificContent?.icon?.icon,
            date: publicationDate,
            title: (
                <GatsbyLink to={page.parentSlug}>
                    <RichText
                        options={{
                            variant: first ? "h2" : "card-title",
                            removeMargin: true,
                            noRichStyling: true,
                        }}
                        textNode={specificContent.shortTitle}
                    />
                </GatsbyLink>
            ),
            image,
        };
    };

const LatestArticlesSection = ({
    data: {
        articles: { items, cta },
        title,
    },
}: Props) => {
    const { investor, language } = useContextStore();
    const mapArticleContent = articleContentMapper(language, investor);
    const noItems = items?.length === 0 || items?.length === undefined;

    const firstArticle = !noItems && mapArticleContent(items[0], true);
    const latestArticles = !noItems
        ? items.slice(1).map(article => mapArticleContent(article))
        : [];

    return (
        <>
            {!noItems && firstArticle && latestArticles.length && (
                <_LatestArticles
                    cta={cta && <CallToAction data={cta} />}
                    firstArticle={firstArticle}
                    latestArticles={latestArticles}
                    title={
                        <RichText
                            options={{
                                variant: "h2",
                                underline: true,
                            }}
                            textNode={title}
                        />
                    }
                />
            )}
        </>
    );
};

export default LatestArticlesSection;

export const query = graphql`
    fragment LatestArticlesSection on ContentfulAssemblyLatestArticlesSection {
        id
        __typename
        contentful_id
        title {
            raw
        }
        articles {
            items {
                ... on ContentfulPageTypeAnglePage {
                    id
                    __typename
                    contentful_id
                    generalContent {
                        ...General
                    }
                    professionalIntermediaryContent {
                        content {
                            ...General
                        }
                    }
                    retailContent {
                        content {
                            ...General
                        }
                    }
                    professionalEndInvestorContent {
                        content {
                            ...General
                        }
                    }
                    parentSlug
                }
                ... on ContentfulPageTypeNewsOrAnnouncementPage {
                    id
                    __typename
                    contentful_id
                    generalContent {
                        ...NewsCardContent
                    }
                    professionalEndInvestorContent {
                        content {
                            ...NewsCardContent
                        }
                    }
                    professionalIntermediaryContent {
                        content {
                            ...NewsCardContent
                        }
                    }
                    retailContent {
                        content {
                            ...NewsCardContent
                        }
                    }
                    parentSlug
                }
            }
            cta {
                ...CallToAction
            }
        }
    }
`;
