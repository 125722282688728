import { Section as _Section, Timeline } from "@2po-dpam/components";
import Image, { ContentfulImageData } from "@components/Image";
import RichText from "@components/RichText";
import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

type TimelineItem = {
    id: string;
    contentful_id: string;
    highlightedItem: string;
    image: ContentfulImageData;
    number: number;
    text: {
        text: { raw: string };
    };
};

type Props = {
    data: {
        id: string;
        __typename: string;
        theme: string;
        variation: string;
        title: {
            raw: string;
        };
        description: {
            text: { raw: string };
        };
        items: TimelineItem[];
    };
};

const mapContent = (items: TimelineItem[]) =>
    items.map(item => ({
        content: <RichText textNode={item.text} />,
        annotation: item.number,
        image: item.image && <Image {...item.image} />,
        highlighted: item.highlightedItem,
    }));

const TimelineSection = ({
    data: { title, description, items, theme, variation },
}: Props) => {
    const { t } = useTranslation();

    const isInteractive = variation === "Interactive";
    const translations = {
        olderBtnText: t("older timeline items"),
        newerBtnText: t("newer timeline items"),
    };

    return (
        <>
            <_Section
                background={theme}
                description={<RichText textNode={description} />}
                title={
                    <RichText
                        options={{ variant: "h2" }}
                        textNode={{ text: title }}
                    />
                }
            >
                <Timeline
                    content={mapContent(items)}
                    controlsOpt={isInteractive && translations}
                    variation={variation}
                />
            </_Section>
        </>
    );
};

export default TimelineSection;

export const query = graphql`
    fragment TimelineSection on ContentfulAssemblyTimelineSection {
        id
        __typename
        theme
        variation
        title {
            raw
        }
        description {
            ...RichText
        }
        items {
            id
            contentful_id
            highlightedItem
            number
            image {
                ...ImageConstrained
            }
            text {
                ...RichText
            }
        }
    }
`;
