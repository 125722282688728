import { useContextStore, usePageContext, useRouting } from "@hooks";
import {
    type InvestorContent,
    getInvestorSpecificContent,
} from "@utils/investors";

const useInvestorContentWithSlug = (data: InvestorContent) => {
    const { pages } = usePageContext();
    const { investor } = useContextStore();
    const { getPathForSlug } = useRouting();
    const page = pages.find(
        ({ pageType }) => pageType?.contentful_id === data.contentful_id,
    );
    const content = getInvestorSpecificContent(investor, data);

    if (!page || !content) return;

    const destination = getPathForSlug(page.slug);

    return {
        ...content,
        slug: page.slug,
        destination,
    };
};
export default useInvestorContentWithSlug;
