import { usePageContext } from "@hooks";
import {
    getLanguagesForMarket,
    mapMarketsToSelectItems,
    mapToMarketsAndLanguages,
} from "@utils/marketLanguages";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18next from "../i18next.config.js";

// Configure i18next

const useMarketsLanguages = (
    data: any,
    activeLanguage: string,
    market: string,
) => {
    const { marketLanguages } = usePageContext();
    const { i18n } = useTranslation("marketsLanguages");

    const marketsMap = useMemo(
        () => data && mapToMarketsAndLanguages(data),
        [marketLanguages, i18n.language],
    );

    const markets = useMemo(
        () => mapMarketsToSelectItems(i18n, marketsMap, activeLanguage),
        [marketsMap, activeLanguage, i18n],
    );

    const languages = useMemo(
        () => getLanguagesForMarket(i18n, market, marketsMap),
        [marketsMap, market, activeLanguage, i18n],
    );

    const setActiveLanguage = language => {
        i18next.changeLanguage(language);
    };

    return { markets, languages, marketsMap, setActiveLanguage };
};

export default useMarketsLanguages;
