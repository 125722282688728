/**
 * Capitalize the first letter in a string
 */
export default (input: string): string => {
    if (!input) return "";
    const lowerCaseInput = input.toLowerCase();
    return `${lowerCaseInput
        .toLowerCase()
        .trim()[0]
        .toUpperCase()}${lowerCaseInput.trim().slice(1)}`;
};
