export type NewsType = {
    id: string;
    name: string;
    values: string[];
};

export const NEWS_TYPE_LABELS = {
    NEWS: "news.label",
    COMPANY_ANNOUNCEMENT: "announcement.label",
};

export const NEWS_TYPE_ICONS = {
    NEWS: "article",
    COMPANY_ANNOUNCEMENT: "team",
};
