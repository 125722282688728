import { ModalImage as _ModalImage, CTA, Icon } from "@2po-dpam/components";
import RichText from "@components/RichText";
import { CTAVariations, IAssemblyEmbeddedCtaModal } from "@types";
import { graphql } from "gatsby";
import React, { useState } from "react";

type Props = {
    data?: IAssemblyEmbeddedCtaModal;
    options?: {
        type?: string;
        color?: string;
        className?: string;
        variant?: CTAVariations;
    };
    children?: React.ReactNode | string;
};

const variationMap: { [key in CTAVariations]: string } = {
    Block: "filled",
    "Inverted block": "outlined",
    Text: "text",
};

const mapVariation = (variation?: CTAVariations) => {
    if (!variation) return undefined;
    return variationMap[variation] || variation;
};

const CallToActionModal = ({ data, options, children }: Props) => {
    const [openModalImage, toggleOpen] = useState(false);

    const variant = mapVariation(data?.variation ?? options?.variant);

    const openModal = data?.cta?.modal;

    return (
        <>
            {openModal && (
                <_ModalImage
                    backgroundImage={openModal?.backgroundImage}
                    closable={false}
                    closeAriaLabel={"closeButton"}
                    icon={
                        <Icon
                            color={options?.color ?? "white"}
                            name={openModal?.icon?.icon}
                            size={90}
                        />
                    }
                    isOpen={openModalImage}
                    onClose={() => toggleOpen(false)}
                    title={openModal?.title}
                >
                    {openModal?.text && <RichText textNode={openModal.text} />}
                </_ModalImage>
            )}
            <CTA
                {...options}
                color={options?.color || "white"}
                onClick={() => (openModal ? toggleOpen(true) : null)}
                variant={variant}
            >
                {data?.cta?.text || children}
            </CTA>
        </>
    );
};

export default CallToActionModal;

export const query = graphql`
    fragment ModalImage on ContentfulModalImage {
        id
        contentful_id
        __typename
        internalName
        title
        backgroundImage {
            id
            alt
            image {
                url
                file {
                    fileName
                    url
                }
            }
        }
        icon {
            icon
        }
        text {
            ...RichText
        }
    }
    fragment CallToActionModal on ContentfulContentCtaModal {
        contentful_id
        __typename
        text
        modal {
            ...ModalImage
        }
    }
`;
