import { FeaturedCard as _FeaturedCard } from "@2po-dpam/components";
import { Image, RichText } from "@components";
import { ContentfulImageData } from "@components/Image";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import React from "react";

type Theme = "primary" | "gray" | "white";

type FeaturedCardData = {
    backgroundImage: ContentfulImageData;
    content: {
        text: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    };
    theme: Theme;
};

type Props = {
    data: FeaturedCardData;
};

const FeaturedCard = ({ data: { backgroundImage, content, theme } }: Props) => (
    <_FeaturedCard
        content={
            content && !isEmptyDocument(content.text) ? (
                <RichText textNode={content} />
            ) : null
        }
        image={
            backgroundImage && (
                <Image
                    {...backgroundImage}
                    alt={backgroundImage?.alt || "Featured image"}
                />
            )
        }
        theme={theme}
    />
);

export default FeaturedCard;

export const query = graphql`
    fragment FeaturedCard on ContentfulContentFeaturedCard {
        id
        __typename
        contentful_id
        backgroundImage {
            ...ImageConstrained
        }
        content {
            text {
                raw
                references {
                    ...EmbeddedCTA
                }
            }
            unorderedListIcon {
                icon
            }
        }
        theme
    }
`;
