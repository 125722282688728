import { type Context } from "@hooks/use-context-store";
import { getDateTimeFormatter } from "@utils/INTL";
import { getInvestorSpecificContent } from "@utils/investors";

export const getPageSlug = (
    pages: any[],
    { investor, language }: Context,
    id: string,
) => {
    const localePages = pages.filter(
        page =>
            page.node_locale === language &&
            page.pageType &&
            Object.keys(page.pageType).length,
    );
    const linkedPage = localePages.find(page => {
        const specificContent = getInvestorSpecificContent(
            investor,
            page.pageType,
        );
        return (
            specificContent?.contentful_id === id ||
            page.pageType.generalContent?.contentful_id === id
        );
    });

    return linkedPage?.slug;
};

const addPublishedDateToPages = (
    pages: any[],
    investor: string,
    language?: string,
) =>
    pages
        .filter(page => getInvestorSpecificContent(investor, page))
        .map(page => {
            const content = getInvestorSpecificContent(investor, page);
            page.publishedDate = getPublishDateLongDateFormatted(
                content.publishDate,
                content.createdAt,
                content.updatedAt,
                language,
            );
            return page;
        });

export const sortPages = (pages: any[], investor: string) => {
    const mapPagesWithPublishDates = addPublishedDateToPages(pages, investor);
    return mapPagesWithPublishDates?.sort((pagesA, pagesB) => {
        if (pagesA.publishedDate > pagesB.publishedDate) {
            return -1;
        }
        if (pagesA.publishedDate < pagesB.publishedDate) {
            return 1;
        }
        return 0;
    });
};

export const getPublishDateLongDateFormatted = (
    publishDate: string,
    createdAt: string,
    updatedAt: string,
    language?: string,
) => {
    const availableDate = getDateDependingPublishCreatedAtOrUpdatedAt(
        publishDate,
        createdAt,
        updatedAt,
    );
    return language
        ? getDateTimeFormatter(language, "longDate")(availableDate)
        : availableDate;
};

const getDateDependingPublishCreatedAtOrUpdatedAt = (
    publishDate: string,
    createdAt: string,
    updatedAt: string,
) => publishDate || createdAt || updatedAt;

export const deleteRepeatedItemsByContentfulId = (items: any[]) => {
    return items.reduce((accumulatorCards, currentCard) => {
        if (
            !accumulatorCards.some(
                accumulatorCard =>
                    accumulatorCard.contentful_id === currentCard.contentful_id,
            )
        ) {
            accumulatorCards.push(currentCard);
        }
        return accumulatorCards;
    }, []);
};
