import { isArrayWithContent } from "../arrays";

export const isEmptyTextNode = (node: any): boolean =>
    node.content.length === 1 &&
    node.content[0].nodeType === "text" &&
    !node.content[0].value;

const isEmptyListNode = (node: any): boolean =>
    node.content?.length === 1 &&
    node.content[0].nodeType === "list-item" &&
    isEmptyTextNode(node.conent[0].content[0]);

export const isEmptyDocument = (data: any): boolean => {
    if (!data || !data?.raw) return true;
    if (data.raw) data = JSON.parse(data.raw);
    if (!data.content) return true;
    return data.content.every(node => {
        if (isEmptyTextNode(node)) return true;
        if (isEmptyListNode(node)) return true;
        if (!isArrayWithContent(node.content)) return true;
        return node.content.every(
            childNode =>
                !childNode?.value &&
                !isArrayWithContent(childNode.content) &&
                !childNode.data?.target,
        );
    });
};
