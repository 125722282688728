import { CallToAction as CTA } from "@2po-dpam/components";
import { THEMES } from "@constants";
import { useContextStore, useLink, usePageContext, useRouting } from "@hooks";
import { CTAVariations, ICta, IDocumentPolicy } from "@types";
import downloadFile from "@utils/download";
import { getFixedUrlIfMarketAndLanguageDoesNotExist } from "@utils/url";
import { Link as GatsbyLink, graphql } from "gatsby";
import React, { useEffect, useState } from "react";

type Props = {
    data?: ICta;
    href?: string;
    options?: {
        type?: string;
        inline?: boolean;
        color?: string;
        className?: string;
        noUnderline?: boolean;
        variant?: CTAVariations;
        theme?: string;
    };
    children?: React.ReactNode | string;
};

const createDocumentLink = (document?: IDocumentPolicy) => {
    if (!document?.slug || !document?.node_locale) return;
    const { slug, node_locale } = document;
    return `/documents/${slug}-${node_locale.replace(/-./g, x =>
        x[1].toUpperCase(),
    )}`;
};

const variationMap: { [key in CTAVariations]: string } = {
    Block: "filled",
    "Inverted block": "outlined",
    Text: "text",
};

const mapVariation = (variation?: CTAVariations) => {
    if (!variation) return undefined;
    return variationMap[variation] || variation;
};

/**
 *    Notes:
 *     - no option in contentmodel for fullwith, despite designs showing those
 *     - no option in contentmodel to distinguish between "filled" & "outlined" buttons, despite designs
 *     - idem for text size (16 or 18; 16 is default now...)
 */

const CallToAction = ({ data, href, options, children }: Props) => {
    const [hrefToUse, setHrefToUse] = useState<string | undefined>(undefined);
    const [documentLink, setDocumentLink] = useState<string | undefined>(
        undefined,
    );

    const { pages } = usePageContext();
    const { language, market } = useContextStore();
    const destinationPage = pages.find(page =>
        [data?.internalLink?.pageLink?.slug, href].includes(page.slug),
    );
    const themeClass = destinationPage?.pageTheme
        ? THEMES[options?.theme || destinationPage.pageTheme]
        : null;

    const { getPathForSlug } = useRouting();
    const { internalLink, externalLink } = useLink(data);

    useEffect(() => {
        setDocumentLink(createDocumentLink(data?.document));
    }, [data]);

    useEffect(() => {
        const link = documentLink
            ? undefined
            : internalLink || externalLink || getPathForSlug(href || "");

        if (link && language)
            setHrefToUse(
                getFixedUrlIfMarketAndLanguageDoesNotExist(
                    link,
                    language,
                    market,
                ),
            );
    }, [documentLink]);

    return (
        <CTA
            {...options}
            LinkComponent={externalLink ? undefined : GatsbyLink}
            className={options?.className}
            color={options?.color}
            href={hrefToUse}
            id={data?.id}
            onClick={documentLink ? () => downloadFile(documentLink) : null}
            openInNewTab={data?.targetBlank}
            themeColor={themeClass}
            type={
                options?.type || data?.variation === "Text" ? "text" : "button"
            }
            variant={mapVariation(data?.variation ?? options?.variant)}
        >
            {data?.text || children}
        </CTA>
    );
};

export default CallToAction;

export const query = graphql`
    fragment CallToAction on ContentfulContentCta {
        id
        contentful_id
        __typename
        targetBlank
        text
        icon {
            icon
        }
        externalLink
        internalLink {
            ...NavLink
        }
        document {
            slug
            title
            node_locale
        }
    }
    fragment EmbeddedCTA on ContentfulAssemblyEmbeddedCta {
        id
        __typename
        contentful_id
        cta {
            ...CallToAction
        }
        variation
    }
    fragment NavLink on ContentfulNavigationLink {
        anchorLink {
            ... on ContentfulAssemblyAngleCollectionSection {
                id
                anchorLink
            }
            ... on ContentfulAssemblyColumnsSection {
                id
                anchorLink
            }
            ... on ContentfulAssemblySection {
                id
                anchorLink
            }
            ... on ContentfulAssemblySplitSection {
                id
                anchorLink
            }
            ... on ContentfulAssemblyOverlappingSplitSection {
                id
                anchorLink
            }
            ... on ContentfulContentRichText {
                id
                anchorLink
            }
        }
        pageLink {
            slug
        }
    }
`;
