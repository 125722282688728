import { Flag, Icon, Select } from "@2po-dpam/components";
import HighChartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import Highcharts from "highcharts/highstock";

import * as style from "./style.module.scss";

import classnames from "classnames";
import React, { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
/**
 * Spider graph component for the Paris Performance Index
 *
 * @interface SpiderGraphProps
 * @typedef {SpiderGraphProps}
 */
interface SpiderGraphProps {
    // Define any props you need here
    countries: any[];
    selectedCountries: any[];
    setSelectedCountries: (any) => void;
    maxSelectedCountries: number;
    setActiveCountry: (string) => void;
}

const SpiderGraph: React.FC<SpiderGraphProps> = ({
    countries,
    selectedCountries,
    setSelectedCountries,
    maxSelectedCountries,
    setActiveCountry,
}) => {
    const [activeCountries, setActiveCountries] = useState<string[]>([]);

    // Add this useEffect hook to select the first two countries when the component mounts
    React.useEffect(() => {
        if (countries.length > 0 && selectedCountries.length === 0) {
            const firstCountry = countries[0].name;
            const secondCountry = countries[1].name;
            setSelectedCountries([firstCountry, secondCountry]);
        }
    }, [countries, selectedCountries, setSelectedCountries, setActiveCountry]);

    // highcharts-more is a plugin for Highcharts that adds support for more chart types
    HC_more(Highcharts);

    // Define the colors for the graph
    const COLORS = ["#123269", "#5A8BE4", "#AAACB2", "#A871B3", "#B0DEF2"];
    const FADED_COLORS = [
        "#B8C1D2",
        "#CEDCF7",
        "#E6E6E8",
        "#E5D5E8",
        "#E7F5FB",
    ];

    // Filter the countries to only include the selected countries
    const usedCountries = countries.filter(country =>
        selectedCountries.includes(country.name),
    );

    // Define the categories for the graph
    const categories = [
        "Current emissions",
        "Projected emissions",
        "Net zero target",
        "Adaptation",
        "Means of implementation",
        "Multilateral transparency",
    ];

    const series = usedCountries.map((country, index) => {
        let colorDefinition = COLORS[index];

        if (
            activeCountries.length > 0 &&
            !activeCountries.includes(country.name)
        ) {
            colorDefinition = FADED_COLORS[index];
        }

        return {
            name: country.name,
            data: categories.map(
                category =>
                    Number(
                        country.categories.find(c => c.title === category)
                            ?.score,
                    ) ?? 0,
            ),
            pointPlacement: "on",
            marker: {
                symbol: "circle",
            },
            color: colorDefinition,
        };
    });

    const sortedSeries = series.sort((a, b) => {
        const aIsActive = activeCountries.includes(a.name);
        const bIsActive = activeCountries.includes(b.name);
        return Number(aIsActive) - Number(bIsActive);
    });

    const options = {
        chart: {
            polar: true,
            height: null,
            width: null,
        },
        title: {
            text: "",
        },
        pane: {
            size: "80%",
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                showInLegend: false, // Hide the legend for each series
                lineWidth: 5,
                allowPointSelect: true,
                states: {
                    hover: {
                        enabled: true,
                        lineWidthPlus: 0,
                    },
                },
            },
        },
        xAxis: {
            categories: categories,
            tickmarkPlacement: "on",
            lineWidth: 0,
            labels: {
                style: {
                    color: "#123269",
                },
            },
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            lineWidth: 0,
            lineColor: "#F1F1F1", // Set the color of the yAxis line
            min: 0,
            max: Math.max(...series.map(s => Math.max(...s.data))),
            tickInterval: 20,
            labels: {
                style: {
                    color: "#F1F1F1",
                },
                zIndex: 1, // Set the zIndex of the yAxis labels to the lowest value
            },
        },
        colors: COLORS,
        tooltip: {
            shared: true,
            pointFormat:
                '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
        },
        series: sortedSeries.map((serie, index) => ({
            ...serie,
            zIndex: index,
        })),
        credits: {
            enabled: false, // Disable the Highcharts.com credit
        },
    };

    const activateCountry = (countryName: string) => {
        if (activeCountries.includes(countryName)) {
            setActiveCountries(activeCountries.filter(c => c !== countryName));
        } else {
            setActiveCountries([...activeCountries, countryName]);
            setActiveCountry(countryName);
        }
    };

    return (
        <div className={style.graphWrapper}>
            <div className={style.countrySelection}>
                <Select
                    className={style.select}
                    emptyValue="Select countries to compare"
                    hasInputFilter
                    items={countries.map(country => country.name)}
                    maxSelections={maxSelectedCountries}
                    multiple={true}
                    noBoldStyling={true}
                    onChange={setSelectedCountries}
                    value={selectedCountries}
                />
                {useMediaQuery("(min-width: 1024px)") && (
                    <span className={style.filterHelptext}>
                        {selectedCountries.length}/{maxSelectedCountries}{" "}
                        selected
                    </span>
                )}
            </div>
            {selectedCountries.length > 0 && (
                <div>
                    <HighChartsReact
                        containerProps={{ style: { height: "698px" } }}
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            )}
            <div className={style.graphLegend}>
                {usedCountries.map((country, index) => (
                    <div
                        className={classnames({
                            [style.legendItem]: true,
                            [style.activeLegendItem]: activeCountries.includes(
                                country.name,
                            ),
                        })}
                        key={country.name}
                        onClick={() => activateCountry(country.name)}
                    >
                        <div className={style.flagContainer}>
                            <Flag country={country.name} size="small" />
                        </div>
                        <span className={style.name}>{country.name}</span>
                        {activeCountries.includes(country.name) && (
                            <div
                                className={style.iconWrapper}
                                onClick={() => {
                                    const updatedSelectedCountries =
                                        selectedCountries.filter(
                                            selectedCountry =>
                                                selectedCountry !==
                                                country.name,
                                        );
                                    setSelectedCountries(
                                        updatedSelectedCountries,
                                    );
                                }}
                            >
                                <Icon color="black" name="close" size={15} />
                            </div>
                        )}
                        <div
                            className={classnames({
                                [style.legendBackground]: true,
                                [style.active]: activeCountries.includes(
                                    country.name,
                                ),
                                [style.inactive]:
                                    activeCountries.length > 0 &&
                                    !activeCountries.includes(country.name),
                            })}
                            style={{
                                backgroundColor: COLORS[index],
                            }}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SpiderGraph;
