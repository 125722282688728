import { RichText } from "@components";
import { TextOptions } from "@components/RichText";
import { Document, IRichText } from "@types";
import { isEmptyDocument } from "@utils/richText";
import React from "react";

const useRichText = () => {
    const handleTitle = (
        title?: Document,
        options: TextOptions = { variant: "h2" },
    ) =>
        !isEmptyDocument(title) ? (
            <RichText
                options={options}
                textNode={{ text: { ...title, references: [] } }}
            />
        ) : null;

    const handleDescription = (description?: IRichText) =>
        description && !isEmptyDocument(description.text) ? (
            <RichText textNode={description} />
        ) : null;

    return { handleTitle, handleDescription };
};

export default useRichText;
