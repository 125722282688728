import { Button, Select, Typography } from "@2po-dpam/components";
import { RichText } from "@components";
import { OnboardingPopupProps } from "@types";
import classNames, { default as classnames } from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import StepWizard from "react-step-wizard";

import * as style from "./style.module.scss";

type StepWizardParams = Parameters<typeof StepWizard>;

type ContentProps = OnboardingPopupProps &
    StepWizardParams & {
        t?: any;
        nextStep: () => void;
        currentStep: number;
    };

const Nav = ({ enabledIndex, totalSteps, currentStep, goToStep }: any) => {
    const dots: any[] = [];
    for (let i = 1; i <= totalSteps; i += 1) {
        const isActive = currentStep === i;
        const disabled = enabledIndex < i;

        dots.push(
            <button
                className={classNames(style.dot, {
                    [style.enabled]: !disabled,
                    [style.active]: isActive,
                })}
                disabled={disabled}
                key={`step-${i}`}
                onClick={() => {
                    if (!disabled) goToStep(i);
                }}
            >
                {i}
            </button>,
        );
    }

    return <div className={style.nav}>{dots}</div>;
};

const First = ({
    id,
    markets,
    currentLanguage,
    handleChangeMarket,
    formValues,
    nextStep,
    countryLabel,
}: ContentProps) => (
    <div className={style.fadeBlock}>
        <label
            htmlFor={`${id}-select-market-trigger`}
            id={`${id}-select-market-label`}
        >
            <Typography
                className={style.selectLabel}
                removeMargin
                variant="para"
            >
                {countryLabel}
            </Typography>
        </label>
        <Select
            className={style.select}
            id={`${id}-select-market`}
            items={markets}
            labelId={`${id}-select-market-label`}
            locale={currentLanguage}
            noBoldStyling
            onChange={(val: string) => {
                handleChangeMarket(val);
                nextStep();
            }}
            optionClassName={style.option}
            tabIndex={2}
            value={formValues.market}
        />
    </div>
);

const Second = ({
    touched,
    id,
    languages,
    handleChangeLanguage,
    formValues,
    nextStep,
    languageLabel,
}: ContentProps) => (
    <div className={style.fadeBlock}>
        <label
            htmlFor={`${id}-select-language-trigger`}
            id={`${id}-select-language-label`}
        >
            <Typography
                className={style.selectLabel}
                removeMargin
                variant="para"
            >
                {languageLabel}
            </Typography>
        </label>
        <Select
            className={style.select}
            disabled={!touched.includes("market")}
            id={`${id}-select-language`}
            items={languages}
            labelId={`${id}-select-language-label`}
            noBoldStyling
            onChange={(val: string) => {
                handleChangeLanguage(val);
                nextStep();
            }}
            optionClassName={style.option}
            tabIndex={3}
            value={formValues.language}
        />
    </div>
);

const Third = ({
    touched,
    investorTypes,
    id,
    currentLanguage,
    handleChangeInvestortype,
    formValues,
    investorTypeLabel,
    nextStep,
}: ContentProps) => (
    <div className={classnames(style.fadeBlock)}>
        <label
            htmlFor={`${id}-select-investortype-trigger`}
            id={`${id}-select-investortype-label`}
        >
            <Typography
                className={style.selectLabel}
                removeMargin
                variant="para"
            >
                {investorTypeLabel}
            </Typography>
        </label>
        <Select
            className={style.select}
            disabled={!touched.includes("language")}
            id={`${id}-select-investortype`}
            items={investorTypes}
            labelId={`${id}-select-investortype-label`}
            locale={currentLanguage}
            noBoldStyling
            onChange={(val: string) => {
                handleChangeInvestortype(val);
                nextStep();
            }}
            optionClassName={style.option}
            tabIndex={1}
            value={formValues.investorType}
        />
    </div>
);

const Fourth = ({
    formValues,
    handleSetPreferences,
    id,
    buttonLabel,
    disclaimerText,
    currentStep,
}: ContentProps) => (
    <div className={style.fadeBlock}>
        <div className={style.caption}>
            {currentStep === 4 && (
                <RichText textNode={{ text: disclaimerText }} />
            )}
        </div>
        <Button
            disabled={
                !formValues.investorType ||
                !formValues.language ||
                !formValues.market
            }
            fullWidth
            id={`${id}-btn-submit`}
            onClick={handleSetPreferences}
            tabIndex={4}
            variant="filled"
        >
            {buttonLabel}
        </Button>
    </div>
);

const OnboardingPopupStepper = ({
    id = "OnboardPopupStepper",
    touched,
    ...props
}: OnboardingPopupProps) => {
    const { t } = useTranslation();

    const getEnabledNavIndex = () => {
        if (touched.includes("investorType")) {
            return 4;
        } else if (touched.includes("language")) {
            return 3;
        } else if (touched.includes("market")) {
            return 2;
        } else {
            return 1;
        }
    };

    const enrichedProps = { ...props, id, t, touched } as ContentProps;

    return (
        <StepWizard
            nav={<Nav enabledIndex={getEnabledNavIndex()} />}
            transitions={{}}
        >
            <First {...enrichedProps} />
            <Second {...enrichedProps} />
            <Third {...enrichedProps} />
            <Fourth {...enrichedProps} />
        </StepWizard>
    );
};

export default OnboardingPopupStepper;
