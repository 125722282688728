import { COOKIE_EXPIRATION_TIME } from "@constants";
import { CookieKey } from "@types";
import { useCookies as reactUseCookies } from "react-cookie";

interface CookieSetOptions {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: boolean | "none" | "lax" | "strict";
    encode?: (value: string) => string;
}

// Keys: indicate on which cookie change the hook should trigger a re-render
const useCookies = (keys?: CookieKey[]) => {
    const [cookies, setReactCookie, removeCookie] = reactUseCookies(keys);

    const setCookie = (
        key: CookieKey,
        value: any,
        options: CookieSetOptions | undefined = {
            path: "/",
            maxAge: COOKIE_EXPIRATION_TIME,
        },
    ) => setReactCookie(key, value, options);

    return { cookies, setCookie, removeCookie };
};

export default useCookies;
