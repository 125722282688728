import { Document as _Document } from "@2po-dpam/components";
import { useContextStore } from "@hooks";
import { IDocumentPolicy } from "@types";
import { getDateTimeFormatter } from "@utils/INTL";
import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    data: IDocumentPolicy;
};

const Document = ({ data: { title, node_locale, slug, date } }: Props) => {
    const { t } = useTranslation();
    const { language } = useContextStore();
    const formattedDate =
        date && getDateTimeFormatter(language, "longDate")(date);
    const locale = node_locale?.replace(/-./g, x => x[1].toUpperCase());
    const documentUrl = `/documents/${slug}-${locale}`;

    return (
        <_Document
            actionLabel={t("open")}
            date={formattedDate}
            label={title}
            url={documentUrl}
        />
    );
};

export default Document;

export const query = graphql`
    fragment Document on ContentfulTopicDocumentPolicy {
        id
        updatedAt
        contentful_id
        __typename
        slug
        description {
            description
        }
        title
        node_locale
        date
    }
`;
