import React from "react";

type ContextStore = {
    investor: string;
    language: string | undefined;
    pageId: string;
    market: string;
    disabledInvestorTypes?: string[];
    fallbackSlug?: string;
};
export const ContextStore = React.createContext<ContextStore>({
    investor: "",
    language: undefined,
    pageId: "",
    market: "",
    disabledInvestorTypes: undefined,
    fallbackSlug: undefined,
});
