import { ContactForm as Form } from "@components";
import { useDataLayer } from "@hooks";
import axios from "axios";
import React, { useState } from "react";

import type { FormData } from "../../components/ContactForm";

interface Props {
    data: {
        formTitle: { raw: string };
    };
    options?: any;
}

const FORM_ID = "contact-form";
const FORM_DESTINATION = "/contact";

const ContactForm = ({ data: { formTitle }, options }: Props) => {
    const { pushEvent } = useDataLayer();
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (formData: FormData) => {
        setLoading(true);
        try {
            await axios.post(FORM_DESTINATION, formData);
            setSubmitted(true);
            setLoading(false);
            pushEvent({
                name: "contact-form-submission-succesful",
            });
        } catch {
            setSubmitError(true);
            setLoading(false);
            pushEvent({
                name: "contact-form-submission-unsuccesful",
            });
        }
    };

    return (
        <Form
            error={submitError}
            formTitle={formTitle}
            id={FORM_ID}
            loading={loading}
            onSubmit={submit}
            options={options}
            submitted={submitted}
        />
    );
};

export default ContactForm;
