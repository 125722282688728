import { CustomParams } from "@interfaces";
import { EventCommands, EventNames } from "@types";

import useContextStore from "./use-context-store";

interface DataLayerPushInterface {
    command?: EventCommands;
    name: EventNames;
    data?: CustomParams;
}

export const useDataLayer = () => {
    const { language, market, investor } = useContextStore();

    const pushEvent = ({
        name,
        command = "event",
        data = {},
    }: DataLayerPushInterface) => {
        if (typeof window !== "undefined" && name && data)
            window.gtag?.(command, name, {
                content_language: language,
                country_of_investor: market,
                type_of_investor: investor,
                ...data,
            });
    };

    return { pushEvent };
};

export default useDataLayer;
