import { NAV_HEIGHT_DESKTOP, NAV_HEIGHT_MOBILE } from "@constants";
import { RefObject } from "react";
import useIsMobile from "./use-is-mobile";

const useScrollNextSection = (currentSectionRef: RefObject<HTMLDivElement>) => {
    const isMobile = useIsMobile();

    const handleScroll = () => {
        if (currentSectionRef?.current && typeof window !== "undefined") {
            const offset = isMobile ? NAV_HEIGHT_MOBILE : NAV_HEIGHT_DESKTOP;

            window.scrollTo({
                top: currentSectionRef.current.clientHeight - offset,
                behavior: "smooth",
            });
        }
    };

    return { handleScroll };
};

export default useScrollNextSection;
