exports.components = {
  "component---src-templates-angle-index-tsx": () => import("./../../../src/templates/angle/index.tsx" /* webpackChunkName: "component---src-templates-angle-index-tsx" */),
  "component---src-templates-detail-page-index-tsx": () => import("./../../../src/templates/detailPage/index.tsx" /* webpackChunkName: "component---src-templates-detail-page-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-overview-ppri-index-tsx": () => import("./../../../src/templates/overviewPPRI/index.tsx" /* webpackChunkName: "component---src-templates-overview-ppri-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-redirect-index-tsx": () => import("./../../../src/templates/redirect/index.tsx" /* webpackChunkName: "component---src-templates-redirect-index-tsx" */),
  "component---src-templates-search-results-index-tsx": () => import("./../../../src/templates/searchResults/index.tsx" /* webpackChunkName: "component---src-templates-search-results-index-tsx" */)
}

