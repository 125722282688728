import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

const usePageContext = () => {
    const {
        pages: { group: marketLanguages, nodes: pages },
    } = useStaticQuery(graphql`
        query pageContext {
            pages: allContentfulComposePage {
                group(field: { node_locale: SELECT }) {
                    fieldValue
                }
                nodes {
                    id
                    contentful_id
                    node_locale
                    disablePageForInvestorTypes
                    slug
                    pageTheme
                    pageType {
                        ... on ContentfulPageTypeNewsOrAnnouncementPage {
                            id
                            __typename
                            contentful_id
                            generalContent {
                                id
                            }
                            professionalIntermediaryContent {
                                content {
                                    id
                                }
                            }
                            retailContent {
                                content {
                                    id
                                }
                            }
                            professionalEndInvestorContent {
                                content {
                                    id
                                }
                            }
                        }
                        ... on ContentfulPageTypeAnglePage {
                            id
                            __typename
                            contentful_id
                            generalContent {
                                id
                            }
                            professionalIntermediaryContent {
                                content {
                                    id
                                }
                            }
                            retailContent {
                                content {
                                    id
                                }
                            }
                            professionalEndInvestorContent {
                                content {
                                    id
                                }
                            }
                        }
                        ... on ContentfulPageTypeContentPage {
                            id
                            __typename
                            contentful_id
                            generalContent {
                                id
                            }
                            professionalIntermediaryContent {
                                content {
                                    id
                                }
                            }
                            retailContent {
                                content {
                                    id
                                }
                            }
                            professionalEndInvestorContent {
                                content {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    // Temporary fix as PT is not being returned from the query due to the 30 locale CF limit
    const enrichedMarkets = useMemo(
        () => marketLanguages?.concat([{ fieldValue: "en-PT" }]),
        [marketLanguages?.length],
    );

    return { pages, marketLanguages: enrichedMarkets };
};

export default usePageContext;
