import { ValuesCard as Card } from "@2po-dpam/components";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

type Props = {
    data: {
        image: any;
    };
    options?: any;
};

const ValuesCard = ({ data: { image }, options }: Props) => (
    <Card
        caption={image.caption}
        hideContentMobile={options?.hideContentMobile}
        image={
            <GatsbyImage
                alt={image.title}
                image={image?.image?.gatsbyImageData}
            />
        }
        value={image.title}
    />
);

export default ValuesCard;

export const query = graphql`
    fragment ValuesCard on ContentfulTopicValue {
        id
        __typename
        contentful_id
        image {
            ...ImageConstrained
        }
    }
`;
