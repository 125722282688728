import { IOptionalSection } from "@types";
import { componentMapper } from "@utils/componentMapper";
import camelcase from "camelcase";
import { graphql } from "gatsby";
import React from "react";
import * as style from "./style.module.scss";

type Props = {
    data: IOptionalSection;
};
const OptionalSection = ({ data }: Props) => {
    if (!data?.optionalContent?.length) return <></>;

    const { optionalContent, configuration } = data;

    return (
        <div className={configuration && style[camelcase(configuration)]}>
            {optionalContent.map((item, index) =>
                componentMapper(item, undefined, index),
            )}
        </div>
    );
};

export default OptionalSection;

export const query = graphql`
    fragment OptionalSection on ContentfulAssemblyOptionalSection {
        __typename
        id
        configuration
        optionalContent {
            ...AngleCollectionSection
            ...Carousel
            ...ColumnsSection
            ...DocumentSection
            ...FaqCollection
            ...FundsOverview
            ...Hero
            ...HeroCarousel
            ...HighlightedArticleSection
            ...ImageWithCtaOverlay
            ...ReportHighlight
            ...ImageWithOverlay
            ...JobCards
            ...LatestArticlesSection
            ...MediaGridSection
            ...NewsCollectionSection
            ...CookieSection
            ...OverlapSection
            ...Section
            ...SplitSection
            ...TimelineSection
            ...SwitchInvestorTypeSection
            ...Tabs
        }
    }
`;
