interface Settings {
    wrapFrom: HTMLElement;
    wrapTo: string;
    wrapId: string;
}
const wrapElements = (opts: Settings) => {
    // setting the defaults for the function:
    const settings = {
        ...opts,
        wrapFrom: opts.wrapFrom,
        wrapWith: "section",
        wrapId: "section-x",
    };

    // using Object.keys() to iterate over the
    // opts Object (or an empty object-literal)
    // if no opts Object is supplied:
    Object.keys(opts || {}).forEach(key => {
        // updating the default settings with
        // the value held in the opts Object:
        settings[key] = opts[key];
    });

    // if there is no settings.wrapFrom node, or
    // no settings.wrapTo string:
    if (!settings.wrapFrom || !settings.wrapTo) {
        // we quit here:
        return false;
    }

    // creating the element with which we can wrap:
    const wrapper = document.createElement(settings.wrapWith);
    wrapper.id = settings.wrapId;
    // creating an Array-literal for later use:

    // here we navigate from the settings.wrapFrom
    // node to its parentNode, and insert the wrapper
    // Element before the next-sibling of the
    // settings.wrapFrom node:

    if (
        wrapper instanceof HTMLElement &&
        settings.wrapFrom.parentNode instanceof HTMLElement
    ) {
        settings.wrapFrom.parentNode.insertBefore(
            wrapper,
            settings.wrapFrom.nextSibling,
        );
    } else {
        return false;
    }

    // while there is a next-sibling of the wrapper,
    // and either that next-sibling is not an element
    // (which has a nodeType of 1), or the next-sibling
    // is an element node and that element node does not
    // match the selector supplied in settings.wrapTo:
    while (
        wrapper.nextSibling &&
        (wrapper.nextSibling.nodeType !== 1 ||
            (wrapper.nextSibling.nodeType === 1 &&
                !(wrapper.nextSibling as HTMLElement).matches(settings.wrapTo)))
    ) {
        // we append the next-sibling to the
        // wrapper:
        wrapper.appendChild(wrapper.nextSibling);
    }

    wrapper.insertBefore(settings.wrapFrom, wrapper.firstChild);
};

export default (elements: HTMLElement[], wrapTo: string) =>
    elements.forEach(heading => {
        const id = heading.innerText.replace(/\W/g, "-");
        wrapElements({
            wrapFrom: heading,
            wrapTo: wrapTo,
            wrapId: id,
        });
    });

export const getOffsetTopChild = (
    parent: HTMLDivElement,
    childSelector: string,
) => {
    if (!parent) return 0;
    const child: HTMLHRElement | null = parent.querySelector(childSelector);
    if (!child) return 0;

    return child.offsetTop - parent.offsetTop;
};

export const isSafari = () =>
    typeof window !== "undefined" &&
    /apple/gi.test(window?.navigator?.vendor) &&
    !/chrome/gi.test(window?.navigator?.userAgent);

export const getBrowserLanguage = () => {
    if (typeof window === "undefined") return "en";

    const language = navigator?.language;

    if (language.includes("-")) {
        const splitLanguage = language.split("-");
        return splitLanguage[0];
    }

    return language;
};
