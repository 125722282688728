import { NumberCard as _NumberCard, ThemeContext } from "@2po-dpam/components";
import { RichText } from "@components";
import { graphql } from "gatsby";
import React, { useContext } from "react";

type Props = {
    data: {
        numberTitle: {
            raw: string;
        };
        subTitle: {
            raw: string;
        };
        content: {
            text: {
                raw: string;
            };
        };
    };
};

const NumberCard = ({ data: { numberTitle, subTitle, content } }: Props) => {
    const containerBackground = useContext(ThemeContext);
    const inverted = containerBackground === "primary";
    return (
        <_NumberCard
            content={
                <RichText
                    options={{
                        removeMargin: true,
                        color: inverted ? "white" : "darkGrey",
                    }}
                    textNode={content.text}
                />
            }
            number={
                <RichText
                    options={{
                        variant: undefined,
                        color: inverted ? "white" : "primary",
                    }}
                    textNode={numberTitle}
                />
            }
            title={
                <RichText
                    options={{
                        variant: undefined,
                        color: inverted ? "white" : "primary",
                    }}
                    textNode={subTitle}
                />
            }
        />
    );
};

export default NumberCard;

export const query = graphql`
    fragment NumberCard on ContentfulAssemblyNumberCard {
        id
        __typename
        contentful_id
        numberTitle: title {
            raw
        }
        content {
            text {
                raw
            }
        }
        subTitle {
            raw
        }
    }
`;
