import { ContactPoint as _ContactPoint } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React from "react";

export type ContactPointData = {
    email: string;
    address?: string;
    contactTitle?: string;
    phoneNumber?: string;
    __typename: string;
};

type Props = {
    data: ContactPointData;
    options?: any;
};

const ContactPoint = ({
    data: { email, address, contactTitle, phoneNumber },
    options,
}: Props) => (
    <_ContactPoint
        address={address}
        className={options?.className}
        email={email}
        embedded={options?.embedded}
        phone={phoneNumber}
        title={contactTitle}
    />
);

export default ContactPoint;

export const query = graphql`
    fragment ContactPoint on ContentfulTopicContactPoint {
        id
        __typename
        contentful_id
        address
        email
        phoneNumber
        contactTitle: title
    }
`;
