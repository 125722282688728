import { AngleCard as _AngleCard } from "@2po-dpam/components";
import { Image, RichText } from "@components";
import { type ContentfulImageData } from "@components/Image";
import { NewsType, THEMES } from "@constants";
import { usePageContext, useRouting } from "@hooks";
import useContextStore from "@hooks/use-context-store";
import { getInvestorSpecificContent } from "@utils/investors";
import { isEmptyDocument } from "@utils/richText";
import classnames from "classnames";
import { Link, graphql } from "gatsby";
import React, { useMemo } from "react";

import { getPublishDateLongDateFormatted } from "@utils/pages";
import * as style from "./style.module.scss";

export type GeneralContent = {
    category: Array<{ subCategory: string }>;
    updatedAt: string;
    publishDate: string;
    createdAt: string;
    shortTitle: {
        raw: string;
    };
    introText: {
        raw: string;
    };
    icon: {
        icon: string;
    };
    formatIcon: {
        icon: string;
    };
    hero: {
        backgroundImage: ContentfulImageData;
    };
    ctaLabel?: string;
    type?: NewsType[]; //for news
};

export type Props = {
    data: {
        theme?: string; //only used for preview
        id: string;
        generalContent: GeneralContent;
        professionalIntermediaryContent: {
            content: GeneralContent;
        };
        retailContent: {
            content: GeneralContent;
        };
        professionalEndInvestorContent: {
            content: GeneralContent;
        };
    };
    showIntroText?: boolean;
    cardView?: boolean;
    options?: any;
};

const AngleCard = ({
    data,
    showIntroText = false,
    cardView = true,
    options,
}: Props) => {
    const { pages } = usePageContext();
    const { investor, language } = useContextStore();
    const anglePage = pages.find(page => page.pageType?.id === data.id);
    const content: GeneralContent = getInvestorSpecificContent(investor, data);
    const { getPathForSlug } = useRouting();
    const destination = getPathForSlug(anglePage?.slug);
    const backgroundImage = content?.hero?.backgroundImage;
    const themeToUse = data.theme || anglePage?.pageTheme;
    const themeClass = themeToUse ? THEMES[themeToUse] : null;

    const description = useMemo(() => {
        if (!(showIntroText && !isEmptyDocument(content?.introText)))
            return null;

        return (
            <RichText
                options={{
                    variant: "para-small",
                    maxLines: cardView ? 2 : 1,
                    embedClassNames: {
                        callToAction: style.embeddedCta,
                    },
                    removeMargin: !cardView,
                }}
                textNode={content.introText}
            />
        );
    }, [cardView]);

    if (!content) return null;

    const publicationDate = getPublishDateLongDateFormatted(
        content?.publishDate,
        content.createdAt,
        content?.updatedAt,
        language,
    );
    return (
        <_AngleCard
            LinkComponent={Link}
            angleIcon={content?.icon?.icon}
            cardView={cardView}
            categoryClassName={classnames(
                style[themeClass],
                options?.className,
            )}
            categoryIcon={content?.formatIcon?.icon}
            ctaLabel={content?.ctaLabel || undefined}
            description={description}
            destination={destination}
            embedded={options?.embedded}
            hideContentMobile={options?.hideContentMobile}
            id={data.id}
            image={
                backgroundImage && (
                    <Image
                        {...backgroundImage}
                        alt={backgroundImage?.alt || "Angle image"}
                    />
                )
            }
            publicationDate={publicationDate}
            shortTitle={
                !isEmptyDocument(content?.shortTitle) ? (
                    <RichText
                        className={style.shortTitle}
                        options={{ variant: "h4", color: "main" }}
                        textNode={content.shortTitle}
                    />
                ) : null
            }
            subCategory={content?.category?.[0].subCategory}
        />
    );
};

export default AngleCard;

export const query = graphql`
    fragment AngleCard on ContentfulPageTypeAnglePage {
        id
        __typename
        contentful_id
        generalContent {
            ...General
        }
        professionalIntermediaryContent {
            content {
                ...General
            }
        }
        retailContent {
            content {
                ...General
            }
        }
        professionalEndInvestorContent {
            content {
                ...General
            }
        }
    }

    fragment General on ContentfulPageContentAngle {
        contentful_id
        id
        ctaLabel
        publishDate
        createdAt
        updatedAt
        category {
            subCategory
        }
        icon {
            icon
        }
        formatIcon {
            icon
        }
        shortTitle {
            raw
        }
        introText {
            raw
        }
        hero {
            backgroundImage {
                ...ImageFullWidth
            }
        }
    }
`;
