import * as Yup from "yup";

import {
    CONTAINS_ONLY_CHARS_REGEX,
    INPUT_CHAR_MAX,
    INPUT_CHAR_MIN,
    MESSAGE_CHAR_LIMIT,
} from "@constants";
import { useTranslation } from "react-i18next";

interface BaseValidationOptions {
    min?: number;
    max?: number;
    required?: boolean;
    regex?: RegExp;
    message?: string;
}

const useFormValidation = () => {
    const { t } = useTranslation();

    const stringValidationBase = Yup.string();

    const errorMSG = {
        INPUT_LIMIT: t("error.inputLimit"),
        NAME: t("error.name"),
        FIRST_NAME: t("error.firstName"),
        SUBJECT: t("error.subject"),
        EMAIL: t("error.email"),
        MESSAGE: t("error.message"),
        MESSAGE_INPUT: t("error.messageInput"),
        TERMS: t("error.terms"),
        MIN: t("error.min"),
        MAX: t("error.max"),
        REQUIRED: t("error.required"),
    };

    const optionalCheck = (
        validation: any,
        optional?: boolean,
        message = errorMSG.REQUIRED,
    ) => (optional ? validation : validation.required(message));

    const minLengthMessage = obj =>
        t("error.min", { value: obj.min.toString() });

    const maxLengthMessage = obj =>
        t("error.max", { value: obj.max.toString() });

    const validationFunction = (
        min?: number,
        max?: number,
        regex?: RegExp,
        message: string = errorMSG.MESSAGE,
    ) => {
        const baseValidation = regex
            ? stringValidationBase.matches(regex, message)
            : stringValidationBase;

        if (min && max) {
            return baseValidation
                .min(min, minLengthMessage)
                .max(max, maxLengthMessage);
        }

        if (min) {
            return baseValidation.min(min, minLengthMessage);
        }

        if (max) {
            return baseValidation.max(max, maxLengthMessage);
        }

        return baseValidation;
    };

    const booleanValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean,
    ) => {
        if (optional) {
            return Yup.boolean();
        }

        return Yup.boolean().oneOf(
            [true],
            options.message || errorMSG.REQUIRED,
        );
    };

    const emailValidation = (optional?: boolean) => {
        const validation = validationFunction(
            undefined,
            INPUT_CHAR_MAX,
            undefined,
            errorMSG.EMAIL,
        ).email(errorMSG.EMAIL);

        return optionalCheck(validation, optional);
    };

    const termsValidation = (optional?: boolean) =>
        booleanValidation({ message: errorMSG.TERMS }, optional);

    const stringOnlyValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean,
    ) => {
        const validation = validationFunction(
            options.min,
            options.max,
            options.regex || CONTAINS_ONLY_CHARS_REGEX,
            options.message,
        );

        return optionalCheck(validation, optional);
    };

    const nameValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean,
    ) => {
        const validation = validationFunction(
            options.min || INPUT_CHAR_MIN,
            options.max || INPUT_CHAR_MAX,
            options.regex || CONTAINS_ONLY_CHARS_REGEX,
            options.message || errorMSG.NAME,
        );

        return optionalCheck(validation, optional);
    };

    const firstNameValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean,
    ) =>
        nameValidation(
            { ...options, message: options.message || errorMSG.FIRST_NAME },
            optional,
        );

    const subjectValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean,
    ) =>
        nameValidation(
            { ...options, message: options.message || errorMSG.SUBJECT },
            optional,
        );

    const messageValidation = (
        options: BaseValidationOptions = {},
        optional?: boolean,
    ) =>
        nameValidation(
            {
                ...options,
                max: options.max || MESSAGE_CHAR_LIMIT,
                message: options.message || errorMSG.MESSAGE,
            },
            optional,
        );

    const getValidationSchema = (schemaObject: any) =>
        Yup.object().shape(schemaObject);

    return {
        emailValidation,
        stringOnlyValidation,
        getValidationSchema,
        booleanValidation,
        termsValidation,
        nameValidation,
        firstNameValidation,
        subjectValidation,
        messageValidation,
    };
};

export default useFormValidation;
