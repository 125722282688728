import { SearchBar as _SearchBar } from "@2po-dpam/components";
import { SEARCH_SLUG } from "@constants";
import { useContextStore } from "@hooks";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    scrolled?: boolean;
    onResultPageSearch?: (q: string) => void;
};

const SearchBar = ({
    isOpen,
    onClose,
    scrolled,
    onResultPageSearch,
}: Props) => {
    const { t } = useTranslation();
    const { language, market, investor } = useContextStore();

    const onSearch = (q: string) => {
        if (location.pathname.includes(SEARCH_SLUG))
            onResultPageSearch && onResultPageSearch(q);
        else
            navigate(
                `/${investor}/${market}/${language}/${SEARCH_SLUG}?q=${q}`,
            );
    };

    return (
        <_SearchBar
            closeButtonLabel={t("search.close-button-label")}
            isOpen={isOpen}
            label={t("search.input-label")}
            onClose={onClose}
            onSearch={onSearch}
            placeholder={t("search.placeholder")}
            scrolled={scrolled}
            searchButtonLabel={t("search.search-button-label")}
        />
    );
};

export default SearchBar;
