import { Footer, Navigation, OnboardingPopup, PageHead } from "@components";
import { getMetaData } from "@components/PageHead";
import { THEMES } from "@constants";
import { useCookies } from "@hooks";
import { PageContext } from "@types";
import { ContextStore } from "@utils/context/ContextStore";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props extends PageContext {
    children: ReactNode;
    data: any;
    onSearch: (q: string) => void;
}

const DefaultLayout = ({
    children,
    data = {},
    pageContext,
    location,
    onSearch,
}: Props) => {
    const { cookies } = useCookies(["investorType"]);
    const [showModal, toggleModal] = useState(!cookies.investorType);
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(pageContext.language);
    }, []);

    const {
        pageContent,
        pageSeo,
        disablePageForInvestorTypes,
        fallbackPage,
        title,
        pageTheme,
    } = data?.page || {};

    if (!pageContext.useLayout) return <>{children}</>;

    return (
        <ContextStore.Provider
            value={{
                investor: pageContext.investor,
                language: pageContext.language,
                market: pageContext.market,
                pageId: pageContext.contentful_id,
                disabledInvestorTypes: disablePageForInvestorTypes,
                fallbackSlug: fallbackPage?.slug,
            }}
        >
            <PageHead
                canonicalUrl={location.origin + location.pathname}
                data={getMetaData({ title }, pageSeo)}
                hero={pageContent?.hero}
                lang={pageContext?.language}
                theme={THEMES[pageTheme]}
            />
            {/* Hide profile selection popup on preview mode */}
            {!process.env.GATSBY_PREVIEW_MODE && (
                <OnboardingPopup
                    currentLanguage={pageContext?.language}
                    isOpen={showModal}
                    onClose={() => toggleModal(false)}
                />
            )}
            <Navigation
                basePath={pageContext.basePath}
                currentPath={location?.pathname}
                investorType={pageContext.investor}
                language={pageContext.language}
                onResultPageSearch={onSearch}
            />
            {children}
            <Footer
                investorType={pageContext.investor}
                language={pageContext.language}
                locale={pageContext.locale}
            />
        </ContextStore.Provider>
    );
};

export default DefaultLayout;
