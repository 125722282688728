import { useContextStore } from "@hooks";
import { IIntroPopup } from "@types";
import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const usePopupData = (language?: string, market?: string) => {
    const { i18n } = useTranslation();
    const { language: contextLanguage, market: contextMarket } =
        useContextStore();

    const activeLanguage = language || contextLanguage;
    const activeMarket = market || contextMarket;
    const activeLocale = `${activeLanguage}-${activeMarket.toUpperCase()}`;

    const {
        popupData: { popups },
    }: { popupData: { popups: IIntroPopup[] } } = useStaticQuery(graphql`
        query OnboardingPopupQuery {
            popupData: allContentfulConfigIntroductionaryPopup {
                popups: nodes {
                    ...IntroPopup
                }
            }
        }
    `) || { popupData: { popups: [] } };

    return useMemo(() => {
        const findPopup = (nodeLocale: string) =>
            popups.find(({ node_locale }) => nodeLocale === node_locale);

        return findPopup(`${activeLocale}`) || popups[0];
    }, [i18n.language, market, language]);
};

export default usePopupData;
