import {
    CallToAction as _CallToAction,
    ReportHighlight as _ReportHighlight,
} from "@2po-dpam/components";
import { Image } from "@components";
import { ContentfulImageData } from "@components/Image";
import { graphql } from "gatsby";
import React from "react";

type ReportHighlightData = {
    document: {
        title: string;
        slug: string;
    };
    node_locale: string;
    ctaLabel: string;
    image: ContentfulImageData;
};

type Props = {
    data: ReportHighlightData;
};

const ReportHighlight = ({
    data: { document, ctaLabel, image, node_locale },
}: Props) => {
    const locale = node_locale.replace(/-./g, x => x[1].toUpperCase());
    const documentUrl = `/documents/${document.slug}-${locale}`;
    return (
        <_ReportHighlight
            cta={
                ctaLabel ? (
                    <_CallToAction href={documentUrl} openInNewTab>
                        {ctaLabel}
                    </_CallToAction>
                ) : null
            }
            image={
                image?.image ? (
                    <Image
                        {...image}
                        alt={image?.alt || "Report image"}
                        caption={undefined}
                        title={undefined}
                    />
                ) : null
            }
            title={document.title}
        />
    );
};

export default ReportHighlight;

export const query = graphql`
    fragment ReportHighlight on ContentfulAssemblyHighlightedReport {
        id
        contentful_id
        __typename
        node_locale
        document {
            title
            slug
        }
        image {
            ...ImageFullWidth
        }
        ctaLabel
    }
`;
