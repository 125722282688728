import { HighlightedArticle, Section as _Section } from "@2po-dpam/components";
import { CallToAction, RichText } from "@components";
import Image, { type Image as ImageType } from "@components/Image";
import { useContextStore, usePageContext } from "@hooks";
import { getInvestorSpecificContent } from "@utils/investors";
import { getPublishDateLongDateFormatted } from "@utils/pages";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import React from "react";

type ArticleContent = {
    shortTitle: {
        raw: string;
    };
    updatedAt: string;
    publishDate: string;
    createdAt: string;
    introText: {
        raw: string;
    };
    ctaLabel: string;
    id: string;
};

type SectionData = {
    id?: string;
    theme: "white" | "gray" | "primary";
    imageLeft?: boolean;
    image?: {
        alt?: string;
        image?: ImageType;
    };
    article: {
        __typename: string;
        id: string;
        contentful_id: string;
        generalContent: ArticleContent;
        professionalEndInvestorContent: {
            content: ArticleContent;
        };
        professionalIntermediaryContent: {
            content: ArticleContent;
        };
        retailContent: {
            content: ArticleContent;
        };
    };
};

type Props = {
    data: SectionData;
};

const HighlightedArticleSection = ({
    data: { id, theme, imageLeft, image, article },
}: Props) => {
    const { pages } = usePageContext();
    const { investor, language } = useContextStore();
    const referencedPage = pages.find(
        page => page.pageType?.id === article?.id,
    );
    const content: ArticleContent = getInvestorSpecificContent(
        investor,
        article,
    );

    const isAngle = /angle/i.test(article?.__typename);

    if (image?.image) {
        image.image.title = undefined;
        image.image.description = undefined;
    }

    const CTAElement = referencedPage && (
        <CallToAction
            data={{
                text: isAngle ? content?.ctaLabel : "Read more",
                id: `${id}-callToAction-${article?.id}`,
                internalName: content.ctaLabel,
                targetBlank: false,
                __typename: "ContentfulCta",
            }}
            href={referencedPage.slug}
            options={{ type: "button" }}
        />
    );

    const publicationDate = getPublishDateLongDateFormatted(
        content?.publishDate,
        content?.createdAt,
        content?.updatedAt,
        language,
    );
    return (
        <_Section background={theme} fullWidth id={id} noPadding>
            <HighlightedArticle
                Image={image && <Image alt={image?.alt} image={image?.image} />}
                callToAction={CTAElement}
                date={publicationDate}
                description={
                    isAngle && !isEmptyDocument(content?.introText) ? (
                        <RichText
                            options={{ inline: true, characterLimit: 160 }}
                            textNode={content.introText}
                        />
                    ) : null
                }
                id={`${id}-content`}
                imageLeft={imageLeft}
                isAngle={isAngle}
                title={
                    !isEmptyDocument(content?.shortTitle) ? (
                        <RichText
                            options={{
                                variant: "para-intro",
                                removeMargin: true,
                            }}
                            textNode={content.shortTitle}
                        />
                    ) : null
                }
            />
        </_Section>
    );
};

export default HighlightedArticleSection;

export const query = graphql`
    fragment HighlightedArticleSection on ContentfulAssemblyHighlightedArticleSection {
        __typename
        id
        theme
        imageLeft
        image {
            ...ImageConstrained
        }
        article {
            ...AngleArticle
            ...NewsArticle
        }
    }

    fragment AngleArticle on ContentfulPageTypeAnglePage {
        id
        __typename
        generalContent {
            ...AngleArticleContent
        }
        professionalEndInvestorContent {
            content {
                ...AngleArticleContent
            }
        }
        professionalIntermediaryContent {
            content {
                ...AngleArticleContent
            }
        }
        retailContent {
            content {
                ...AngleArticleContent
            }
        }
    }

    fragment AngleArticleContent on ContentfulPageContentAngle {
        id
        __typename
        shortTitle {
            raw
        }
        introText {
            raw
        }
        updatedAt
        createdAt
        publishDate
        ctaLabel
    }

    fragment NewsArticle on ContentfulPageTypeNewsOrAnnouncementPage {
        id
        __typename
        generalContent {
            ...NewsArticleContent
        }
        professionalEndInvestorContent {
            content {
                ...NewsArticleContent
            }
        }
        professionalIntermediaryContent {
            content {
                ...NewsArticleContent
            }
        }
        retailContent {
            content {
                ...NewsArticleContent
            }
        }
    }

    fragment NewsArticleContent on ContentfulPageContentNewsAndAnnouncements {
        id
        __typename
        shortTitle {
            raw
        }
        introText {
            raw
        }
        updatedAt
        createdAt
        publishDate
        # ctaLabel
    }
`;
