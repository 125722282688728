export default division => {
    switch (division?.toUpperCase()) {
        case "AM LEGAL":
            return "am-legal";
        case "ASSET MANAGEMENT FRANCE":
        case "DPAM":
            return "asset-management";
        case "ASSISTANT":
        case "PERSONAL ASSISTANT":
            return "assistant";
        case "COMPLIANCE":
            return "compliance";
        case "DPAM COO":
            return "coo";
        case "DPAM FIXED INCOME":
        case "GLOBAL BALANCED":
        case "FUNDAMENTAL EQUITY & LISTED REAL ESTATE":
        case "SYSTEMATIC EQUITY & FLEXIBLE ALLOCATION":
            return "fixed-income";
        case "DPAM VALUE ADDED SERVICES & REPORTING":
            return "value-added-services-and-reporting";
        case "IAM LU":
        case "INSTITUTIONAL SALES FUNDS BELGIUM":
        case "INSTITUTIONAL SALES INTERNATIONAL":
        case "INSTITUTIONAL SALES MANDATES BELGIUM":
            return "institutional-sales";
        case "MARKETING & DEVELOPMENT":
            return "marketing";
        case "QUANT SOLUTIONS & INNOVATION":
        case "STRATEGY OFFICE":
            return "strategy-office";
        case "REQUEST FOR PROPOSAL TEAM":
            return "rfp=team";
        case "RI COMPETENCE CENTER":
            return "ri-compentence-center";
        case "RISK MANAGEMENT":
            return "risk-management";
        default:
            return "other";
    }
};
