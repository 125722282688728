import useContextStore from "./use-context-store";

const useWhitepaper = () => {
    const { language } = useContextStore();

    const handle = (url: string) => {
        if (!url) return "";

        return url?.replace("/AAA/", `/${language}/`);
    };

    return { handle };
};

export default useWhitepaper;
