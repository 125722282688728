import "./google-map.scss";

import { Icon } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import * as ReactDOMServer from "react-dom/server";

import loader from "../../utils/googleMaps/loader";
import { mapsStyling } from "../../utils/googleMaps/mapStyling";
import * as style from "./style.module.scss";

type Props = {
    data: any;
};

const Map = ({ data }: Props) => {
    useEffect(() => {
        loader(api => {
            initMap(api, mapsStyling);
        });
        // eslint-disable-next-line
    }, []);
    const initMap = (google, styling) => {
        const normalIcon = {
            url:
                "data:image/svg+xml;charset=utf-8," +
                encodeURIComponent(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.56 477.48"><defs><style>.cls-1,.cls-2{fill:none;stroke:#333;stroke-width:22.84px;}.cls-1{stroke-linejoin:round;}.cls-2{stroke-miterlimit:10;}</style></defs><title>map-marker_1</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M349.14,180.28c0,93.26-167.69,285.78-167.69,285.78s-170-192.52-170-285.78a168.86,168.86,0,0,1,337.72,0Z"/><circle class="cls-2" cx="180.28" cy="180.28" r="68.52"/></g></g></svg>',
                ),
            scaledSize: new google.maps.Size(40, 40),
        };

        const activeIcon = {
            url:
                "data:image/svg+xml;charset=utf-8," +
                encodeURIComponent(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.56 477.48"><defs><style>.cls-1,.cls-2{fill:none;stroke:#00aa62;stroke-width:22.84px;}.cls-1{stroke-linejoin:round;}.cls-2{stroke-miterlimit:10;}</style></defs><title>Asset 2</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M349.14,180.28c0,93.26-167.69,285.78-167.69,285.78s-170-192.52-170-285.78a168.86,168.86,0,0,1,337.72,0Z"/><circle class="cls-2" cx="180.28" cy="180.28" r="68.52"/></g></g></svg>',
                ),
            scaledSize: new google.maps.Size(40, 40),
        };

        const mainOffice = data.offices[0];

        const getInfoWindowContent = office =>
            ReactDOMServer.renderToString(
                <div className="content">
                    <div className="office-name">
                        <strong>{office.cityName}</strong>
                        <span className="office-label">OFFICE</span>
                    </div>
                    <p>
                        <Icon color="white" name="home" />
                        {office.address}
                    </p>
                    <p>
                        <a href={`tel:${office.phoneNumber}`}>
                            <Icon color="white" name="phone" />
                            {office.phoneNumber}
                        </a>
                    </p>
                    <p>
                        <a href={`mailto:${office.email}`}>
                            <Icon color="white" name="at" />
                            {office.email}
                        </a>
                    </p>
                </div>,
            );

        const map = new google.maps.Map(
            document.getElementById(`map-offices`),
            {
                center: {
                    lat: mainOffice.latitude,
                    lng: mainOffice.longitude,
                },
                styles: styling,
                disableDefaultUI: true,
                zoomControl: true,
                minZoom: 3,
                zoom: 5,
            },
        );

        const markers = data?.offices.map(
            office =>
                new google.maps.Marker({
                    position: {
                        lat: office.latitude,
                        lng: office.longitude,
                    },
                    map,
                    icon: normalIcon,
                    office: office,
                }),
        );

        markers.forEach(marker => {
            marker.addListener("click", () => {
                infowindow.setContent(getInfoWindowContent(marker.office));
                map.panTo(marker.getPosition());
                map.panBy(0, -150);
                markers.forEach(marker => marker.setIcon(normalIcon));
                infowindow.open({ map, anchor: marker, shouldFocus: false });
                marker.setIcon(activeIcon);

                // infowindow.focus();
            });
        });

        const infowindow = new google.maps.InfoWindow({
            content: getInfoWindowContent(mainOffice),
        });

        map.addListener("click", function () {
            infowindow.close();
            markers.forEach(marker => marker.setIcon(normalIcon));
        });

        markers[0].setIcon(activeIcon);
        infowindow.open(map, markers[0]);
        map.panTo(markers[0].getPosition());
        map.panBy(0, -150);
    };

    return (
        <div className={style.wrapper}>
            <div className="google-map" id="map-offices" />
        </div>
    );
};

export default Map;

export const query = graphql`
    fragment Map on ContentfulContentMap {
        id
        __typename
        offices {
            cityName
            address
            email
            phoneNumber
            latitude
            longitude
        }
    }
`;
