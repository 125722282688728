import { getBrowserLanguage } from "@utils/dom";
import {
    getLanguagesForMarket,
    mapToMarketsAndLanguages,
} from "@utils/marketLanguages";
import { useTranslation } from "react-i18next";

import usePageContext from "./use-page-context";

const useBrowserLanguage = () => {
    const { i18n } = useTranslation();
    const { marketLanguages } = usePageContext();
    const marketsMap = mapToMarketsAndLanguages(marketLanguages);

    const getLanguage = (market: string) => {
        const languages = getLanguagesForMarket(i18n, market, marketsMap);
        const browserLanguage = getBrowserLanguage();

        if (languages?.some(({ value }) => value === browserLanguage)) {
            return browserLanguage;
        }

        return "en";
    };

    return { getLanguage };
};

export default useBrowserLanguage;
