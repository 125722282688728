const truncate = (
    text: string,
    limit: number,
    withEllipsis?: boolean,
): string => {
    if (!text || text.length <= limit) return text || "";
    return withEllipsis
        ? text.slice(0, limit - 1).concat("…")
        : text.slice(0, limit);
};

export default truncate;
