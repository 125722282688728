import { RichText } from "@components";
import type {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import React from "react";

type RichTextResetProps = {
    text: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    className?: string;
};

const RichTextReset = ({ text, className }: RichTextResetProps) => (
    <RichText
        className={className}
        options={{
            variant: undefined,
            color: undefined,
            noParagraphWrapper: true,
        }}
        textNode={{ text }}
    />
);

export default RichTextReset;
