import { Typography } from "@2po-dpam/components";
import { isArrayWithContent } from "@utils/arrays";
import classnames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import * as styles from "./style.module.scss";

export type ContentfulImageData = {
    __typename: string;
    id: string;
    title: string;
    caption: string;
    alt: string;
    image: Image;
};

type Dimensions = {
    width?: number;
    height?: number;
};

export type Image = {
    title?: string;
    description?: string;
    gatsbyImageData?: IGatsbyImageData;
    url?: string;
    file?: {
        url?: string;
        details?: {
            image?: Dimensions;
        };
    };
} & Dimensions;

type ImageSource = {
    srcset: string;
    media?: string;
};

type WrapperProps = {
    title?: string;
    caption?: string;
    id?: string;
    children: React.ReactNode;
    className?: string;
    style?: {
        [x: string]: string;
    };
    altImage?: boolean;
    hideText?: boolean;
};

const Wrapper = ({
    children,
    id,
    title,
    caption,
    className,
    style,
    altImage,
    hideText,
}: WrapperProps) => (
    <figure
        className={classnames(styles.figure, className)}
        id={id}
        style={style as React.CSSProperties}
    >
        {children}
        {(title || caption) && !hideText && (
            <figcaption
                className={classnames(altImage && styles.altDescription)}
            >
                {title && (
                    <Typography className={styles.title}>{title}</Typography>
                )}
                {caption && (
                    <Typography className={styles.caption}>
                        {caption}
                    </Typography>
                )}
            </figcaption>
        )}
    </figure>
);

const getDimensions = (image: Image) => {
    let width = image?.width || image?.file?.details?.image?.width || "auto";
    let height = image?.height || image?.file?.details?.image?.height || "auto";
    if (typeof width === "number") width = `${width}px`;
    if (typeof height === "number") height = `${height}px`;
    return {
        "--max-width": width,
        "--max-height": height,
    };
};

type Props = {
    image?: Image;
    src?: string;
    id?: string;
    alt?: string;
    title?: string;
    sources?: Array<ImageSource>;
    caption?: string;
    options?: any;
    className?: string;
    hideText?: boolean;
};

const Image = ({
    src,
    id,
    alt = "",
    image,
    title,
    caption,
    sources,
    options,
    className,
    hideText,
}: Props) => {
    const { t } = useTranslation();

    if (image?.gatsbyImageData) {
        return (
            <Wrapper
                altImage={options?.altImage}
                caption={caption}
                className={classnames(options?.className, className)}
                hideText={hideText}
                id={id}
                title={title}
            >
                {image?.gatsbyImageData ? ( // GatsbyImage doesn't accept undefined for image but the util can return it..
                    <GatsbyImage
                        alt={alt || ""}
                        className={classnames(
                            options?.altImage && styles.imageVariation,
                        )}
                        image={image.gatsbyImageData}
                        imgStyle={{
                            objectFit: options?.objectFit || "cover",
                        }}
                    />
                ) : (
                    <p>{t("missing image")}</p>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper
            caption={caption}
            className={classnames(options?.className, className)}
            hideText={hideText}
            id={id}
            style={image ? getDimensions(image) : undefined}
            title={title}
        >
            <Fragment>
                {isArrayWithContent(sources) &&
                    sources.map((source: ImageSource, idx: number) => (
                        <source key={`source-${idx}`} {...source} />
                    ))}
                <img
                    alt={alt || title || caption}
                    className={styles.image}
                    loading="lazy"
                    src={src || image?.url || image?.file?.url}
                />
            </Fragment>
        </Wrapper>
    );
};

export default Image;

export const query = graphql`
    fragment ImageConstrained on ContentfulContentImage {
        __typename
        id
        title
        caption
        alt
        image {
            description
            title
            gatsbyImageData(
                layout: CONSTRAINED
                outputPixelDensities: 2
                placeholder: NONE
            )
            width
            height
            url
        }
    }
    fragment ImageFullWidth on ContentfulContentImage {
        __typename
        id
        title
        caption
        alt
        image {
            description
            title
            gatsbyImageData(
                layout: FULL_WIDTH
                outputPixelDensities: 2
                placeholder: NONE
            )
            width
            height
            url
        }
    }
    fragment ImageThumbnail on ContentfulContentImage {
        __typename
        id
        title
        caption
        alt
        image {
            description
            title
            gatsbyImageData(
                outputPixelDensities: 2
                placeholder: NONE
                aspectRatio: 1
                width: 80
                height: 80
            )
            width
            height
            url
        }
    }
`;
