import { Tabs as _Tabs } from "@2po-dpam/components";
import { RichText } from "@components";
import { graphql } from "gatsby";
import React from "react";

import * as style from "./style.module.scss";

type Props = {
    data: {
        tabItems: {
            id: string;
            title: { raw: string };
            content: {
                text: { raw: string };
                unorderedListIcon: { icon: string };
            };
            theme: string;
        }[];
    };
};

const Tabs = ({ data }: Props) => {
    const tabItems = data.tabItems.map(item => ({
        id: item.id,
        title: <RichText textNode={item?.title} />,
        content: (
            <RichText
                className={style.tabs}
                options={{
                    listIcon: item?.content?.unorderedListIcon?.icon,
                    removeMargin: true,
                }}
                textNode={item?.content?.text}
            />
        ),
        theme: item.theme,
    }));
    return <_Tabs tabs={tabItems} />;
};

export default Tabs;

export const query = graphql`
    fragment Tabs on ContentfulAssemblyTabs {
        id
        updatedAt
        contentful_id
        __typename
        tabItems {
            id
            title {
                raw
            }
            content {
                text {
                    raw
                }
                unorderedListIcon {
                    icon
                }
            }
            theme
        }
    }
`;
