import { useEffect, useState } from "react";

type windowDimensions = {
    windowWidth: number;
    windowHeight: number;
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState<windowDimensions>({
        windowHeight: 0,
        windowWidth: 0,
    });

    useEffect(() => {
        const getWindowDimensions = () => {
            const { innerWidth: width, innerHeight: height } = window;
            setWindowDimensions({
                windowWidth: width,
                windowHeight: height,
            });
        };

        getWindowDimensions();
        window.addEventListener("resize", getWindowDimensions);
        return () => window.removeEventListener("resize", getWindowDimensions);
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;
