import { Podcast as _Podcast } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

type PodcastData = {
    title: string;
    category: string;
    podcastId: string;
};

type Props = {
    data: PodcastData;
};

const Podcast = ({ data: { title, category, podcastId } }: Props) => {
    const [color, setColor] = useState<string | null>(null);
    useEffect(() => {
        const value = getComputedStyle(document.body)
            .getPropertyValue("--theme-primary")
            .replace("#", "")
            .trim();
        setColor(value);
    }, []);
    return color ? (
        <_Podcast
            color={color}
            description={title}
            id={podcastId}
            key={color}
            podcastId={podcastId}
            title={category}
        />
    ) : (
        <Spinner />
    );
};

export default Podcast;

export const query = graphql`
    fragment Podcast on ContentfulContentPodcast {
        id
        __typename
        contentful_id
        title
        category
        podcastId
    }
`;
