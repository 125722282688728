import { isSafari } from "@utils/dom";
import { useEffect } from "react";

const getAttributeValue = (elem: Element, key: string) =>
    elem?.attributes.getNamedItem(key)?.value;

/* eslint-disable sonarjs/cognitive-complexity */
const useFocusTrap = (
    focusableElementIds: string[],
    containerId: string,
    isVisible: boolean,
) => {
    useEffect(() => {
        if (!document || !isVisible) return;

        document.getElementById(containerId)?.focus();

        const tabHandler = (ev: KeyboardEvent) => {
            // only act on tab press
            if (
                ev.key.toLowerCase() !== "tab" &&
                ev.which !== 9 &&
                ev.keyCode !== 9
            )
                return;

            const container = document.getElementById(containerId);
            const firstElement = document.getElementById(
                focusableElementIds[0],
            ) as any;
            const lastElement = document.getElementById(
                focusableElementIds[focusableElementIds.length - 1],
            ) as any;

            // trying to go past last, go back to first
            if (document.activeElement === lastElement && !ev.shiftKey) {
                firstElement?.focus();
                ev.preventDefault();
                return;
            }
            //trying to go before first, move to last
            if (document.activeElement === firstElement && ev.shiftKey) {
                lastElement?.focus();
                ev.preventDefault();
                return;
            }

            const getNextItem = (tabIndex, shiftKey) => {
                if (typeof tabIndex !== "string")
                    return shiftKey ? lastElement : firstElement;
                const nextIndex = shiftKey
                    ? Number(tabIndex) - 2
                    : Number(tabIndex);
                return document.getElementById(focusableElementIds[nextIndex]);
            };

            if (container?.contains(document.activeElement)) {
                const tabIndex =
                    document.activeElement &&
                    getAttributeValue(document.activeElement, "tabIndex");

                const nextItem = getNextItem(tabIndex, ev.shiftKey);

                if (
                    nextItem &&
                    getAttributeValue(nextItem, "aria-disabled") !== "true"
                ) {
                    nextItem.focus();
                    ev.preventDefault();
                } else {
                    firstElement?.focus();
                    ev.preventDefault();
                }
            }
        };

        const focusHandler = ev => {
            const container = document.getElementById(containerId);
            const targetIsContainer = ev.target?.matches("#" + containerId);
            const targetIsModal = ev.target?.matches(".modal.fade");

            if (!container) {
                document.removeEventListener("focus", focusHandler, true);
                return;
            }

            ev.stopPropagation();

            if (targetIsModal) return;

            if (
                !ev.relatedTarget &&
                !container.contains(ev.target) &&
                !targetIsContainer
            ) {
                document.getElementById(focusableElementIds[0])?.focus();
                return;
            }

            if (
                !container.contains(ev.target) &&
                !targetIsModal &&
                !targetIsContainer
            ) {
                document.getElementById(focusableElementIds[0])?.focus();
            }
        };

        if (isSafari()) {
            document.addEventListener("focus", focusHandler, true);
        }

        document.addEventListener("keydown", tabHandler, true);

        return () => {
            document.removeEventListener("keydown", tabHandler, true);
            document.removeEventListener("focus", focusHandler, true);
        };
        // eslint-disable-next-line
    }, [isVisible]);
};
/* eslint-disable sonarjs/cognitive-complexity */

export default useFocusTrap;
