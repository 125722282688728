import { CTA, MainNavigation } from "@2po-dpam/components";
import {
    ErrorBoundary,
    InvestorTypePopup,
    MarketLanguagePopup,
    RichText,
    SearchBar,
} from "@components";
import CallToAction from "@components/CallToAction";
import { INVESTORS } from "@constants";
import {
    useContextStore,
    useDataLayer,
    useHasScrolled,
    useInvestorLabels,
    useLink,
    usePageContext,
} from "@hooks";
import { Investor, MenuItem } from "@types";
import { getInvestorSpecificContent } from "@utils/investors";
import { Link, graphql, useStaticQuery } from "gatsby";
import isEqual from "lodash.isequal";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "usehooks-ts";

import { useLocation } from "@reach/router";
import classNames from "classnames";
import * as style from "./style.module.scss";

type subMenuItem = {
    text: {
        text: {
            raw: any;
        };
    };
    title: {
        raw: any;
    };
    menuItems: {
        text: string;
        link: {
            pageLink: {
                slug: string;
            };
        };
    }[];
};

type MenuTheme =
    | "Primary"
    | "Primary green"
    | "Sustainable green"
    | "Fundamental green"
    | "Fixed blue"
    | "Multi blue"
    | "Quantitative red";

type Menu = {
    text: string;
    theme: MenuTheme;
    menucolumnsLeft: subMenuItem[];
    menucolumnsRight: subMenuItem[];
    titleMenucolumnsLeft: string;
    titleMenucolumnsRight: string;
};

type Props = {
    language: string;
    investorType: Investor;
    currentPath: string;
    basePath?: string;
    onResultPageSearch?: (q: string) => void;
};

const Navigation = ({
    onResultPageSearch,
    language,
    investorType,
    basePath,
}: Props) => {
    const navigationRef = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const { pushEvent } = useDataLayer();
    const searchbarWrapperRef = useRef(null);
    const [searchOpen, setSearchOpen] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
    const [activeMenuItem, setActiveMenuItem] = useState<
        MenuItem | undefined
    >();
    const { marketLanguages, pages } = usePageContext();
    const { market, investor } = useContextStore();
    const { currentInvestorLabel } = useInvestorLabels();

    const { getInternalDestination, getCTADestination } = useLink();
    const { t } = useTranslation("general");
    const {
        navigations: { nodes: navigations },
    } = useStaticQuery(graphql`
        query HeaderQuery {
            navigations: allContentfulNavigationHeader {
                nodes {
                    ...Navigation
                }
            }
        }
    `);

    const locale = `${language}-${market.toUpperCase()}`;
    const navigation = navigations.find(
        navigation => navigation.node_locale === locale,
    );

    const [inverted] = useHasScrolled();
    const investorTypeMap = {
        [INVESTORS.RETAIL]: "menuRetail",
        [INVESTORS.INTERMEDIARY]: "menuProfessionalIntermediary",
        [INVESTORS.END_INVESTOR]: "menuProfessionalEndInvestor",
    };
    const [showInvestorPopup, toggleInvestorPopup] = useState(false);
    const [showMarketLanguagePopup, toggleMarketLanguagePopup] =
        useState(false);
    const signInButton = navigation.signInButton || {};
    const minorActionItems = [
        {
            label: currentInvestorLabel,
            icon: "user",
            action: () => toggleInvestorPopup(true),
        },
        {
            label: "Language & country",
            icon: "global",
            action: () => toggleMarketLanguagePopup(true),
        },
        {
            label: signInButton.text,
            icon: "login",
            action: () => {
                pushEvent({ name: "clicks-to-sign-in" });
                window.open(signInButton.externalLink, "_blank");
            },
        },
    ];

    const primaryActionItems = navigation.primaryButtons?.length
        ? navigation.primaryButtons.map(button => ({
              label: button.text,
              destination: getCTADestination(button),
          }))
        : [];

    const menu = navigation?.[investorTypeMap[investorType]];
    const logoSource = inverted ? navigation.logoAlternative : navigation.logo;
    const showInvestorButton = useMemo(
        () =>
            investor !== "retail" &&
            !Object.values(investorTypeMap)
                .filter(key => navigation[key])
                .map(investor => navigation[investor])
                .every((menu, i, menus) => isEqual(menu, menus[0])),
        [investor],
    );

    const getPageLinks = contentPages =>
        contentPages.map(page => {
            const { shortTitle } =
                getInvestorSpecificContent(investorType, page) || {};

            return {
                location: `${basePath}${getPageSlug(page.id)}`,
                label: (
                    <RichText
                        options={{ inline: true, noRichStyling: true }}
                        textNode={shortTitle}
                    />
                ),
            };
        });

    const getPageSlug = (id: string) => {
        const page = pages.find(page => page.pageType?.id === id);
        return page?.slug;
    };

    const getLinks = subMenuItem => {
        if (subMenuItem?.automaticListing) {
            const { items } = subMenuItem.automaticListing;
            return getPageLinks(items || []);
        }

        return subMenuItem?.menuItems?.map(menuItem => ({
            label: menuItem.text,
            location: getInternalDestination(menuItem?.link),
        }));
    };

    const getSubmenuItems = (subMenuItems, title) => ({
        title,
        hideOnMobile: subMenuItems?.every(
            subMenuItem => subMenuItem.automaticListing,
        ),
        ...(subMenuItems?.[0]?.text
            ? {
                  text: <RichText textNode={subMenuItems?.[0]?.text} />,
              }
            : {
                  menuColumns: subMenuItems?.map(subMenuItem => ({
                      title: !!subMenuItem.title && (
                          <RichText
                              options={{ noParagraphWrapper: true }}
                              textNode={{ text: subMenuItem.title }}
                          />
                      ),
                      links: getLinks(subMenuItem),
                      cta: subMenuItem?.automaticListing?.cta && (
                          <CallToAction
                              data={{
                                  ...subMenuItem?.automaticListing?.cta,
                                  variation: "Inverted block",
                              }}
                              options={{
                                  className: style.listingBtn,
                              }}
                          />
                      ),
                  })),
              }),
    });

    const menuItems = useMemo(
        () =>
            menu.map((menuItem: Menu) => ({
                label: menuItem.text,
                theme: menuItem.theme,
                subMenuItems: [
                    getSubmenuItems(
                        menuItem.menucolumnsLeft,
                        menuItem.titleMenucolumnsLeft,
                    ),
                    getSubmenuItems(
                        menuItem.menucolumnsRight,
                        menuItem.titleMenucolumnsRight,
                    ),
                ],
            })),
        [menu],
    );

    const reset = () => {
        activeMenuItem && setActiveMenuItem(undefined);
        searchOpen && setSearchOpen(false);
        showMobileMenu && setShowMobileMenu(false);
    };

    useEffect(() => {
        const scrollbarWidth =
            window.innerWidth -
            document.getElementsByTagName("html")[0].clientWidth;
        const bodyClass =
            scrollbarWidth == 0 ? "scrollbar-thin" : "scrollbar-normal";
        navigationRef.current?.setAttribute(
            "data-scrollbar-size",
            scrollbarWidth.toString(),
        );
        document.body.classList.add(bodyClass);
    }, []);

    useEffect(() => reset(), [pathname]);

    const handleClickOutside = () => {
        if (searchOpen) setSearchOpen(false);
    };

    useOnClickOutside(searchbarWrapperRef, handleClickOutside);

    if (!menu) return <></>;

    return (
        <Fragment>
            <div ref={searchbarWrapperRef}>
                <SearchBar
                    isOpen={searchOpen}
                    onClose={() => setSearchOpen(false)}
                    onResultPageSearch={onResultPageSearch}
                    scrolled={inverted}
                />
            </div>
            <div
                className={classNames(style.navigation, {
                    [style.searchOpenScrolled]: searchOpen && inverted,
                })}
                ref={navigationRef}
            >
                <InvestorTypePopup
                    id="MainNavigation-InvestorTypePopup"
                    isOpen={showInvestorPopup}
                    onClose={() => toggleInvestorPopup(false)}
                />
                <MarketLanguagePopup
                    currentLanguage={language}
                    data={marketLanguages}
                    id="MainNavigation-MarketLanguagePopup"
                    isOpen={showMarketLanguagePopup}
                    onClose={() => toggleMarketLanguagePopup(false)}
                />
                <MainNavigation
                    activeMenuItem={activeMenuItem}
                    inverted={inverted}
                    linkComponent={Link}
                    logo={
                        <Link to={basePath || "/"}>
                            <img
                                alt={logoSource.alt}
                                src={logoSource.image?.file?.url}
                            />
                        </Link>
                    }
                    menuItems={menuItems}
                    minorActionItems={minorActionItems}
                    onSearch={() => setSearchOpen(prevValue => !prevValue)}
                    primaryActionItems={primaryActionItems}
                    searchButtonLabel={t("search.open-search-button-label")}
                    setActiveMenuItem={setActiveMenuItem}
                    setShowMobileMenu={setShowMobileMenu}
                    showMobileMenu={showMobileMenu}
                    switchInvestorBtn={
                        showInvestorButton && (
                            <CTA
                                className={style.switchInvestorBtn}
                                onClick={() =>
                                    toggleInvestorPopup(state => !state)
                                }
                                variant="outlined"
                            >
                                {t("switch investor", { context: investor })}
                            </CTA>
                        )
                    }
                />
            </div>
        </Fragment>
    );
};

const WrappedNavigation = (props: Props) => (
    <ErrorBoundary>
        <Navigation {...props} />
    </ErrorBoundary>
);

export default WrappedNavigation;

export const query = graphql`
    fragment AngleLink on ContentfulPageTypeAnglePage {
        id
        node_locale
        generalContent {
            contentful_id
            shortTitle {
                raw
            }
        }
        professionalEndInvestorContent {
            content {
                shortTitle {
                    raw
                }
            }
        }
        professionalIntermediaryContent {
            content {
                shortTitle {
                    raw
                }
            }
        }
    }
    fragment NewsLink on ContentfulPageTypeNewsOrAnnouncementPage {
        id
        node_locale
        generalContent {
            contentful_id
            shortTitle {
                raw
            }
        }
        professionalEndInvestorContent {
            content {
                shortTitle {
                    raw
                }
            }
        }
        professionalIntermediaryContent {
            content {
                shortTitle {
                    raw
                }
            }
        }
    }
    fragment Menu on ContentfulNavigationMenu {
        text
        theme
        menucolumnsLeft {
            text {
                text {
                    raw
                }
            }
            title {
                raw
            }
            menuItems {
                text
                link {
                    ...NavLink
                }
            }
            automaticListing {
                items {
                    ... on ContentfulPageTypeAnglePage {
                        ...AngleLink
                    }
                    ... on ContentfulPageTypeNewsOrAnnouncementPage {
                        ...NewsLink
                    }
                }
                cta {
                    ...CallToAction
                }
            }
        }
        menucolumnsRight {
            text {
                text {
                    raw
                }
            }
            title {
                raw
            }
            menuItems {
                text
                link {
                    ...NavLink
                }
            }
            automaticListing {
                items {
                    ... on ContentfulPageTypeAnglePage {
                        ...AngleLink
                    }
                    ... on ContentfulPageTypeNewsOrAnnouncementPage {
                        ...NewsLink
                    }
                }
                cta {
                    ...CallToAction
                }
            }
        }
        titleMenucolumnsLeft
        titleMenucolumnsRight
    }
    fragment Navigation on ContentfulNavigationHeader {
        node_locale
        id
        logo {
            alt
            image {
                file {
                    url
                }
            }
        }
        logoAlternative {
            alt
            image {
                file {
                    url
                }
            }
        }
        signInButton {
            ...CallToAction
        }
        primaryButtons {
            ...CallToAction
        }
        menuRetail {
            ...Menu
        }
        menuProfessionalIntermediary {
            ...Menu
        }
        menuProfessionalEndInvestor {
            ...Menu
        }
    }
`;
