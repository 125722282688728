import {
    CtaOverlayContent,
    ImageSectionWithOverlay as _ImageSectionWithOverlay,
} from "@2po-dpam/components";
import { Image, RichText } from "@components";
import { ContentfulImageData } from "@components/Image";
import { CONTENT_TYPES } from "@constants";
import { useWindowDimensions } from "@hooks";
import { isArrayWithContent } from "@utils/arrays";
import {
    componentMapper,
    matchesType,
    shortenTypename,
} from "@utils/componentMapper";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import React, { useEffect, useMemo, useRef, useState } from "react";

import * as style from "./style.module.scss";

type OverlayBlock = {
    tile: {
        raw: any;
    };
    number: string;
    id: string;
    __typename: string;
    content: any;
};

type Props = {
    data: {
        id: string;
        __typename: string;
        overlayBlocks: OverlayBlock[];
        theme: string;
        backgroundImage: ContentfulImageData;
        textContent?: {
            text?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
            unorderedListIcon?: { icon: string };
        };
        category: string;
    };
    options?: any;
};

type Margins = {
    numberMargin: string;
    titleMargin: string;
};

const getMaximumSizes = (
    elements: HTMLDivElement[],
    getChildHeight: (HTMLElement, number) => number,
) =>
    elements.reduce(
        (sizes, elem) => {
            const numberHeight = getChildHeight(elem, 0),
                titleHeight = getChildHeight(elem, 1);
            if (numberHeight > sizes.maxNumber) sizes.maxNumber = numberHeight;
            if (titleHeight > sizes.maxTitle) sizes.maxTitle = titleHeight;
            return sizes;
        },
        { maxTitle: 0, maxNumber: 0 },
    );

const calculateMargins = ({
    elements,
    getChildHeight,
    maxNumber,
    maxTitle,
}: any) =>
    elements.map(elem => {
        const numberHeight = getChildHeight(elem, 0),
            titleHeight = getChildHeight(elem, 1);
        const getMargin = (difference: number) =>
            difference > 0 ? `${difference}px` : "0";
        return {
            titleMargin: getMargin(maxTitle - titleHeight),
            numberMargin: getMargin(maxNumber - numberHeight),
        };
    });

/* eslint-disable sonarjs/cognitive-complexity */
const ImageSectionWithOverlay = ({
    data: {
        id,
        theme,
        overlayBlocks,
        backgroundImage,
        __typename,
        category,
        textContent,
    },
}: Props) => {
    const cardRefs = useRef<any>([]);
    const [cardMargins, setCardMargins] = useState<Margins[]>([]);
    const { windowWidth } = useWindowDimensions();
    const allHrInfoCards = useMemo(
        () =>
            isArrayWithContent(overlayBlocks) &&
            overlayBlocks.every(item =>
                matchesType(shortenTypename(item), CONTENT_TYPES.HR_INFO_CARD),
            ),
        [overlayBlocks],
    );

    const isCTAOverlaySection = matchesType(
        shortenTypename({ __typename }),
        CONTENT_TYPES.IMAGE_WITH_CTA_OVERLAY_SECTION,
    );

    useEffect(() => {
        if (
            !document ||
            !allHrInfoCards ||
            !isArrayWithContent(cardRefs.current)
        )
            return;

        const handler = () => {
            let maxTitle = 0,
                maxNumber = 0;
            const getChildHeight = (elem: any, idx: number) =>
                elem?.children?.item(idx)?.clientHeight || 0;

            if (windowWidth > 768) {
                const sizes = getMaximumSizes(cardRefs.current, getChildHeight);
                maxNumber = sizes.maxNumber;
                maxTitle = sizes.maxTitle;
            }

            const newMargins = calculateMargins({
                elements: cardRefs.current,
                getChildHeight,
                maxNumber,
                maxTitle,
            });
            setCardMargins(newMargins);
        };

        document.fonts.ready.then(handler);
    }, [windowWidth, allHrInfoCards]);

    return windowWidth ? (
        <_ImageSectionWithOverlay
            background={theme}
            id={id}
            image={
                backgroundImage && (
                    <Image {...backgroundImage} caption="" title="" />
                )
            }
            variant={isCTAOverlaySection ? "CTA" : "HR"}
        >
            {isArrayWithContent(overlayBlocks) &&
                overlayBlocks.map((item, idx) =>
                    componentMapper(item, {
                        ref: matchesType(
                            shortenTypename(item),
                            CONTENT_TYPES.HR_INFO_CARD,
                        )
                            ? (el: any) => (cardRefs.current[idx] = el)
                            : null,
                        margins: cardMargins[idx],
                    }),
                )}
            {isCTAOverlaySection &&
                textContent &&
                !isEmptyDocument(textContent.text) && (
                    <CtaOverlayContent
                        className={style.ctaOverlay}
                        content={
                            <RichText
                                options={{
                                    headingClassName: style.ctaOverlayRTHeading,
                                }}
                                textNode={textContent as any}
                            />
                        }
                        title={category}
                    />
                )}
        </_ImageSectionWithOverlay>
    ) : (
        <>loading</>
    );
};
/* eslint-enable sonarjs/cognitive-complexity */

export default ImageSectionWithOverlay;

export const query = graphql`
    fragment ImageWithOverlay on ContentfulAssemblyImageSectionWithOverlay {
        id
        __typename
        contentful_id
        theme
        backgroundImage: imageBackground {
            ...ImageFullWidth
        }
        overlayBlocks {
            ...HrInfoCard
        }
    }
    fragment ImageWithCtaOverlay on ContentfulAssemblyImageSectionWithCtaOverlay {
        id
        __typename
        contentful_id
        theme
        category: title
        backgroundImage {
            ...ImageFullWidth
        }
        textContent: content {
            ...RichText
        }
    }
`;
