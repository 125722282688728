import { Carousel as _Carousel } from "@2po-dpam/components";
import { CONTENT_TYPES, INVESTORS } from "@constants";
import { useContextStore, useIsMobile, useWindowDimensions } from "@hooks";
import { componentMapper, matchesType } from "@utils/componentMapper";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";

import * as style from "./style.module.scss";

type CarouselData = {
    amountOfColumns: 3 | 4;
    renderOnMobileAs: "Carousel" | "List";
    entries: any[];
    node_locale: string;
    autoplay: boolean;
    interval: number;
};

type Props = {
    data: CarouselData;
};

// overriding entry typenames that refer to page types to prevent any possible collisions
const mapEntry = (entry: any) => {
    const typename = entry.__typename;
    if (matchesType(typename, CONTENT_TYPES.ANGLE_PAGE)) {
        return {
            ...entry,
            __typename: CONTENT_TYPES.ANGLE_CARD,
        };
    }
    if (matchesType(typename, CONTENT_TYPES.NEWS_PAGE)) {
        return {
            ...entry,
            __typename: CONTENT_TYPES.SIMPLE_NEWS_CARD,
        };
    }
    if (matchesType(typename, CONTENT_TYPES.NEWS_CARD_PREVIEW)) {
        return {
            ...entry,
            __typename: CONTENT_TYPES.SIMPLE_NEWS_CARD_PREVIEW,
        };
    }
    return entry;
};

/* eslint-disable sonarjs/cognitive-complexity */
const Carousel = ({
    data: { amountOfColumns, entries, renderOnMobileAs, autoplay, interval },
}: Props) => {
    const { windowWidth } = useWindowDimensions();
    const { investor } = useContextStore();
    let _entries: any[] = [];
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
    const [columns, setColumns] = useState(amountOfColumns);

    entries.forEach(entry => {
        const { strategy, items } = entry;
        strategy ? _entries.push(...items) : _entries.push(entry);
    });

    const isMobile = useIsMobile();
    const renderAsList = isMobile && renderOnMobileAs === "List";

    const hasContentOfType = (type: string) => {
        if (!_entries?.length) return false;
        return _entries.some(entry => mapEntry(entry).__typename === type);
    };

    const getAlignableContentType = () => {
        if (hasContentOfType(CONTENT_TYPES.SIMPLE_NEWS_CARD)) {
            return "SimpleNewsCard";
        }
        if (hasContentOfType(CONTENT_TYPES.VALUESCARD)) {
            return "ValuesCard";
        }
    };

    if (matchesType(entries?.[0]?.__typename, "ContentfulAssemblyJobCards")) {
        _entries = _entries[0].items?.map(entry => ({
            ...entry,
            __typename: CONTENT_TYPES.JOB_CARDS,
        }));
    }

    const carouselItems = _entries
        ?.filter(
            entry =>
                entry !== null &&
                !(
                    matchesType(entry.__typename, CONTENT_TYPES.ANGLE_PAGE) &&
                    investor === INVESTORS.RETAIL
                ),
        )
        .map((entry, idx) =>
            componentMapper(mapEntry(entry), {
                stretch: true,
                investor,
                renderedInList: renderAsList,
                hideContentMobile: !renderAsList && activeCarouselIndex !== idx,
                altImage: matchesType(
                    entries?.[0]?.__typename,
                    "ContentfulContentImage",
                ),
            }),
        );

    useEffect(() => {
        if (matchesType(entries?.[0]?.__typename, "ContentfulContentImage")) {
            setColumns(windowWidth > 1300 ? 4 : 3);
        }
    }, [amountOfColumns, entries, windowWidth]);

    return renderAsList ? (
        <ul className={style.carouselList}>
            {carouselItems.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    ) : (
        <_Carousel
            alignButtonsOn={getAlignableContentType()}
            autoPlay={autoplay}
            autoPlayTimer={interval ? interval * 1000 : undefined}
            columnsMdUp={columns}
            onActiveIndexChange={setActiveCarouselIndex}
        >
            {carouselItems}
        </_Carousel>
    );
};
/* eslint-enable sonarjs/cognitive-complexity */

export const query = graphql`
    fragment Carousel on ContentfulAssemblyCarousel {
        id
        __typename
        amountOfColumns
        renderOnMobileAs
        autoplay
        interval
        entries {
            ...JobCards
            ...ValuesCard
            ...EmbeddedImage
            ...ImageConstrained
            ... on ContentfulAssemblyAutomaticOrManualQuery {
                strategy
                items {
                    ...AngleCard
                    ...SimpleNewsCard
                }
            }
        }
    }
`;

export default Carousel;
