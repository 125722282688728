import { Quote as _Quote } from "@2po-dpam/components";
import RichText from "@components/RichText";
import { IQuote, QuoteVariation } from "@types";
import { graphql } from "gatsby";
import React from "react";

type Props = {
    data: IQuote;
};

const variationsMapper: { [key in QuoteVariation]: "small" | "big" } = {
    "Small-text": "small",
    "Big-text": "big",
};

const Quote = ({
    data: {
        speaker,
        additionalSpeakerInfo,
        quoteContent,
        quoteVariation = "Big-text",
    },
}: Props) => (
    <_Quote
        additionalAuthorInfo={additionalSpeakerInfo}
        author={speaker}
        content={
            <RichText
                options={{ removeMargin: true, noParagraphWrapper: true }}
                textNode={quoteContent.text}
            />
        }
        variant={variationsMapper[quoteVariation]}
    />
);

export default Quote;

export const query = graphql`
    fragment Quote on ContentfulContentQuote {
        id
        __typename
        contentful_id
        speaker
        additionalSpeakerInfo: description
        quoteVariation
        quoteContent {
            text {
                raw
            }
        }
    }
`;
