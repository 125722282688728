import { type Result } from "@templates/searchResults";
import DOMPurify from "dompurify";
import FlexSearch, { type Index } from "flexsearch";
import { graphql, useStaticQuery } from "gatsby";
import { useRef, useState } from "react";

import useContextStore from "./use-context-store";

type SearchIndexes = {
    allSearchIndex: {
        nodes: {
            index: string;
            store: string;
            locale: string;
            investor: string;
        }[];
    };
};

type Store = Record<string, Result>;

type SearchHelpers = {
    lazyLoadSearch: () => Promise<void>;
    search: (q: string) => Promise<Result[] | undefined>;
    loadSearch: (indexString: string, _store: string) => void;
    searchDone: boolean;
    searchIndexLoaded: boolean;
};

const useSearch = (): SearchHelpers => {
    const [searchIndexLoaded, setSearchIndexLoaded] = useState<boolean>(false);
    const [searchDone, setSearchDone] = useState<boolean>(false);
    const [index, setIndex] = useState<Index<string> | null>(null);
    const store = useRef<Store | null>(null);

    const { language, market, investor } = useContextStore();
    const { allSearchIndex: searchIndexes }: SearchIndexes =
        useStaticQuery(graphql`
            query {
                allSearchIndex {
                    nodes {
                        locale
                        investor
                        store
                        index
                    }
                }
            }
        `);
    const urls = searchIndexes.nodes.find(
        node =>
            node.locale === `${language}-${market.toUpperCase()}` &&
            node.investor === investor,
    );

    const loadSearch = async (indexUrl: string, storeUrl: string) => {
        if (!indexUrl || !storeUrl || index || store.current) return;
        const [storeFile, indexFile] = await Promise.all([
            fetch(`/static/${storeUrl}`).then(x => x.text()),
            fetch(`/static/${indexUrl}`).then(x => x.text()),
        ]);

        const _index: Index<string> = FlexSearch.create();
        _index.import(indexFile);

        setIndex(_index);
        store.current = JSON.parse(storeFile);
        setSearchIndexLoaded(true);
    };

    const lazyLoadSearch = async () => {
        if (index || store.current || !urls) return;
        const { index: indexUrl, store: storeUrl } = urls;

        try {
            loadSearch(indexUrl, storeUrl);
        } catch (error) {
            //eslint-disable-next-line no-console
            console.error(`ERROR FETCHING INDEX | STORE\n${error}`);
        }
    };

    const search = async (q: string) => {
        if (!index || !store.current || !q) return;
        const hits = await index.search(DOMPurify.sanitize(q));
        setSearchDone(true);
        //eslint-disable-next-line
        return hits.map(id => store.current![id]);
    };

    return {
        lazyLoadSearch,
        search,
        loadSearch,
        searchDone,
        searchIndexLoaded,
    };
};

export default useSearch;
