import { ContextStore } from "@utils/context/ContextStore";
import { useContext } from "react";

export type Context = {
    investor: string;
    language: string | undefined;
    pageId: string;
    market: string;
    disabledInvestorTypes?: string[];
    fallbackSlug?: string;
};

const useContextStore = (): Context => useContext(ContextStore);

export default useContextStore;
