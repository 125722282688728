const AVAILABLE_SITE_LANGUAGES_COUNTRIES = [
    "en-be",
    "nl-be",
    "fr-be",
    "fr-fr",
    "en-lu",
    "fr-lu",
];

export const DEGROOF_DOMAIN = "www.degroofpetercam.com";
export const getFixedUrlIfMarketAndLanguageDoesNotExist = (
    link: string,
    language: string,
    market: string,
) => {
    const key = `${language}-${market}`;
    return AVAILABLE_SITE_LANGUAGES_COUNTRIES.includes(key)
        ? link
        : link.replace(`/${key}/`, "/en-be/");
};

export const overrideDashForWhiteSpace = (text: string): string => {
    return text.replace(/-/g, " ");
};

export const deleteMoreThanOneBlankBetweenWords = (text: string): string => {
    return text.replace(/\s+/g, " ");
};
export const overrideSpecialCharacters = (text: string): string => {
    return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[æǽ]/g, "ae")
        .replace(/[œ]/g, "oe")
        .replace(/[ÀÁÂÃÄÅ]/g, "A")
        .replace(/[àáâãäå]/g, "a")
        .replace(/[Ç]/g, "C")
        .replace(/[ç]/g, "c")
        .replace(/[Ð]/g, "D")
        .replace(/[ð]/g, "d")
        .replace(/[ÈÉÊË]/g, "E")
        .replace(/[èéêë]/g, "e")
        .replace(/[ÌÍÎÏ]/g, "I")
        .replace(/[ìíîï]/g, "i")
        .replace(/[Ñ]/g, "N")
        .replace(/[ñ]/g, "n")
        .replace(/[ÒÓÔÕÖØ]/g, "O")
        .replace(/[òóôõöø]/g, "o")
        .replace(/[ÙÚÛÜ]/g, "U")
        .replace(/[ùúûü]/g, "u")
        .replace(/[Ý]/g, "Y")
        .replace(/[ýÿ]/g, "y");
};
