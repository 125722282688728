import RichText from "@components/RichText";
import { graphql } from "gatsby";
import React from "react";

type Props = {
    data: {
        text: {
            raw: string;
        };
        variant: "Large" | "Small";
        id: string;
    };
    options?: any;
};
const Subtitle = ({ data: { text, variant } }: Props) => (
    <RichText
        options={{
            color: "primary",
            variant: variant === "Small" ? "subtitle-small" : "subtitle",
        }}
        textNode={text}
    />
);

export default Subtitle;

export const query = graphql`
    fragment Subtitle on ContentfulContentSubtitle {
        id
        __typename
        contentful_id
        text {
            raw
        }
        variant
    }
`;
