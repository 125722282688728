import { FaqCollection as _FaqCollection, FaqItem } from "@2po-dpam/components";
import RichText from "@components/RichText";
import { isArrayWithContent } from "@utils/arrays";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import React from "react";

type Faq = {
    id: string;
    question: string;
    answer: {
        text?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
        unorderedListIcon?: { icon: string };
    };
};

type Props = {
    data: {
        id: string;
        contentful_id: string;
        __typename: string;
        faqs: Faq[];
    };
};

const FaqCollection = ({ data: { faqs } }: Props) => (
    <_FaqCollection>
        {isArrayWithContent(faqs) &&
            faqs
                .filter(
                    faq =>
                        faq && faq.answer && !isEmptyDocument(faq.answer?.text),
                )
                .map((faq: Faq) => (
                    <FaqItem {...faq} key={faq.id}>
                        <RichText textNode={faq.answer} />
                    </FaqItem>
                ))}
    </_FaqCollection>
);
export default FaqCollection;

export const query = graphql`
    fragment FaqCollection on ContentfulAssemblyFaqCollection {
        id
        contentful_id
        __typename
        faqs {
            id
            question
            answer {
                id
                contentful_id
                __typename
                text {
                    raw
                    references {
                        ... on ContentfulComposePage {
                            id
                            slug
                            contentful_id
                            __typename
                        }
                        ... on ContentfulContentLabelWIcon {
                            id
                            contentful_id
                            __typename
                            icon {
                                icon
                            }
                            text {
                                raw
                            }
                        }
                        ...EmbeddedCTA
                        ...Subtitle
                        ...AngleCard
                        ...NewsCard
                        ...Video
                        ...EmbeddedImage
                        ...ContactPoint
                        ...ServiceCard
                        ...FeaturedCard
                        ...Quote
                    }
                }
                unorderedListIcon {
                    icon
                }
            }
        }
    }
`;
