import { Jobcard as _JobCard } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useContextStore from "@hooks/use-context-store";
import getJobLink from "@utils/jobDetailUrl/jobDetailUrl";
import { getFixedUrlIfMarketAndLanguageDoesNotExist } from "@utils/url";
import iconMapper from "./iconMapper";

export type Data = {
    title: string;
    location: string;
    url: string;
    division: string;
};
export type Props = {
    data: Data;
};

const JobCard = ({ data }: Props) => {
    const { language, market } = useContextStore();
    const [jobUrl, setJobUrl] = useState<string>(data.url);
    useEffect(() => {
        if (data.url) {
            const newLink = getJobLink(data, market, language);

            if (newLink && language) {
                setJobUrl(
                    getFixedUrlIfMarketAndLanguageDoesNotExist(
                        newLink,
                        language,
                        market,
                    ),
                );
            }
        }
    }, [data]);

    const { t } = useTranslation("general");
    return (
        <_JobCard
            actionLabel={t("viewJobDescription")}
            icon={iconMapper(data.division)}
            location={data.location}
            title={data.title}
            url={jobUrl}
        />
    );
};

export default JobCard;

export const query = graphql`
    fragment JobCards on ContentfulAssemblyJobCards {
        __typename
        items {
            location
            title
            url
            division
        }
    }
`;
