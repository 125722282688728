import { kebabCase } from "@utils/kebabCase";
import camelcase from "camelcase";

export type InvestorContent = {
    generalContent: any;
    id?: string;
    professionalEndInvestorContent: any;
    professionalIntermediaryContent: any;
    retailContent: any;
    contentful_id?: string;
};

export const getInvestorSpecificContent = (
    investor: string,
    data?: InvestorContent,
) => {
    const investorKey = investor && `${camelcase(investor)}Content`;
    const investorSpecific = data?.[investorKey];
    return investorSpecific &&
        (investorSpecific.content || investorSpecific.fields?.content)
        ? investorSpecific.content || investorSpecific.fields?.content
        : data?.generalContent;
};

export const disablePageForInvestor = (
    investorTypes: string[],
    investor: string,
) => investorTypes?.map(type => kebabCase(type)).includes(kebabCase(investor));
