import Image, { type ContentfulImageData } from "@components/Image";
import { useIsMobile } from "@hooks";
import camelcase from "camelcase";
import classNames from "classnames";
import { graphql } from "gatsby";
import debounce from "lodash.debounce";
import React, { useEffect, useRef, useState } from "react";

import * as style from "./style.module.scss";

type Props = {
    data: {
        contentful_id: string;
        __typename: string;
        image: ContentfulImageData;
        showImageDescription: boolean;
        showImageTitle: boolean;
        variation: "Boxed" | "Full-width left" | "Full-width right";
    };
    options: any;
};

const EmbeddedImage = ({
    data: { image, showImageDescription, showImageTitle, variation },
    options,
}: Props) => {
    if (!showImageDescription && image) image.caption = "";
    if (!showImageTitle && image) image.title = "";

    const display = variation && camelcase(variation);
    const [offsetStyle, setOffsetStyle] = useState<React.CSSProperties>({});
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();

    const updateOffsetStyle = () => {
        if (!imageContainerRef?.current) return;
        if (isMobile) {
            setOffsetStyle({});
            return;
        }
        if (display === "fullWidthLeft") {
            const { left } = imageContainerRef.current.getBoundingClientRect();
            const currentMarginLeft =
                parseInt(imageContainerRef.current.style?.marginLeft, 10) || 0;
            const offsetLeft = currentMarginLeft - left;

            if (left === 0) return;
            setOffsetStyle({
                marginLeft: offsetLeft,
                marginRight: 0,
                width: "unset",
                maxWidth: "unset",
            });
        }
        if (display === "fullWidthRight") {
            const { left, width } =
                imageContainerRef.current.getBoundingClientRect();
            const currentMarginRight =
                parseInt(imageContainerRef.current.style?.marginRight, 10) || 0;
            const offset = document.body.clientWidth - left - width;
            const offsetRight = currentMarginRight - offset;

            if (offset === 0) return;
            setOffsetStyle({
                marginLeft: 0,
                marginRight: offsetRight,
                width: "unset",
                maxWidth: "unset",
            });
        }
    };

    // We don't add debouncedUpdateOffsetStyle to the dependency array because we know it won't change.
    // The alternative of memoizing a callback was deemed unnecessary.
    useEffect(() => {
        const debouncedUpdateOffsetStyle = debounce(updateOffsetStyle, 150);
        debouncedUpdateOffsetStyle();
        window.addEventListener("resize", debouncedUpdateOffsetStyle);
        return () => {
            window.removeEventListener("resize", debouncedUpdateOffsetStyle);
        };
        //eslint-disable-next-line
    }, [isMobile]);

    return (
        <div
            className={classNames(style.image)}
            ref={imageContainerRef}
            style={offsetStyle}
        >
            <Image {...image} options={options} />
        </div>
    );
};
export default EmbeddedImage;

export const query = graphql`
    fragment EmbeddedImage on ContentfulAssemblyEmbeddedImage {
        contentful_id
        __typename
        image {
            contentful_id
            ...ImageFullWidth
        }
        showImageDescription
        showImageTitle
        variation
    }
`;
