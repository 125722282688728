import { Select, Typography } from "@2po-dpam/components";
import { isArrayWithContent } from "@utils/arrays";
import capitalize from "@utils/capitalize";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import * as style from "./style.module.scss";

type Option = { label: string; value: string };
const getSelectOptions = (elements: HTMLHeadElement[], fromTop: number) =>
    elements.reduce((list: Option[], spanElement) => {
        const section = spanElement;
        if (section && section instanceof HTMLElement) {
            const id = section?.id?.replace(/\W/g, "-");

            const link = document.querySelector(
                `[data-name="#${id}"]`,
            ) as HTMLElement;
            if (
                section.offsetTop <= fromTop &&
                section.offsetTop + section.offsetHeight + 50 > fromTop
            ) {
                link?.classList.add(style.current);
            } else {
                link?.classList.remove(style.current);
            }

            return list.concat({
                value: id,
                label: capitalize(link?.innerText),
            });
        } else return list;
    }, []);

const SideMenu = () => {
    const { t } = useTranslation();
    const [anchors, setAnchors] = useState<HTMLElement[]>([]);
    const [selectOptions, setSelectOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const asideRef = useRef<any>(null);

    useEffect(() => {
        const spans: any = Array.from(document.querySelectorAll("span[id]"));
        const spansWithIdAndH2 = spans.filter(
            span => span.querySelector("h2") !== null,
        );

        //assign id to each span that has a h2 element (the span will be the wrapper)
        spansWithIdAndH2.forEach(span => {
            const h2 = span.querySelector("h2");

            const noSymbols = h2.innerText.replace(/\W/g, " "); // Replace parentheses with blank spaces
            const withOnlyOneSpaceBetweenEachWord = noSymbols
                .split(/\s+/)
                .join(" ");
            const h2NoSpaces = withOnlyOneSpaceBetweenEachWord
                .toLowerCase()
                .trim(); // Remove leading and trailing white spaces
            const spitedHyphens = h2NoSpaces.replace(/\W/g, "-");

            span.id = spitedHyphens;
        });

        const handleScroll = () => {
            const fromTop = window.scrollY;
            const options = getSelectOptions(spansWithIdAndH2, fromTop);
            setSelectOptions(options);
        };

        window.addEventListener("scroll", handleScroll);
        setAnchors(spansWithIdAndH2);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToELement = (id: string, mobile: boolean) => {
        const yOffset = !mobile ? -130 : -300;
        const element = document.getElementById(id);
        if (element && element instanceof HTMLElement) {
            const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;

            window.scrollTo({ top: y, behavior: "smooth" });
        }
    };

    useEffect(() => {
        document.body.classList.add("sidemenu-shown");

        return () => {
            document.body.classList.remove("sidemenu-shown");
        };
    }, []);

    if (isArrayWithContent(anchors))
        return (
            <>
                <aside
                    className={classnames(
                        "d-none",
                        "d-md-block",
                        style.wrapper,
                    )}
                    ref={asideRef}
                >
                    <ul>
                        {anchors.map((anchor, idx) => {
                            const h2 = anchor.querySelector("h2");
                            const id = anchor.id;
                            return (
                                <li key={idx}>
                                    <a
                                        data-name={`#${id}`}
                                        onClick={() =>
                                            scrollToELement(id, false)
                                        }
                                    >
                                        {h2?.innerText ?? ""}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </aside>
                <div className={`d-md-none ${style.select}`}>
                    <Select
                        items={selectOptions}
                        onChange={value => scrollToELement(value, true)}
                    />
                </div>
            </>
        );
    else
        return process.env.NODE_ENV === "production" ? null : (
            <aside className={style.wrapper}>
                <Typography variant="para">
                    <em>{t("no h2")}</em>
                </Typography>
            </aside>
        );
};

export default SideMenu;
