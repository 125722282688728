import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import get from "lodash.get";

export type EmailTemplate = {
    subject?: string;
    body?: {
        body?: string;
    };
};

export const replaceValues = (text, data) =>
    text.replace(/{{([\w\d.[\]]+)}}/gim, (_, p1) => {
        const path = p1.replace("angle.", "").replace("news.", "");
        const value = get(data, path);
        if (value && typeof value === "object" && !!value.raw) {
            return documentToPlainTextString(JSON.parse(value.raw));
        }
        if (value && typeof value === "object" && !!value.json) {
            return documentToPlainTextString(value.json);
        }
        return value;
    });

export const interpolateEmail = (
    template: EmailTemplate | undefined | null,
    page: any,
    preview = false,
) => {
    let subject = template?.subject || "";
    let body = (preview ? template?.body : template?.body?.body) || "";

    subject = replaceValues(subject, page);
    body = replaceValues(body, page);

    return { subject, body };
};
