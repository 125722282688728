import { CTA, Select, Typography } from "@2po-dpam/components";
import { RichText } from "@components";
import { OnboardingPopupProps } from "@types";
import { default as classnames } from "classnames";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import * as style from "./style.module.scss";

const OnboardingPopupFields = ({
    buttonLabel,
    countryLabel,
    currentLanguage,
    disclaimerText,
    formValues,
    handleChangeInvestortype,
    handleChangeLanguage,
    handleChangeMarket,
    handleSetPreferences,
    id = "OnboardPopupFields",
    investorTypeLabel,
    investorTypes,
    languageLabel,
    languages,
    markets,
    progress,
    progressBars,
    touched,
}: OnboardingPopupProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div
                className={classnames(
                    style.fadeBlock,
                    style.visible,
                    style.firstBlock,
                )}
            >
                <label
                    htmlFor={`${id}-select-market-trigger`}
                    id={`${id}-select-market-label`}
                >
                    <Typography
                        className={style.selectLabel}
                        removeMargin
                        variant="para"
                    >
                        {t(countryLabel)}
                    </Typography>
                </label>
                <Select
                    className={style.select}
                    id={`${id}-select-market`}
                    items={markets}
                    labelId={`${id}-select-market-label`}
                    locale={currentLanguage}
                    noBoldStyling
                    onChange={handleChangeMarket}
                    optionClassName={style.option}
                    tabIndex={2}
                    value={formValues.market}
                />
            </div>
            <div
                className={classnames(style.fadeBlock, {
                    [style.visible]: touched.includes("market"),
                })}
            >
                <label
                    htmlFor={`${id}-select-language-trigger`}
                    id={`${id}-select-language-label`}
                >
                    <Typography
                        className={style.selectLabel}
                        removeMargin
                        variant="para"
                    >
                        {languageLabel}
                    </Typography>
                </label>
                <Select
                    className={style.select}
                    disabled={!touched.includes("market")}
                    id={`${id}-select-language`}
                    items={languages}
                    labelId={`${id}-select-language-label`}
                    noBoldStyling
                    onChange={handleChangeLanguage}
                    optionClassName={style.option}
                    tabIndex={3}
                    value={formValues.language}
                />
            </div>
            <div
                className={classnames(style.fadeBlock, {
                    [style.visible]: touched.includes("language"),
                })}
            >
                <label
                    htmlFor={`${id}-select-investortype-trigger`}
                    id={`${id}-select-investortype-label`}
                >
                    <Typography
                        className={style.selectLabel}
                        removeMargin
                        variant="para"
                    >
                        {investorTypeLabel}
                    </Typography>
                </label>
                <Select
                    className={style.select}
                    disabled={!touched.includes("language")}
                    id={`${id}-select-investortype`}
                    items={investorTypes}
                    labelId={`${id}-select-investortype-label`}
                    locale={currentLanguage}
                    noBoldStyling
                    onChange={handleChangeInvestortype}
                    optionClassName={style.option}
                    tabIndex={1}
                    value={formValues.investorType}
                />
            </div>
            <div
                className={classnames(
                    style.fadeBlock,
                    style.lastBlock,
                    style.autoHeight,
                    {
                        [style.visible]: touched.length === 3,
                    },
                )}
            >
                <div className={style.caption}>
                    <RichText textNode={{ text: disclaimerText }} />
                </div>
                <CTA
                    disabled={
                        !formValues.investorType ||
                        !formValues.language ||
                        !formValues.market
                    }
                    fullWidth
                    id={`${id}-btn-submit`}
                    onClick={handleSetPreferences}
                    tabIndex={4}
                    variant="filled"
                >
                    {t(buttonLabel)}
                </CTA>
            </div>
            <div className={style.progress}>
                {progressBars.map((bar, idx) => (
                    <div
                        className={classnames(
                            style.progressBar,
                            bar.activated && style.activated,
                        )}
                        key={`progressbar-${idx + 1}`}
                    >
                        {bar.current && (
                            <div className={style.border}>
                                <Typography
                                    className={style.number}
                                    color="main"
                                    removeMargin
                                    variant="para-small"
                                >
                                    {progress}
                                </Typography>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default OnboardingPopupFields;
