import { Divider, Link, List, Typography } from "@2po-dpam/components";
import { CONTENT_TYPES } from "@constants";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
    BLOCKS,
    Block,
    INLINES,
    Inline,
    MARKS,
    Text,
} from "@contentful/rich-text-types";
import {
    Document,
    IRichText,
    RichTextBodyFontSizeVariation,
    TypographyVariantsUnion,
} from "@types";
import { componentMapper, shortenTypename } from "@utils/componentMapper";
import { isEmptyTextNode } from "@utils/richText";
import { Link as GatsbyLink, graphql } from "gatsby";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
    renderRichText,
} from "gatsby-source-contentful/rich-text";
import React, { Fragment, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import * as style from "./style.module.scss";

export type ColorUnion =
    | "main"
    | "white"
    | "darkGrey"
    | "primary"
    | "midGrey"
    | "sustainableGreen";

export type TextNode = {
    id?: string;
    __typename?: string;
    text: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

export type RichTextField = {
    text: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

export type TextOptions = {
    variant?: TypographyVariantsUnion;
    paraVariant?: TypographyVariantsUnion;
    className?: string;
    color?: ColorUnion;
    paragraph?: boolean;
    removeMargin?: boolean;
    noParagraphWrapper?: boolean;
    noRichStyling?: boolean;
    inline?: boolean;
    underline?: boolean;
    embedClassNames?: {
        callToAction?: string;
        image?: string;
        quote?: string;
        video?: string;
        angleCard?: string;
        newsCard?: string;
        labelWIcon?: string;
        table?: string;
        contactPoint?: string;
    };
    paragraphClassName?: string;
    headingClassName?: string;
    characterLimit?: number;
    maxLines?: number;
    boldCta?: true;
    component?: string | React.ElementType;
    embedded?: boolean;
    listIcon?: string;
    isFirstSection?: boolean;
    bodyTextSize?: RichTextBodyFontSizeVariation;
    family?: "stix" | "roboto";
};

type EmbeddedEntryOptions = TextOptions & {
    inline?: boolean;
};

type Props = Partial<IRichText> & {
    textNode: Document;
    options?: TextOptions;
    className?: string;
};

type NodeContent = Text[];

type Node = Block | Inline;

const hasBoldText = (content: NodeContent) =>
    content?.some(c => c?.marks?.some(mark => mark.type === MARKS.BOLD));

const setFirstWordBold = (node: any) => {
    const content = node.content[0];
    const words = content.value?.split(" ");
    node.content = words.map((word, i) => {
        if (i === 0)
            return {
                ...content,
                value: word,
                marks: [
                    {
                        type: MARKS.BOLD,
                    },
                ],
            };
        else
            return {
                ...content,
                value: ` ${word}`,
            };
    });

    return node;
};

const handleVariant = (
    variant: TypographyVariantsUnion = "para",
    bodyText: RichTextBodyFontSizeVariation = "Normal",
): TypographyVariantsUnion => {
    const paragraphTypes: TypographyVariantsUnion[] = ["para"];

    if (bodyText === "Larger" && (!variant || paragraphTypes.includes(variant)))
        return "para-large";

    return variant;
};

const renderText = (
    node: Node,
    {
        paragraph,
        inline,
        bodyTextSize,
        variant: initVariant,
        ...initialOptions
    }: TextOptions,
    children: any,
) => {
    const variant = handleVariant(initVariant, bodyTextSize);

    const options = { ...initialOptions, variant };

    if (inline || !paragraph)
        return (
            <Typography {...options} component={inline ? "span" : undefined}>
                {children}
            </Typography>
        );

    if (isEmptyTextNode(node)) return null;

    const firstWordShouldBeBold =
        !paragraph && !hasBoldText(node.content as NodeContent);
    if (firstWordShouldBeBold) node = setFirstWordBold(node);

    const Content = () => (
        <>
            {node.content.map((doc, i) => (
                <Fragment key={i}>
                    {documentToReactComponents(
                        doc,
                        textOptions({ paragraph, ...options }),
                    )}
                </Fragment>
            ))}
        </>
    );

    return options.noParagraphWrapper ? (
        <>
            <Content />
        </>
    ) : (
        <Typography {...options}>
            <Content />
        </Typography>
    );
};

const renderLink = ({ data, content }: Node, options?: TextOptions) => (
    <Link
        Component={GatsbyLink}
        destination={data.uri || data.target.slug}
        variant="inline"
    >
        <Typography color={options?.color}>
            {(content[0] as Text).value}
        </Typography>
    </Link>
);

const renderHyperLink = ({ data, content }: Node) => (
    <Link
        Component={GatsbyLink}
        className={style.hyperlink}
        destination={data.uri || data.target.slug}
        variant="inline"
    >
        {(content[0] as Text).value}
    </Link>
);

const renderHR = () => <Divider />;

const renderUnorderedList = (
    node: Node,
    children,
    listIcon,
    nested = false,
) => <List icon={listIcon} items={children} nested={nested} />;

const renderOrderedList = (node: Node, children, nested = false) => (
    <List items={children} nested={nested} type="ol" />
);

const renderContent = (node: Node, options: TextOptions) => (
    <>
        {node.content.map((doc, i) => (
            <Fragment key={i}>
                {documentToReactComponents(doc, textOptions({ ...options }))}
            </Fragment>
        ))}
    </>
);

const renderTable = (node: Node, options: TextOptions) => (
    <table className={classNames(style.table, options?.embedClassNames?.table)}>
        <tbody>{renderContent(node, options)}</tbody>
    </table>
);

const renderTableRow = (node: Node, options: TextOptions) => (
    <tr>{renderContent(node, options)}</tr>
);

const renderTableCell = (node: Node, options: TextOptions, children) => {
    const onlyChildIsParagraph =
        node?.content?.length === 1 && node.content[0].nodeType === "paragraph";
    return onlyChildIsParagraph ? (
        <td>{renderContent(node, { ...options, embedded: true })}</td>
    ) : (
        <td>{renderText(node, { ...options }, children)}</td>
    );
};

const renderListItem = (node: Node, children) => {
    if (!children.length || !children[0]?.props) return null;
    if (children.length > 1) {
        return (
            <>
                {children[0]}
                {React.Children.map(
                    children,
                    (child, idx) =>
                        idx > 0 &&
                        React.cloneElement(child, {
                            ...child.props,
                            nested: true,
                        }),
                )}
            </>
        );
    }

    return React.cloneElement(children[0], {
        ...children[0].props,
        removeMargin: true,
    });
};

const SomethingMissing = ({ text }: any) => {
    const { t } = useTranslation();
    return (
        <span>
            {t(text)}
            <br></br>
        </span>
    );
};

const mapTypenameIfNeeded = (node: Node) => {
    if (!node || !node.data?.target) return;

    if (node.data.target.__typename === CONTENT_TYPES.ANGLE_PAGE) {
        node.data.target.__typename = CONTENT_TYPES.ANGLE_CARD;
    }
};

const getEmbedClassname = (
    node: Node,
    options?: TextOptions,
): string | undefined => {
    if (!node || !node.data?.target) return undefined;
    const typename = shortenTypename(node.data.target);

    switch (typename) {
        case CONTENT_TYPES.ANGLE_PAGE:
            return options?.embedClassNames?.angleCard;
        case CONTENT_TYPES.EMBEDD_CTA:
            return options?.embedClassNames?.callToAction ?? style.embeddedCta;
        case CONTENT_TYPES.SIMPLE_NEWS_CARD_PREVIEW:
            return options?.embedClassNames?.newsCard;
        case CONTENT_TYPES.VIDEO:
            return options?.embedClassNames?.video;
        case CONTENT_TYPES.EMBEDD_IMAGE:
            return options?.embedClassNames?.image;
        case CONTENT_TYPES.LABEL_W_ICON:
            return options?.embedClassNames?.labelWIcon;
        case CONTENT_TYPES.QUOTE:
            return options?.embedClassNames?.quote;
        case CONTENT_TYPES.CONTACT_POINT:
        case CONTENT_TYPES.CONTACT_POINT_PREVIEW:
            return options?.embedClassNames?.contactPoint;
        default:
            return undefined;
    }
};

const renderEmbeddedEntry = (node: Node, options?: EmbeddedEntryOptions) => {
    mapTypenameIfNeeded(node);
    const extraIdForKey = Math.floor(Date.now() * Math.random());
    const className = getEmbedClassname(node, options);
    const rendered = componentMapper(
        node.data?.target,
        {
            ...options,
            className,
            embedded: true,
            showIntroText:
                node?.data?.target?.__typename === CONTENT_TYPES.ANGLE_CARD,
        },
        extraIdForKey, // to make keys unique if same entry is added 2x
    );
    return rendered ? rendered : <SomethingMissing text="missing component" />;
};

const optionsFor = (elementType: string, options?: TextOptions) => {
    if (!options) return options;
    if (elementType === "heading")
        return {
            ...options,
            className: options?.headingClassName || options?.className,
        };
    if (elementType === "paragraph")
        return {
            ...options,
            className: options?.paragraphClassName || options?.className,
            variant: options?.paraVariant || options?.variant,
        };
    return options;
};

const textOptions = (options?: TextOptions) => ({
    renderNode: {
        [BLOCKS.HEADING_1]: (node: Node, children: any) =>
            renderText(node, { ...optionsFor("heading", options) }, children),
        [BLOCKS.HEADING_2]: (node: Node, children: any) =>
            renderText(
                node,
                { variant: "h2", ...optionsFor("heading", options) },
                children,
            ),
        //eslint-disable-next-line sonarjs/no-identical-functions
        [BLOCKS.HEADING_3]: (node: Node, children: any) =>
            renderText(node, { ...optionsFor("heading", options) }, children),
        [BLOCKS.HEADING_4]: (node: Node, children: any) =>
            renderText(
                node,
                { variant: "h4", ...optionsFor("heading", options) },
                children,
            ),
        [BLOCKS.HEADING_5]: (node: Node, children: any) =>
            renderText(
                node,
                { variant: "h5", ...optionsFor("heading", options) },
                children,
            ),
        [BLOCKS.HEADING_6]: (node: Node, children: any) =>
            renderText(
                node,
                { variant: "h6", ...optionsFor("heading", options) },
                children,
            ),
        [BLOCKS.PARAGRAPH]: (node: Node, children: any) =>
            renderText(
                node,
                {
                    ...optionsFor("paragraph", {
                        variant: "para",
                        paragraph: true,
                        ...options,
                    }),
                },
                children,
            ),
        [BLOCKS.UL_LIST]: (node: Node, children) =>
            renderUnorderedList(
                node,
                children,
                options?.listIcon,
                options?.embedded,
            ),
        [BLOCKS.OL_LIST]: (node: Node, children) =>
            renderOrderedList(node, children, options?.embedded),
        [BLOCKS.LIST_ITEM]: (node: Node, children) =>
            renderListItem(node, children),
        [BLOCKS.HR]: renderHR,
        [INLINES.HYPERLINK]: (node: Node) => renderHyperLink(node),
        [INLINES.ENTRY_HYPERLINK]: (node: Node) => renderLink(node, options),
        [BLOCKS.EMBEDDED_ENTRY]: (node: Node) =>
            renderEmbeddedEntry(node, {
                ...options,
                inline: false,
            }),
        [INLINES.EMBEDDED_ENTRY]: (node: Node) =>
            renderEmbeddedEntry(node, {
                ...options,
                inline: true,
            }),
        ["table"]: (node: Node) => renderTable(node, { ...options }),
        ["table-row"]: (node: Node) => renderTableRow(node, { ...options }),
        ["table-cell"]: (node: Node, children) =>
            renderTableCell(node, { ...options }, children),
    },
    renderText: (text: string) =>
        text
            .split("\n")
            .reduce(
                (children: ReactNode[], textSegment, index) => [
                    ...children,
                    index > 0 && <br key={index} />,
                    textSegment,
                ],
                [],
            ),
});

const tryToLimit = (data, characterLimit = 80) => {
    if (!data || !data.text || !(data.text.raw || data.text.json)) return data;
    const parsed = data.text.raw ? JSON.parse(data.text.raw) : data.text.json;
    if (
        parsed.content?.[0]?.nodeType !== "paragraph" ||
        !parsed.content?.[0]?.content?.length
    ) {
        return data;
    }

    let charactersLeft = characterLimit;
    const relevantContent = parsed.content[0].content.reduce(
        (list: any[], node: any) => {
            if (charactersLeft <= 0) return list;
            if (node.value.length <= charactersLeft) {
                charactersLeft -= node.value.length;
                return list.concat(node);
            }
            if (node.value.length > charactersLeft) {
                node.value = node.value.slice(0, charactersLeft).trimEnd();
                // re-trim if we are in the middle of a word
                node.value =
                    node.value.substr(
                        0,
                        Math.min(
                            node.value.length,
                            node.value.lastIndexOf(" "),
                        ),
                    ) + "...";
                charactersLeft = 0;
                return list.concat(node);
            }
            return list;
        },
        [],
    );
    parsed.content = [{ ...parsed.content[0], content: relevantContent }];
    if (data.text.raw) data.text.raw = JSON.stringify(parsed);
    if (data.text.json) data.text.json = parsed;
    return data;
};

const RichText = ({ textNode, options, className }: Props) => {
    textNode = textNode?.raw || textNode?.json ? { text: textNode } : textNode;
    if (options?.characterLimit) {
        textNode = tryToLimit(textNode, options.characterLimit);
    }

    const textOptionProps = {
        ...options,
        listIcon: options?.listIcon ?? textNode?.unorderedListIcon?.icon,
        className:
            className ??
            (options?.isFirstSection ? style.firstSection : undefined),
        bodyTextSize: textNode?.fontSizeBodyText,
    };

    return (
        <span id={textNode?.anchorLink || textNode?.id}>
            {textNode?.text?.json
                ? documentToReactComponents(
                      textNode.text.json,
                      textOptions(textOptionProps),
                  )
                : textNode?.text &&
                  renderRichText(textNode.text, textOptions(textOptionProps))}
        </span>
    );
};

export default RichText;

export const query = graphql`
    fragment RichText on ContentfulContentRichText {
        id
        __typename
        fontSizeBodyText
        anchorLink
        text {
            raw
            #  Important! everything in references MUST have at least
            #         contentful_id
            #         __typename
            references {
                ... on ContentfulComposePage {
                    id
                    slug
                    contentful_id
                    __typename
                }
                ... on ContentfulContentLabelWIcon {
                    id
                    contentful_id
                    __typename
                    icon {
                        icon
                    }
                    text {
                        raw
                    }
                }
                ...EmbeddedCTA
                ...Subtitle
                ...AngleCard
                ...NewsCard
                ...Video
                ...EmbeddedImage
                ...ContactPoint
                ...ServiceCard
                ...FeaturedCard
                ...Quote
                ...FaqCollection
                ...EmbeddedIcon
                ...Podcast
            }
        }
        unorderedListIcon {
            icon
        }
    }
`;
