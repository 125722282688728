export default {
    ANGLE_CARD_PREVIEW: "ContentfulAnglePage",
    ANGLE_CARD: "AngleCard",
    ANGLE_COLLECTION_SECTION: "ContentfulAngleCollectionSection",
    ANGLE_PAGE_CONTENT: "ContentfulPageContentAngle",
    ANGLE_PAGE: "ContentfulPageTypeAnglePage",
    AUTHOR_PREVIEW: "ContentfulAuthor",
    AUTHOR: "ContentfulTopicAuthor",
    AWARD_PREVIEW: "ContentfulAward",
    AWARD: "ContentfulTopicAward",
    BRAND_PREVIEW: "ContentfulBrand",
    BRAND: "ContentfulTopicBrand",
    CALL_TO_ACTION: "ContentfulCta",
    CAROUSEL: "ContentfulCarousel",
    COLUMNS_SECTION: "ContentfulColumnsSection",
    CONTACT_OFFICE_PREVIEW: "ContentfulOffice",
    CONTACT_OFFICE: "ContentfulTopicOffice",
    CONTACT_POINT_PREVIEW: "ContentfulContactPoint",
    CONTACT_POINT: "ContentfulTopicContactPoint",
    CONTENT_PAGE: "ContentfulPageTypeContentPage",
    COOKIE_SECTION: "ContentfulOneTrustCookieSection",
    DOCUMENT_COLUMN: "ContentfulTopicDocumentPolicy",
    DOCUMENT_LIST: "ContentfulDocumentSectionWithSearchFilter",
    EMBEDD_CTA: "ContentfulEmbeddedCta",
    EMBEDD_ICON: "ContentfulEmbeddedIcon",
    EMBEDD_IMAGE: "ContentfulEmbeddedImage",
    EVENT: "ContentfulTopicEvent",
    FAQ_COLLECTION: "ContentfulFaqCollection",
    FEATURED_CARD: "ContentfulFeaturedCard",
    FORM: "ContentfulForm",
    FUNDS_OVERVIEW: "ContentfulFundsOverview",
    HERO_CAROUSEL: "ContentfulHeroCarouselVariation",
    HERO_PREVIEW: "Contentfulhero",
    HERO: "ContentfulHero",
    HIGHLIGHTED_ARTICLE: "ContentfulHighlightedArticleSection",
    HR_INFO_CARD: "ContentfulHrInformation",
    ICON_NUMBER_CARD: "ContentfulNumberedCard",
    IFRAME: "ContentfulIframe",
    IMAGE_WITH_CTA_OVERLAY_SECTION: "ContentfulImageSectionWithCtaOverlay",
    IMAGE_WITH_OVERLAY_SECTION: "ContentfulImageSectionWithOverlay",
    IMAGE: "ContentfulImage",
    JOB_CARDS: "ContentfulJobCards",
    LABEL_W_ICON_PREVIEW: "ContentfulLabelWithIcon",
    LABEL_W_ICON: "ContentfulLabelWIcon",
    LATEST_ARTICLES_SECTION: "ContentfulLatestArticlesSection",
    MAP: "ContentfulMap",
    MEDIA_GRID_SECTION: "ContentfulMediaGridSection",
    NEWS_CARD_PREVIEW: "ContentfulPageContentNews",
    NEWS_CARD: "ContentfulPageContentNewsAndAnnouncements",
    NEWS_COLLECTION_SECTION: "ContentfulNewsCollectionSection",
    NEWS_PAGE: "ContentfulPageTypeNewsOrAnnouncementPage",
    NUMBERCARD: "ContentfulNumberCard",
    OVERLAPPING_SPLIT_SECTION: "ContentfulOverlappingSplitSection",
    PAGE_CONTENT: "ContentfulPageContentContent",
    PODCAST: "ContentfulPodcast",
    QUOTE: "ContentfulQuote",
    REPORT_HIGHLIGHT: "ContentfulHighlightedReport",
    RICHTEXT_PREVIEW: "ContentfulRichtext",
    RICHTEXT: "ContentfulRichText",
    SECTION: "ContentfulSection",
    SERVICECARD: "ContentfulTopicService",
    SIMPLE_NEWS_CARD_PREVIEW: "ContentfulNewsAnnouncementPage",
    SIMPLE_NEWS_CARD: "SimpleNewsCard",
    SPLIT_SECTION: "ContentfulSplitSection",
    SUB_ASSET_CARD: "ContentfulSubAssetClassCard",
    SUBTITLE: "ContentfulSubtitle",
    SUSTAINABILITY_CARD: "ContentfulSustainabilityCard",
    SWITCH_INVESTOR_TYPE_SECTION: "ContentfulSwitchInvestorTypeSection",
    TABS: "ContentfulTabs",
    THUMBNAIL_LINK_PREVIEW: "ContentfulThumbnailLink",
    THUMBNAIL_LINK: "ContentfulNavigationThumbnailWLink",
    TIMELINE_SECTION: "ContentfulTimelineSection",
    VALUESCARD: "ContentfulTopicValue",
    VIDEO: "ContentfulVideo",
    OPTIONAL_SECTION: "ContentfulOptionalSection",
    CARD_ICON: "ContentfulCardIcon",
    CONTENT_WIDGET: "ContentfulWidget",
};
