export const LOCALES = {
    NLBE: "nl-BE",
    NLNL: "nl-NL",
    ENAT: "en-AT",
    ENBE: "en-BE",
    ENFI: "en-FI",
    ENFR: "en-FR",
    ENDE: "en-DE",
    ENIT: "en-IT",
    ENLU: "en-LU",
    ENNL: "en-NL",
    ENES: "en-ES",
    ENSE: "en-SE",
    ENCH: "en-CH",
    ENGB: "en-GB",
    FRBE: "fr-BE",
    FRFR: "fr-FR",
    FRLU: "fr-LU",
    FRCH: "fr-CH",
    DEAT: "de-AT",
    DEDE: "de-DE",
    DECH: "de-CH",
    ITIT: "it-IT",
    ITCH: "it-CH",
    ESES: "es-ES",
};
export const LOCALES_ARR = Object.values(LOCALES);
