import { Modal, Select, Typography } from "@2po-dpam/components";
import { RichText } from "@components";
import {
    useContextStore,
    useCookies,
    useFocusTrap,
    useInvestorLabels,
    usePopupData,
    useRouting,
} from "@hooks";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as style from "./style.module.scss";

const getFocusableElements = (id: string) => [
    `${id}-select-trigger`,
    `${id}-secondaryButton`,
    `${id}-primaryButton`,
];
type Props = {
    isOpen: boolean;
    id?: string;
    onClose: () => void;
    providedOptions?: string[];
};

const InvestorTypePopup = ({
    id = "InvestorPopup",
    onClose,
    isOpen,
}: Props) => {
    const { setCookie } = useCookies();
    const { disclaimerText } = usePopupData();
    const location = useLocation();
    const { t } = useTranslation();
    const { investor, language } = useContextStore();
    const [investorType, setInvestorType] = useState("");
    const { currentMarketLabels } = useInvestorLabels();
    const { getPath } = useRouting();
    useFocusTrap(getFocusableElements(id), id, isOpen);

    const investorTypes = Object.entries(currentMarketLabels).map(
        ([key, value]) => ({
            label: value,
            value: key,
        }),
    );

    useEffect(() => {
        if (!isOpen || !location) return;
        setInvestorType(investor);
    }, [isOpen, location, investor]);

    const handleSetPreferences = () => {
        setCookie("investorType", investorType);
        const newPath = getPath({ investor: investorType });
        onClose();
        if (newPath !== location?.pathname) navigate(newPath);
    };

    return (
        <Modal
            closable
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            primaryButton={{
                label: t("popup confirm") || "Set preferences",
                onClick: handleSetPreferences,
                tabIndex: 2,
            }}
        >
            <Typography color="main" variant="h4">
                <Trans i18nKey="investorType.title">
                    <strong>Investor</strong> type
                </Trans>
            </Typography>
            <label
                className={style.label}
                htmlFor={`${id}-select-trigger`}
                id={`${id}-select-label`}
            >
                <Typography removeMargin variant="para">
                    {t("investorType.label")}
                </Typography>
            </label>
            <Select
                className={style.select}
                id={`${id}-select`}
                items={investorTypes}
                labelId={`${id}-select-label`}
                locale={language}
                noBoldStyling
                onChange={setInvestorType}
                optionClassName={style.option}
                tabIndex={1}
                value={investorType}
            />
            <div className={style.caption}>
                <RichText textNode={{ text: disclaimerText }} />
            </div>
        </Modal>
    );
};

export default InvestorTypePopup;
