import { useEffect, useState } from "react";

const useElementScrollThreshold = (
    top: number,
    elementRef?: React.MutableRefObject<HTMLElement>,
) => {
    const [atOrOverThreshold, setAtOrOverThreshold] = useState(false);
    useEffect(() => {
        if (!elementRef) return;

        const checkElementScroll = () => {
            if (!elementRef.current) return;
            const elementClientRect =
                elementRef.current.getBoundingClientRect();
            setAtOrOverThreshold(elementClientRect.top <= top);
        };

        if (typeof window !== "undefined") {
            window.addEventListener("scroll", checkElementScroll, {
                passive: true,
            });

            return () => {
                window.removeEventListener("scroll", checkElementScroll);
            };
        }
    }, [top, elementRef]);

    return atOrOverThreshold;
};

export default useElementScrollThreshold;
