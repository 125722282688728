import { Section as _Section } from "@2po-dpam/components";
import { CallToAction, RichText } from "@components";
import { ISection } from "@types";
import { componentMapper } from "@utils/componentMapper";
import { kebabCase } from "@utils/kebabCase";
import { isEmptyDocument } from "@utils/richText";
import { graphql } from "gatsby";
import React from "react";

export type ContentAlignment = "Left" | "Center" | "Right";

type SectionData = {
    backLink?: React.ReactNode;
    fullWidth?: boolean;
    introText?: React.ReactElement;
    noPadding?: boolean;
    sidebar?: React.ReactNode;
};

type Props = {
    data: ISection & SectionData;
    isFirstSection?: boolean;
    basePath?: string;
};
const Section = ({
    basePath,
    data: {
        additionalCta,
        anchorLink,
        backLink,
        bottomSpacing = "Medium",
        content,
        contentAlignment,
        description,
        fullWidth,
        id,
        introText,
        noPadding,
        sidebar,
        theme,
        title,
        topSpacing = "Medium",
    },
    isFirstSection,
}: Props) => {
    const titleEl = !isEmptyDocument(title) ? (
        <RichText options={{ variant: "h2" }} textNode={{ text: title }} />
    ) : null;

    const descriptionEl =
        description && !isEmptyDocument(description.text) ? (
            <RichText textNode={description} />
        ) : null;

    return (
        <_Section
            IntroText={introText}
            additionalCta={
                additionalCta && <CallToAction data={additionalCta} />
            }
            anchorLink={kebabCase(anchorLink || id)}
            backLink={backLink}
            background={theme}
            bottomSpacing={bottomSpacing}
            contentAlignment={contentAlignment}
            description={descriptionEl}
            fullWidth={fullWidth}
            id={id}
            noPadding={noPadding}
            sidebar={sidebar}
            title={titleEl}
            topSpacing={topSpacing}
            withBorder={!fullWidth}
        >
            {content?.map((child, index) =>
                componentMapper(child, {
                    insideSection: true,
                    isFirstSection: isFirstSection && index === 0,
                    basePath,
                }),
            )}
        </_Section>
    );
};

export default Section;

export const query = graphql`
    fragment Section on ContentfulAssemblySection {
        __typename
        id
        title {
            raw
        }
        description {
            ...RichText
        }
        theme
        topSpacing
        bottomSpacing
        content {
            ...RichText
            ...Carousel
            ...ImageConstrained
            ...Tabs
            ...FundsOverview
            ...Form
            ...Quote
            ...Iframe
            ... on ContentfulContentWidget {
                __typename
                id
                internalName
                type
            }
            ...FaqCollection
        }
        additionalCta {
            ...CallToAction
        }
        anchorLink
        contentAlignment
    }
`;
