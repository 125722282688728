let loading = false;
let handlers = [];

const load = () => {
    if (loading) {
        return;
    }
    loading = true;
    const script = document.createElement("script");
    const apiKey = process.env.GATSBY_GOOGLEMAPS_API_KEY || "";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&libraries=geometry`;
    script.async = true;
    document.body?.appendChild(script);
    script.addEventListener("load", () => {
        for (const handler of handlers) {
            handler(window.google);
        }
        handlers = [];
        loading = false;
    });
};

// Avoid loading multiple instances of google maps api.
export default callback => {
    //google maps is present => simply execute the callback
    if (window.google) {
        callback(window.google);
        return;
    }
    //google maps not present => store callback in hadlers array and proceed
    //loading the google maps script
    handlers.push(callback);
    load();
};
