export const mapToMarketsAndLanguages = (list: any) => {
    const localesMap = new Map();
    list.forEach(item => {
        const [language, market] = item.fieldValue.toLowerCase().split("-");
        if (!market) return;
        if (localesMap.has(market))
            localesMap.set(market, localesMap.get(market).concat(language));
        else localesMap.set(market, [language]);
    });
    return localesMap;
};

export type SelectItem = { label: string; value: string };
type IntlTypeUnion = "language" | "region" | "script" | "currency";
type LabelMaker = Intl.DisplayNames | { of: (value: string) => string };
const getLabelMaker = (
    language: string,
    type: IntlTypeUnion,
    i18n: any,
): LabelMaker => {
    if (Intl.DisplayNames) return new Intl.DisplayNames([language], { type });
    const lookup = (value: string) =>
        type === "region"
            ? `markets.${value.toUpperCase()}`
            : `languages.${value.toUpperCase()}`;
    const transformer = (value: string) => {
        const key = lookup(value);
        return i18n.exists(key)
            ? i18n.t(key)
            : `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
    };
    return { of: transformer };
};

const getOptionsMapper = (labelMaker: LabelMaker) => (value: string) =>
    ({
        value,
        label: labelMaker.of(value.toUpperCase()),
    } as SelectItem);

export const mapMarketsToSelectItems = (
    i18n: any,
    marketsMap?: Map<string, string[]>,
    currentLanguage?: string,
) => {
    if (!marketsMap || !currentLanguage) return [];
    const optionsMapper = getOptionsMapper(
        getLabelMaker(i18n.language, "region", i18n),
    );
    const list = Array.from<string>(marketsMap.keys()).map(optionsMapper);
    return list || [];
};

export const getLanguagesForMarket = (
    i18n: any,
    market?: string,
    marketsMap?: Map<string, string[]>,
): SelectItem[] => {
    if (!market || !marketsMap) return [];
    const list = marketsMap.get(market);
    if (!list) return [];
    const optionsMapper = (language: string) => ({
        value: language,
        label: getLabelMaker(language, "language", i18n).of(language),
    });

    return list.map(optionsMapper) as SelectItem[];
};
