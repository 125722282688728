import { CompareFunds } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export type Fund = {
    fundName: string;
    category: { name: string };
    normativeScreening: boolean;
    exclusions: boolean;
    sustainableThematicApproach: boolean;
    bestInClass: boolean;
    fundLink: string;
};

type Props = {
    data: {
        id;
        funds: Fund[];
    };
};

const FundsOverview = ({ data: { funds } }: Props) => {
    const { t } = useTranslation("funds");

    const headings = {
        assetClass: (
            <Trans i18nKey="headings.assetClass" t={t}>
                <strong>Asset</strong> class
            </Trans>
        ),
        fundName: (
            <Trans i18nKey="headings.fundName" t={t}>
                <strong>Fund</strong> name
            </Trans>
        ),
        bestInClass: (
            <Trans i18nKey="headings.bestInClass" t={t}>
                <strong>Best</strong> in class
            </Trans>
        ),
        exclusions: (
            <Trans i18nKey="headings.exclusions" t={t}>
                <strong>Exclusions</strong>
            </Trans>
        ),
        normativeScreening: (
            <Trans i18nKey="headings.normativeScreening" t={t}>
                <strong>Normative</strong> screening
            </Trans>
        ),
        sustainableThematicApproach: (
            <Trans i18nKey="headings.sustainableThematicApproach" t={t}>
                <strong>Sustainable</strong> thematic approach
            </Trans>
        ),
    };

    const fieldLabels = {
        fundName: t("fieldLabels.fundName"),
        normativeScreening: t("fieldLabels.normativeScreening"),
        bestInClass: t("fieldLabels.bestInClass"),
        exclusions: t("fieldLabels.exclusions"),
        sustainableThematicApproach: t(
            "fieldLabels.sustainableThematicApproach",
        ),
    };

    return (
        <CompareFunds
            fieldLabels={fieldLabels}
            funds={funds}
            headings={headings}
            selectLabel={t("select-label")}
        />
    );
};

export default FundsOverview;

export const query = graphql`
    fragment FundsOverview on ContentfulAssemblyFundsOverview {
        id
        __typename
        contentful_id
        funds {
            bestInClass
            category {
                name
            }
            exclusions
            fundLink
            fundName
            normativeScreening
            sustainableThematicApproach
        }
    }
`;
