import { NEWS_TYPE_ICONS, NEWS_TYPE_LABELS, NewsType } from "@constants";

export const getNewsTypeLabel = (type: string) =>
    NEWS_TYPE_LABELS[normalizeNewsType(type)];
export const getNewsTypeIcon = (type: string) =>
    NEWS_TYPE_ICONS[normalizeNewsType(type)];

export const mapContentfulNewsType = (types?: NewsType[]) => {
    if (!Array.isArray(types)) return;
    const newsType = types?.filter(type => type.name !== "All");
    return newsType?.[0].values?.[0];
};

const normalizeNewsType = (type: string) =>
    type?.toUpperCase().replace(" ", "_");
