import { BackLink as _BackLink } from "@2po-dpam/components";
import { useRouting } from "@hooks";
import { Link } from "gatsby";
import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import * as style from "./style.module.scss";

export type Props = {
    destinationSlug: string;
    pageType: "angle" | "news";
    className?: string;
};
const BackLink = ({ destinationSlug, pageType, className }: Props) => {
    const { t } = useTranslation();
    const { getPathForSlug } = useRouting();
    const destination = getPathForSlug(destinationSlug);

    return (
        <_BackLink
            Component={Link}
            className={classNames(style.backLink, className)}
            destination={destination}
            text={t(pageType === "angle" ? "back_to_all" : "back_to_news")}
        />
    );
};

export default BackLink;
