import { BrandCard as _BrandCard } from "@2po-dpam/components";
import { Image } from "@components";
import { ContentfulImageData } from "@components/Image";
import { graphql } from "gatsby";
import React from "react";

type AwardData = {
    name: string;
    logo: ContentfulImageData;
    awardLink?: string;
};

type Props = {
    data: AwardData;
};

const Award = ({ data: { name, awardLink, logo } }: Props) => {
    const Logo = (
        <Image {...logo} alt={logo?.alt || "Award image"} caption="" title="" />
    );
    return <_BrandCard link={awardLink} logo={Logo} name={name} />;
};

export default Award;

export const query = graphql`
    fragment Award on ContentfulTopicAward {
        id
        __typename
        contentful_id
        name
        # Alias because of conflict with other field
        awardLink: link
        logo {
            ...ImageConstrained
        }
    }
`;
