// extracted by mini-css-extract-plugin
export var active = "style-module--active--df6ad";
export var activeLegendItem = "style-module--active-legend-item--7508f";
export var countrySelection = "style-module--country-selection--4f8df";
export var filterHelptext = "style-module--filter-helptext--6718b";
export var flagContainer = "style-module--flag-container--063b5";
export var graphLegend = "style-module--graph-legend--7a3b0";
export var graphWrapper = "style-module--graph-wrapper--70b19";
export var iconWrapper = "style-module--icon-wrapper--46ae2";
export var inactive = "style-module--inactive--a2cee";
export var legendBackground = "style-module--legend-background--3e548";
export var legendItem = "style-module--legend-item--53539";
export var name = "style-module--name--b9a64";
export var select = "style-module--select--b0fbe";