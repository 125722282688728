import { Data } from "@components/JobCard";
import {
    DEGROOF_DOMAIN,
    deleteMoreThanOneBlankBetweenWords,
    overrideDashForWhiteSpace,
    overrideSpecialCharacters,
} from "@utils/url";

const getJobLink = (data: Data, market: string, language?: string) => {
    const url = new URL(data.url);
    const jobId = url.searchParams.get("jobId");
    //delete french special characaters
    const titleNormalized = overrideSpecialCharacters(data.title);
    //delete title dashes and add dashes for blank spaces
    const titleWithNoDashes = overrideDashForWhiteSpace(titleNormalized);
    //delete more than one blank space between words
    const titleMaxOneSpaceBetweenWords =
        deleteMoreThanOneBlankBetweenWords(titleWithNoDashes);

    const titleSpitedByDashes = titleMaxOneSpaceBetweenWords
        .replace(/ /g, "-")
        .toLowerCase();

    return `${url.protocol}//${DEGROOF_DOMAIN}/${language}-${market}/careers/${jobId}-${titleSpitedByDashes}`;
};

export default getJobLink;
