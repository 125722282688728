/**
 * Configuration of i18next
 *
 * TODO: Look for optimisations here
 */

// Importing the sources manually for now. Find a more elegant pattern, potentially with the use of a custom
// webpack loader (does not need to be in gatsby).
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// DE
import contactDe from "./locales/de/contact.json";
import fundsDe from "./locales/de/funds.json";
import generalDe from "./locales/de/general.json";
import pageHomeDe from "./locales/de/pageHome.json";

// EN
import contactEn from "./locales/en/contact.json";
import fundsEn from "./locales/en/funds.json";
import generalEn from "./locales/en/general.json";
import marketsLanguagesEn from "./locales/en/marketsLanguages.json";
import pageHomeEn from "./locales/en/pageHome.json";

// ES
import contactEs from "./locales/es/contact.json";
import fundsEs from "./locales/es/funds.json";
import generalEs from "./locales/es/general.json";
import pageHomeEs from "./locales/es/pageHome.json";

// FR
import contactFr from "./locales/fr/contact.json";
import fundsFr from "./locales/fr/funds.json";
import generalFr from "./locales/fr/general.json";
import pageHomeFr from "./locales/fr/pageHome.json";

// IT
import contactIt from "./locales/it/contact.json";
import fundsIt from "./locales/it/funds.json";
import generalIt from "./locales/it/general.json";
import pageHomeIt from "./locales/it/pageHome.json";

// NL
import contactNl from "./locales/nl/contact.json";
import fundsNl from "./locales/nl/funds.json";
import generalNl from "./locales/nl/general.json";
import pageHomeNl from "./locales/nl/pageHome.json";

const resources = {
    de: {
        contact: contactDe,
        funds: fundsDe,
        general: generalDe,
        pageHome: pageHomeDe,
    },
    en: {
        contact: contactEn,
        funds: fundsEn,
        general: generalEn,
        marketsLanguages: marketsLanguagesEn,
        pageHome: pageHomeEn,
    },
    es: {
        contact: contactEs,
        funds: fundsEs,
        general: generalEs,
        pageHome: pageHomeEs,
    },
    fr: {
        contact: contactFr,
        funds: fundsFr,
        general: generalFr,
        pageHome: pageHomeFr,
    },
    it: {
        contact: contactIt,
        funds: fundsIt,
        general: generalIt,
        pageHome: pageHomeIt,
    },
    nl: {
        contact: contactNl,
        funds: fundsNl,
        general: generalNl,
        pageHome: pageHomeNl,
    },
};

// Initialize the main i18n instance
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en",
        supportedLngs: ["en", "fr", "nl", "de", "es", "it"],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        nsSeparator: ":",
        keySeparator: ".",
        defaultNS: "general", // Seems most logical. Was translation in previous implementation without any effect
        fallbackNS: ["contact", "funds", "marketsLanguages", "pageHome"], // This is like implemented in the previous
        // plugin but seems extremely unpredictable
        react: {
            useSuspense: false, // Taken over from other plugin, find out what this does
        },
        lng: "en", // Never detect
    });

export default i18n;
