import {
    Button,
    ListViewToggle,
    Select,
    Section as _Section,
} from "@2po-dpam/components";
import { useCookies } from "@hooks";
import { isArrayWithContent } from "@utils/arrays";
import { getInvestorSpecificContent } from "@utils/investors";
import { deleteRepeatedItemsByContentfulId, sortPages } from "@utils/pages";
import classNames from "classnames";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button as RsButton } from "reactstrap";
import useContextStore from "../../hooks/use-context-store";
import type { GeneralContent } from "../AngleCard";
import AngleCard from "../AngleCard";
import * as style from "./style.module.scss";

type AngleCard = {
    theme?: string; //only used for preview
    id: string;
    generalContent: GeneralContent;
    professionalIntermediaryContent: {
        content: GeneralContent;
    };
    retailContent: {
        content: GeneralContent;
    };
    professionalEndInvestorContent: {
        content: GeneralContent;
    };
};

type AngleCategory = {
    id: string;
    mainCategory: string;
    subCategory: string;
    page_content__angle: {
        page_type__angle_page: AngleCard[];
    }[];
};

type Props = {
    data: {
        hideFilters: boolean;
        angleCategories: AngleCategory[];
    };
};

const AngleCollectionSection = ({ data }: Props) => {
    const { cookies, setCookie } = useCookies(["viewType"]);
    const cookieValue = cookies["viewType"] || "card";
    const refs = useRef<(HTMLSpanElement | null)[]>([]);
    const { t } = useTranslation("general");
    const { investor } = useContextStore();
    const [angleCards, setAngleCards] = useState<AngleCard[] | undefined>([]);
    const [totalCardsToShow, setTotalCardsToShow] = useState<number>(6);
    const categories = data.angleCategories.filter(cat =>
        isArrayWithContent(cat.page_content__angle),
    );

    const categoryOptions = categories.map(category => ({
        value: category.id,
        label: category.subCategory,
    }));
    //if there are not filters (filters hidden) then we display all the categories otherwise by default only CIO view (first in the array)
    const [activatedCategories, setActivatedCategories] = useState<
        AngleCategory[] | undefined
    >(data.hideFilters ? categories : [categories[0]]);
    const lastActiveCategory = useRef<AngleCategory | undefined>(undefined);

    const getAnglePage = category =>
        category?.page_content__angle?.map(card => ({
            ...card.page_type__angle_page?.[0],
        }));

    useEffect(() => {
        if (!activatedCategories) return;
        const filteredCardsNew = activatedCategories
            .map(category => getAnglePage(category))
            .flat();

        const uniqueItems = deleteRepeatedItemsByContentfulId(filteredCardsNew);
        const sortedAndUniqueAnglePages = sortPages(uniqueItems, investor);

        setAngleCards(sortedAndUniqueAnglePages);
        setTotalCardsToShow(6);
    }, [activatedCategories, investor]);

    useEffect(() => {
        if (!isArrayWithContent(refs.current)) return;
        if (
            activatedCategories &&
            !!lastActiveCategory.current &&
            lastActiveCategory.current !== activatedCategories[0]
        ) {
            refs?.current?.[totalCardsToShow - 6]?.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
        if (isArrayWithContent(refs.current) && activatedCategories) {
            lastActiveCategory.current = activatedCategories[0];
        }
    }, [refs.current.length, totalCardsToShow, activatedCategories]);

    const loadMore = () => {
        setTotalCardsToShow(totalCardsToShow + 6);
    };

    return (
        <>
            {isArrayWithContent(categoryOptions) &&
                isArrayWithContent(angleCards) &&
                activatedCategories && (
                    <_Section className={style.styledSection} withBorder>
                        <div className={style.sectionHeader}>
                            {!data.hideFilters && (
                                <div className={style.selectWrapper}>
                                    <Select
                                        className={style.select}
                                        items={categoryOptions}
                                        onChange={id =>
                                            setActivatedCategories([
                                                data.angleCategories.find(
                                                    item => item.id === id,
                                                ) || data.angleCategories[0],
                                            ])
                                        }
                                        value={activatedCategories[0].id}
                                    />
                                    <div className={style.categoriesHeader}>
                                        <div className={style.categories}>
                                            <ul>
                                                {data.angleCategories
                                                    .filter(
                                                        category =>
                                                            getAnglePage(
                                                                category,
                                                            )?.length,
                                                    )
                                                    .map(category => (
                                                        <li
                                                            className={classNames(
                                                                style.listItem,
                                                                {
                                                                    [style.activeCategory]:
                                                                        category.id ===
                                                                        activatedCategories[0]
                                                                            .id,
                                                                },
                                                            )}
                                                            key={category.id}
                                                        >
                                                            <RsButton
                                                                color="link"
                                                                onClick={() =>
                                                                    setActivatedCategories(
                                                                        [
                                                                            category,
                                                                        ],
                                                                    )
                                                                }
                                                                title={
                                                                    category.subCategory
                                                                }
                                                            >
                                                                {
                                                                    category.subCategory
                                                                }
                                                            </RsButton>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ListViewToggle
                                active={cookieValue}
                                className={style.switchContainer}
                                onClick={(value: string) =>
                                    setCookie("viewType", value)
                                }
                            />
                        </div>

                        <div
                            className={classNames(style.angleGrid, {
                                [style.listView]: cookieValue === "list",
                            })}
                        >
                            {angleCards?.map((angleContent: any, index) => (
                                <>
                                    {index < totalCardsToShow &&
                                        getInvestorSpecificContent(
                                            investor,
                                            angleContent,
                                        ) && (
                                            <div
                                                className={style.cardWrapper}
                                                key={angleContent?.id}
                                            >
                                                <AngleCard
                                                    cardView={
                                                        cookieValue === "card"
                                                    }
                                                    data={angleContent}
                                                    options={
                                                        cookieValue && {
                                                            className:
                                                                style[
                                                                    cookieValue
                                                                ],
                                                        }
                                                    }
                                                    showIntroText={true}
                                                />
                                                <span
                                                    ref={(
                                                        element: HTMLSpanElement | null,
                                                    ) => {
                                                        refs.current[index] =
                                                            element;
                                                    }}
                                                />
                                            </div>
                                        )}
                                </>
                            ))}
                        </div>

                        {totalCardsToShow < angleCards?.length && (
                            <div className={style.loadMore}>
                                <Button onClick={loadMore} variant="outlined">
                                    {t("loadMore")}
                                </Button>
                            </div>
                        )}
                    </_Section>
                )}
        </>
    );
};

export default AngleCollectionSection;

export const query = graphql`
    fragment AngleCollectionSection on ContentfulAssemblyAngleCollectionSection {
        __typename
        hideFilters
        angleCategories {
            id
            mainCategory
            subCategory
            page_content__angle {
                page_type__angle_page {
                    ...AngleCard
                }
            }
        }
    }
`;
