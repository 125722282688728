import { Icon } from "@2po-dpam/components";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";

import * as style from "./style.module.scss";

type EmbeddedIconSize = "Small" | "Large";

type EmbeddedIconData = {
    size: EmbeddedIconSize;
    icon: {
        icon: string;
    };
};

type Props = {
    data: EmbeddedIconData;
    inline?: boolean;
};

const sizeMap: { [key in EmbeddedIconSize]: number } = {
    Small: 15,
    Large: 30,
};

const EmbeddedIcon = ({ data: { icon, size }, inline }: Props) => {
    const Component = inline ? "span" : "div";
    return (
        <Component
            className={classNames(style.embeddedIcon, inline && style.inline)}
        >
            <Icon name={icon.icon} size={sizeMap[size]} />
        </Component>
    );
};

export const query = graphql`
    fragment EmbeddedIcon on ContentfulAssemblyEmbeddedIcon {
        id
        contentful_id
        __typename
        size
        icon {
            icon
        }
    }
`;

export default EmbeddedIcon;
