import { Author as _Author } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React from "react";

import Image, { type ContentfulImageData } from "../Image";

type Props = {
    data: {
        firstName: string;
        lastName: string;
        description?: string;
        thumbnail?: ContentfulImageData;
    };
};

const Author = ({ data }: Props) => {
    const { thumbnail } = data;
    if (thumbnail?.image) {
        thumbnail.image.title = undefined;
        thumbnail.image.description = undefined;
    }
    return (
        <_Author
            {...data}
            image={
                thumbnail && (
                    <Image
                        alt={thumbnail?.alt}
                        image={thumbnail?.image}
                        src={
                            thumbnail?.image?.url || thumbnail?.image?.file?.url
                        }
                    />
                )
            }
        />
    );
};

export default Author;

export const query = graphql`
    fragment Author on ContentfulTopicAuthor {
        id
        __typename
        firstName
        lastName
        description
        thumbnail {
            ...ImageConstrained
        }
    }
`;
