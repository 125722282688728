import { IIframe } from "@types";
import { graphql } from "gatsby";
import React from "react";

const Iframe = ({
    data: { url, width, height, loadingPolicy, sandbox, reffererPolicy },
}: {
    data: IIframe;
}) => (
    <iframe
        height={height || 700}
        loading={loadingPolicy}
        referrerPolicy={reffererPolicy}
        sandbox={sandbox?.join(" ")}
        src={url}
        width={width || "100%"}
    />
);

export default Iframe;

export const query = graphql`
    fragment Iframe on ContentfulContentIframe {
        id
        __typename
        contentful_id
        url
        width
        height
        loadingPolicy
        sandbox
        reffererPolicy
    }
`;
