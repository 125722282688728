import { Investor } from "@types";
import { disablePageForInvestor } from "@utils/investors";
import { INVESTORS_ARR, INVESTOR_MAP } from "../constants/investors";
import useContextStore from "./use-context-store";
import usePageContext from "./use-page-context";

const useAvailablePageLocales = () => {
    const { pages } = usePageContext();
    const { pageId } = useContextStore();
    const availablePages = pages.filter(page => page.contentful_id === pageId);
    const currentPage = availablePages[0];
    const investors: Investor[] = [];
    const slugs: Record<string, string> = availablePages.reduce((map, page) => {
        map[page.node_locale.toLowerCase()] = page.slug;
        return map;
    }, {});

    if (currentPage?.pageType?.generalContent) investors.push(...INVESTORS_ARR);
    else
        for (const [contentKey, investor] of Object.entries(INVESTOR_MAP)) {
            const disablePageForInvestorType = disablePageForInvestor(
                currentPage?.disablePageForInvestorType,
                investor,
            );

            if (disablePageForInvestorType) continue;
            if (currentPage?.pageType[contentKey]) investors.push(investor);
        }

    return {
        investors,
        slugs,
    };
};

export default useAvailablePageLocales;
