/* eslint-disable react/display-name */
import { AlignmentContext, ThemeContext } from "@2po-dpam/components";
import { CallToAction, RichText } from "@components";
import { type MaxValues } from "@components/ColumnsSection";
import Image, { ContentfulImageData } from "@components/Image";
import { useIsMobile } from "@hooks";
import classNames from "classnames";
import { graphql } from "gatsby";
import React, { forwardRef, useContext, useEffect, useState } from "react";

import * as style from "./style.module.scss";

type Ref = React.Ref<HTMLDivElement> | null;

type Props = {
    data: {
        id: string;
        contentful_id: string;
        icon: ContentfulImageData;
        topic: { text: { raw: string } };
        content: {
            text: { raw: string };
            unorderedListIcon?: { icon: string };
        };
        cta?: any;
    };
    cardMeasurements: MaxValues;
    shadowDom?: boolean;
};

const px2rem = (n: number) => n / 16;

const SustainabilityCard = forwardRef(
    (
        {
            data: { icon, topic, content, cta },
            cardMeasurements,
            shadowDom,
        }: Props,
        ref: Ref,
    ) => {
        const [cardH, setCardH] = useState(0);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, setShowBottomDivider] = useState(false);
        const [titleWrapperH, setTitleWrapperH] = useState(0);
        const isMobile = useIsMobile();
        const backgroundColor: string = useContext(ThemeContext);
        const cardTheme = ["white", "gray"].includes(backgroundColor)
            ? "main"
            : "white";
        const alignment = useContext(AlignmentContext);

        useEffect(() => {
            let _titleWrapperH = cardMeasurements?.maxHeightTitleWrapper || 0;
            let _height = cardMeasurements?.maxHeightCards || 0;

            if (isMobile || shadowDom) {
                _titleWrapperH = 0;
                _height = 0;
            }

            setCardH(_height);
            setTitleWrapperH(_titleWrapperH);
        }, [isMobile, cardMeasurements, shadowDom]);

        useEffect(() => {
            setShowBottomDivider(cardH > 0);
        }, [cardH]);

        return (
            <div
                className={classNames(
                    style.sustainabilityCard,
                    style[cardTheme],
                    alignment && style[`align${alignment}`],
                )}
                ref={ref}
                style={{ height: cardH > 0 ? `${px2rem(cardH)}rem` : "auto" }}
            >
                <div
                    className="titleWrapper"
                    style={{
                        height:
                            titleWrapperH > 0
                                ? `${px2rem(titleWrapperH)}rem`
                                : "auto",
                    }}
                >
                    <div className={style.image}>
                        <Image {...icon} hideText={true} />
                    </div>
                    <RichText
                        className={style.title}
                        options={{
                            variant: "h4",
                            removeMargin: true,
                            color: cardTheme,
                        }}
                        textNode={topic}
                    />
                </div>
                <div className={style.content}>
                    <RichText
                        options={{
                            embedClassNames: {
                                callToAction: style.embeddedCta,
                            },
                        }}
                        textNode={content}
                    />
                </div>
                {cta && (
                    <CallToAction
                        data={cta}
                        options={{
                            noUnderline: true,
                            className: style.cta,
                            type: "text",
                        }}
                    />
                )}
            </div>
        );
    },
);

export default SustainabilityCard;

export const query = graphql`
    fragment SustainabilityCard on ContentfulAssemblySustainabilityCard {
        id
        contentful_id
        __typename
        icon {
            ...ImageConstrained
        }
        content {
            ...RichText
        }
        cta {
            ...CallToAction
        }
        topic: title {
            raw
        }
    }
`;
