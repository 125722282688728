import { CTA, Icon, Typography } from "@2po-dpam/components";
import { RichText } from "@components";
import { useContextStore } from "@hooks";
import { IEvent } from "@types";
import { getDateTimeFormatter } from "@utils/INTL";
import camelcase from "camelcase";
import { graphql } from "gatsby";
import React from "react";
import * as style from "./style.module.scss";

export type Props = {
    data: IEvent;
};

const EventCard = ({ data }: Props) => {
    const { language } = useContextStore();
    const { date, location, title, description, ctaLabel, eventUrl, theme } =
        data;
    const dateFormatter = getDateTimeFormatter(language, "shortDate");
    const formattedDate = date && dateFormatter(date);

    const Heading = () => {
        const headingProps = {
            className: style.heading,
            removeMargin: true,
            variant: "h6",
        };

        if (!!formattedDate && !!location)
            return (
                <Typography {...headingProps}>
                    <Icon name="mapPin" size={12} />
                    {`${formattedDate}, ${location}`}
                </Typography>
            );

        return (
            <Typography {...headingProps}>
                <Icon name="mapPin" />
                {formattedDate ?? location}
            </Typography>
        );
    };

    return (
        <div className={style.cardWrapper}>
            <div className={style.content}>
                <Heading />
                {title && (
                    <RichText
                        options={{ variant: "h4", removeMargin: true }}
                        textNode={title}
                    />
                )}
                {description && (
                    <RichText
                        options={{ variant: "para", removeMargin: true }}
                        textNode={description}
                    />
                )}
            </div>
            <CTA
                color={theme && camelcase(theme)}
                href={eventUrl}
                iconName="chevron"
                iconPosition="end"
                variant="inverted"
            >
                {ctaLabel}
            </CTA>
        </div>
    );
};

export default EventCard;

export const query = graphql`
    fragment Event on ContentfulTopicEvent {
        id
        __typename
        contentful_id
        date
        location
        title {
            raw
        }
        description {
            raw
        }
        ctaLabel
        eventUrl
        theme
    }
`;
