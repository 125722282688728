import { Button, Section } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React, { useEffect } from "react";

import * as style from "./style.module.scss";

type Props = {
    data: {
        prefrencesLabel: string;
    };
};

declare global {
    interface Window {
        OneTrust: any;
    }
}

const CookieSection = ({ data: { prefrencesLabel } }: Props) => {
    useEffect(() => {
        try {
            window?.OneTrust?.initializeCookiePolicyHtml();
        } catch {
            /*
            when initializeCookiePolicyHtml fails it means the cookie page was visited directly,
            so the the cookieList will be filled in on script initialization.
            */
        }
    }, []);
    return (
        <Section>
            <Button
                onClick={() => window.OneTrust.ToggleInfoDisplay()}
                variant="outlined"
            >
                {prefrencesLabel}
            </Button>
            <div className={style.cookiePolicy} id="ot-sdk-cookie-policy" />
        </Section>
    );
};

export default CookieSection;

export const query = graphql`
    fragment CookieSection on ContentfulAssemblyOneTrustCookieSection {
        id
        __typename
        prefrencesLabel
    }
`;
