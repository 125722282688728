import {
    Section as _Section,
    SplitSection as _SplitSection,
} from "@2po-dpam/components";
import { CallToAction, RichText } from "@components";
import { ContactPointData } from "@components/ContactPoint";
import { ContentfulImageData } from "@components/Image";
import { ISplitSection } from "@types";
import { componentMapper } from "@utils/componentMapper";
import { kebabCase } from "@utils/kebabCase";
import { isEmptyDocument } from "@utils/richText";
import classnames from "classnames";
import { graphql } from "gatsby";
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import React from "react";
import * as style from "./style.module.scss";

type SplitSectionContentOptions =
    | (RenderRichTextData<ContentfulRichTextGatsbyReference> & {
          __typename: string;
      })
    | ContentfulImageData
    | ContentfulImageData
    | ContactPointData;

type SplitSectionData = {
    leftContent: SplitSectionContentOptions[];
    rightContent: SplitSectionContentOptions[];
};

type Props = {
    data: ISplitSection & SplitSectionData;
};

const SplitSection = ({
    data: {
        additionalCta,
        anchorLink,
        bottomSpacing,
        description,
        distribution = "50-50",
        hasDivider,
        highlightedSubsectionLeft,
        id,
        leftContent,
        rightContent,
        theme,
        title,
        topSpacing,
        variation,
    },
}: Props) => {
    const Title = !isEmptyDocument(title) ? (
        <RichText options={{ variant: "h2" }} textNode={{ text: title }} />
    ) : null;
    const Description =
        description && !isEmptyDocument(description.text) ? (
            <RichText textNode={description} />
        ) : null;

    const containsMap = [...(leftContent || []), ...(rightContent || [])].some(
        content => content?.__typename === "ContentfulContentMap",
    );

    const [LeftContent, RightContent] = [leftContent, rightContent].map(
        (content, i) => (
            <div
                className={classnames(style.contentBlock, {
                    [style.padding]:
                        containsMap &&
                        content[0].__typename !== "ContentfulContentMap",
                })}
                key={i}
            >
                {content?.map(child =>
                    componentMapper(child, {
                        embedClassNames: {
                            table: style.table,
                            contactPoint: style.contactPoint,
                        },
                    }),
                )}
            </div>
        ),
    );
    const Distribution = distribution ?? "50-50";
    const fullWidth = variation === "Full-width";

    const containsOnlyRichText = [leftContent, rightContent].every(
        content => content?.[0].__typename === "ContentfulContentRichText",
    );

    return (
        <_Section
            additionalCta={
                additionalCta && <CallToAction data={additionalCta} />
            }
            anchorLink={kebabCase(anchorLink || id)}
            background={theme}
            bottomSpacing={bottomSpacing}
            description={Description}
            fullWidth={fullWidth || containsMap}
            noPadding={containsMap}
            title={Title}
            topSpacing={topSpacing}
            withBorder={!fullWidth}
        >
            <_SplitSection
                containsOnlyRichText={containsOnlyRichText}
                distribution={Distribution}
                firstChild={LeftContent}
                fullWidth={containsMap || (fullWidth && containsOnlyRichText)}
                hideDivider={!hasDivider}
                reverseOrder={!highlightedSubsectionLeft}
                secondChild={RightContent}
            />
        </_Section>
    );
};

export default SplitSection;

export const query = graphql`
    fragment SplitSection on ContentfulAssemblySplitSection {
        id
        __typename
        title {
            raw
        }
        description {
            ...RichText
        }
        hasDivider
        highlightedSubsectionLeft
        # Use alias here because field conflict with OverlappingSplitSection
        leftContent: highlightedSubsection {
            ...ImageConstrained
            ...RichText
            ...Map
            ...Form
            ...ContactPoint
            ...ContactOffice
        }
        rightContent: secondarySubsection {
            ...ImageConstrained
            ...RichText
            ...Map
            ...Form
            ...ContactPoint
            ...ContactOffice
        }
        distribution
        variation
        theme
        additionalCta {
            ...CallToAction
        }
        anchorLink
    }
`;
