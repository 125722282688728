import { Footer as _Footer } from "@2po-dpam/components";
import { ErrorBoundary, Image, RichText } from "@components";
import { INVESTORS } from "@constants";
import { useHasScrolled, useRouting } from "@hooks";
import { IFooter, IFooterContainer } from "@types";
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby";
import {} from "gatsby-source-contentful/rich-text";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    language: string;
    investorType: string;
    locale: string;
};

const INVESTOR_TYPE_MAP = {
    [INVESTORS.RETAIL]: "retailFooterContent",
    [INVESTORS.END_INVESTOR]: "endInvestorFooterContent",
    [INVESTORS.INTERMEDIARY]: "intermediaryInvestorFooterContent",
};

const mapLinks = (links: any[], getPath: (slug: string) => string) =>
    links?.map(link => ({
        ...link,
        icon: link.icon?.icon,
        destination:
            link.externalLink || getPath(link.internalLink?.pageLink?.slug),
    }));

const Footer = ({ language, investorType, locale }: Props) => {
    const [hasScrolled] = useHasScrolled(100);

    const { t } = useTranslation();
    const { getPathForSlug } = useRouting();
    const {
        footers: { nodes: footers },
    }: { footers: { nodes: IFooterContainer[] } } = useStaticQuery(graphql`
        query FooterQuery {
            footers: allContentfulNavigationFooter {
                nodes {
                    ...Footer
                }
            }
        }
    `);

    const footer: IFooterContainer | undefined =
        footers.find(footer => footer.node_locale === locale) ||
        footers.find(footer => footer.node_locale === language);

    if (!footer) return null;

    const {
        marketingCommuncationDisclaimer,
        socialLinks,
        otherLinks,
        newsletterReference,
    }: IFooter = footer[INVESTOR_TYPE_MAP[investorType]];
    const { copyrightInformation, logo, companyDescription } = footer;

    return (
        <_Footer
            LinkComponent={GatsbyLink}
            companyInfo={
                <RichText
                    options={{ variant: "para-x-small", removeMargin: true }}
                    textNode={companyDescription}
                />
            }
            copyrightInfo={copyrightInformation}
            header={
                marketingCommuncationDisclaimer && (
                    <RichText textNode={marketingCommuncationDisclaimer} />
                )
            }
            links={mapLinks(otherLinks, getPathForSlug)}
            logo={<Image alt={logo?.alt} src={logo?.image?.file?.url} />}
            newsLetterReference={
                newsletterReference && (
                    <RichText
                        options={{ paraVariant: "para-x-small" }}
                        textNode={newsletterReference}
                    />
                )
            }
            showFab={hasScrolled}
            socials={mapLinks(socialLinks, getPathForSlug)}
            toTopText={t("to top")}
        />
    );
};

const WrappedFooter = (props: Props) => (
    <ErrorBoundary componentName="Footer">
        <Footer {...props} />
    </ErrorBoundary>
);

export default WrappedFooter;

export const query = graphql`
    fragment Footer on ContentfulNavigationFooter {
        id
        node_locale
        __typename
        logo {
            alt
            image {
                file {
                    url
                }
            }
        }
        companyDescription {
            ...RichText
        }
        retailFooterContent {
            ...FooterContent
        }
        endInvestorFooterContent {
            ...FooterContent
        }
        intermediaryInvestorFooterContent {
            ...FooterContent
        }
        copyrightInformation
    }
    fragment FooterContent on ContentfulNavigationInvestorSpecificFooter {
        marketingCommuncationDisclaimer {
            raw
            references {
                ...EmbeddedIcon
            }
        }
        socialLinks {
            ...CallToAction
        }
        otherLinks {
            ...CallToAction
        }
        newsletterReference {
            ...RichText
        }
    }
`;
