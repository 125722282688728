import { BrandCard as _BrandCard } from "@2po-dpam/components";
import { Image } from "@components";
import { ContentfulImageData } from "@components/Image";
import { graphql } from "gatsby";
import React from "react";

type BrandCardData = {
    name: string;
    brandLink: string;
    logo: ContentfulImageData;
};

type Props = {
    data: BrandCardData;
};

const BrandCard = ({ data: { name, brandLink, logo } }: Props) => {
    const Logo = (
        <Image {...logo} alt={logo?.alt || "Brand image"} caption="" title="" />
    );
    return <_BrandCard link={brandLink} logo={Logo} name={name} />;
};

export default BrandCard;

export const query = graphql`
    fragment BrandCard on ContentfulTopicBrand {
        id
        __typename
        contentful_id
        name
        # Alias because of conflict with other field
        brandLink: link
        logo {
            ...ImageConstrained
        }
    }
`;
