import { Columns as _Columns, Section as _Section } from "@2po-dpam/components";
import { CallToAction, RichText } from "@components";
import { THEMES } from "@constants";
import { useIsMobile, useWindowDimensions } from "@hooks";
import { isArrayWithContent } from "@utils/arrays";
import {
    componentMapper,
    matchesType,
    shortenTypename,
} from "@utils/componentMapper";
import { kebabCase } from "@utils/kebabCase";
import { isEmptyDocument } from "@utils/richText";
import classNames from "classnames";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { IColumnsSection } from "@types";
import contentfulTypenames from "../../constants/contentfulTypenames";
import * as style from "./style.module.scss";

type Props = {
    data: IColumnsSection;
};

export type MaxValues = {
    maxHeightTitleWrapper: number;
    maxHeightCards: number;
};

const getMaxValues = (cards: Array<HTMLDivElement>): MaxValues => {
    let maxHeightTitleWrapper = 0;
    let maxHeightCards = 0;
    let longestCard: HTMLDivElement | null = null;

    for (const card of cards) {
        const titleWrapper = card?.querySelector(
            ".titleWrapper",
        ) as HTMLElement;
        const titleWrapperH = titleWrapper?.offsetHeight;
        const h = card.offsetHeight;
        if (titleWrapperH > maxHeightTitleWrapper)
            maxHeightTitleWrapper = titleWrapperH;
        if (h > maxHeightCards) {
            maxHeightCards = h;
            longestCard = card;
        }
    }

    const titleWrapper = longestCard?.querySelector(
        ".titleWrapper",
    ) as HTMLElement;
    const titleWrapperH = titleWrapper?.offsetHeight;
    const diff = maxHeightTitleWrapper - titleWrapperH;
    maxHeightCards += diff;

    return {
        maxHeightTitleWrapper,
        maxHeightCards,
    };
};

const ColumnsSection = ({
    data: {
        additionalCta,
        amountOfColumns,
        amountOfColumnsMobile = 1,
        anchorLink,
        bottomSpacing,
        content,
        contentAlignment,
        description,
        enableLoadMore: loadMore,
        id,
        theme,
        title,
        topSpacing,
    },
}: Props) => {
    const { windowWidth } = useWindowDimensions();
    const sustainabilityCards = useRef<Array<HTMLDivElement>>([]);
    const [cardsMeasurements, setCardsMeasurements] =
        useState<MaxValues | null>(null);
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const background = ["primary", "white", "gray"].includes(theme)
        ? theme
        : "primary";
    const sectionTheme = THEMES[theme];

    const titleEl = !isEmptyDocument(title) ? (
        <RichText
            options={{ variant: "h2" }}
            textNode={{ text: { ...title, references: [] } }}
        />
    ) : null;
    const descriptionEl =
        description && !isEmptyDocument(description.text) ? (
            <RichText textNode={description} />
        ) : null;

    const containsSubAssetCards = content?.every(child =>
        matchesType(shortenTypename(child), contentfulTypenames.SUB_ASSET_CARD),
    );

    const containsSustainabilityCards = content?.some(child =>
        matchesType(
            shortenTypename(child),
            contentfulTypenames.SUSTAINABILITY_CARD,
        ),
    );

    useEffect(() => {
        document.fonts.ready.then(() => {
            let _cardMeasurements: MaxValues | null = null;
            if (isArrayWithContent(sustainabilityCards.current))
                _cardMeasurements = getMaxValues(sustainabilityCards.current);

            setCardsMeasurements(_cardMeasurements);
        });
    }, [windowWidth]);

    return (
        <>
            <_Section
                additionalCta={
                    additionalCta && <CallToAction data={additionalCta} />
                }
                anchorLink={kebabCase(anchorLink || id)}
                background={background}
                bottomSpacing={bottomSpacing}
                className={classNames({
                    [style[sectionTheme]]: sectionTheme,
                    [style.containsSubAssetCards]:
                        isMobile && containsSubAssetCards,
                })}
                contentAlignment={contentAlignment}
                description={descriptionEl}
                fullWidth={isMobile && containsSubAssetCards}
                title={titleEl}
                topSpacing={topSpacing}
                withBorder={!(isMobile && containsSubAssetCards)}
            >
                <_Columns
                    columns={amountOfColumns}
                    columnsMobile={amountOfColumnsMobile}
                    loadMore={{ ...loadMore, text: t("load more") }}
                >
                    {content?.map(child =>
                        componentMapper(child, {
                            stretch: true,
                            noUnderline: true,
                            columnSection: true,
                            objectFit: "contain",
                            cardMeasurements: cardsMeasurements,
                            className: containsSubAssetCards
                                ? "mt-0"
                                : undefined,
                        }),
                    )}
                </_Columns>
            </_Section>
            {containsSustainabilityCards && (
                <_Section
                    additionalCta={
                        additionalCta && <CallToAction data={additionalCta} />
                    }
                    anchorLink={kebabCase(anchorLink || id)}
                    background={background}
                    className={classNames(style.section, style.ghostSection, {
                        [style[sectionTheme]]: sectionTheme,
                        [style.containsSubAssetCards]:
                            isMobile && containsSubAssetCards,
                    })}
                    contentAlignment={contentAlignment}
                    description={descriptionEl}
                    fullWidth={isMobile && containsSubAssetCards}
                    title={titleEl}
                    withBorder={!(isMobile && containsSubAssetCards)}
                >
                    <_Columns
                        columns={amountOfColumns}
                        columnsMobile={amountOfColumnsMobile}
                        loadMore={{ ...loadMore, text: t("load more") }}
                    >
                        {content?.map((child, i) =>
                            componentMapper(
                                child,
                                {
                                    stretch: true,
                                    noUnderline: true,
                                    columnSection: true,
                                    objectFit: "contain",
                                    shadowDom: true,
                                    className: containsSubAssetCards
                                        ? "mt-0"
                                        : undefined,
                                    ref:
                                        shortenTypename(child) ===
                                        contentfulTypenames.SUSTAINABILITY_CARD
                                            ? (el: HTMLDivElement) =>
                                                  (sustainabilityCards.current[
                                                      i
                                                  ] = el)
                                            : null,
                                },
                                child.id,
                            ),
                        )}
                    </_Columns>
                </_Section>
            )}
        </>
    );
};

export default ColumnsSection;

export const query = graphql`
    fragment ColumnsSection on ContentfulAssemblyColumnsSection {
        id
        __typename
        amountOfColumns
        amountOfColumnsMobile
        theme
        topSpacing
        bottomSpacing
        title {
            raw
        }
        description {
            ...RichText
        }
        content {
            ...SubAssetClassCard
            ...RichText
            ...ThumbnailLink
            ...Document
            ...ReportHighlight
            ...IconNumberCard
            ...BrandCard
            ...NumberCard
            ...Award
            ...ImageConstrained
            ...SustainabilityCard
            ...CardIcon
        }
        additionalCta {
            ...CallToAction
        }
        anchorLink
        contentAlignment
        enableLoadMore {
            desktopItemsBeforeLoadMore
            mobileItemsBeforeLoadMore
        }
    }
`;
