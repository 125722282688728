/* eslint-disable sonarjs/cognitive-complexity */
import { Overlap as _Overlap, Section as _Section } from "@2po-dpam/components";
import { CallToAction, EventCard, Image, RichText } from "@components";
import { CONTENT_TYPES } from "@constants";
import { IEvent, IOverlappingSplitSection, IRichText } from "@types";
import { matchesType, shortenTypename } from "@utils/componentMapper";
import { kebabCase } from "@utils/kebabCase";
import { isEmptyDocument } from "@utils/richText";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";
import * as style from "./style.module.scss";

type Props = {
    data: IOverlappingSplitSection;
};

const OverlapSection = ({
    data: {
        additionalCta,
        anchorLink,
        bottomSpacing,
        description,
        highlightedSubsection,
        highlightedSubsectionLeft,
        id,
        internalName,
        secondarySubsection,
        theme,
        title,
        topSpacing,
        variation,
    },
}: Props) => {
    const _id = internalName
        ? internalName.split(" ").join("_")
        : id || "OverlapSection";

    const isFullWidth = variation === "Full-width";

    const titleEl = !isEmptyDocument(title) ? (
        <RichText
            className={description ? style.titleWithDescription : style.title}
            options={{ variant: "h2" }}
            textNode={{ text: title }}
        />
    ) : null;

    const descriptionEl =
        description && !isEmptyDocument(description.text) ? (
            <RichText
                className={style.description}
                options={{
                    embedClassNames: { callToAction: style.description },
                }}
                textNode={description}
            />
        ) : null;

    const imageEl = highlightedSubsection?.image ? (
        <Image
            {...highlightedSubsection}
            // Hide title and caption
            alt={highlightedSubsection?.alt || "image highlight"}
            caption=""
            title=""
        />
    ) : null;

    const Content = () => {
        if (!secondarySubsection) return null;

        const typename = shortenTypename(secondarySubsection);

        if (matchesType(typename, CONTENT_TYPES.EVENT)) {
            return <EventCard data={secondarySubsection as IEvent} />;
        }

        if (matchesType(typename, CONTENT_TYPES.RICHTEXT)) {
            const rteEntry = secondarySubsection as IRichText;

            return !isEmptyDocument(rteEntry?.text) ? (
                <RichText
                    options={{
                        embedClassNames: { callToAction: style.embeddedCta },
                    }}
                    textNode={rteEntry}
                />
            ) : null;
        }

        return null;
    };

    return (
        <_Section
            additionalCta={
                additionalCta && <CallToAction data={additionalCta} />
            }
            anchorLink={kebabCase(anchorLink || id)}
            background={theme}
            bottomSpacing={bottomSpacing}
            className={classNames(style.wrapper, {
                [style.noPaddingMobile]:
                    !isFullWidth && !titleEl && !descriptionEl,
                [style.noXPaddingMobile]:
                    !isFullWidth && (titleEl || descriptionEl),
            })}
            description={descriptionEl}
            fullWidth={isFullWidth}
            id={id}
            noPadding={isFullWidth}
            title={titleEl}
            topSpacing={topSpacing}
            withBorder={!isFullWidth}
        >
            <_Overlap
                ImageComponent={imageEl}
                background={(secondarySubsection as IEvent)?.theme}
                className={
                    titleEl || descriptionEl ? style.topMargin : undefined
                }
                fullWidth={isFullWidth}
                highlightLeft={highlightedSubsectionLeft}
                id={_id}
            >
                <Content />
            </_Overlap>
        </_Section>
    );
};

export default OverlapSection;

export const query = graphql`
    fragment OverlapSection on ContentfulAssemblyOverlappingSplitSection {
        id
        __typename
        internalName
        highlightedSubsectionLeft
        theme
        topSpacing
        bottomSpacing
        variation
        title {
            raw
        }
        description {
            ...RichText
        }
        highlightedSubsection {
            ...ImageFullWidth
        }
        secondarySubsection {
            ...RichText
            ...Event
        }
        additionalCta {
            ...CallToAction
        }
        anchorLink
    }
`;
