import { NewsCard as _Card } from "@2po-dpam/components";
import CallToAction from "@components/CallToAction";
import Image, { ContentfulImageData } from "@components/Image";
import RichText from "@components/RichText";
import { NewsType } from "@constants";
import { useContextStore, useInvestorContentWithSlug } from "@hooks";
import {
    getNewsTypeIcon,
    getNewsTypeLabel,
    mapContentfulNewsType,
} from "@utils/newsTypes";
import { getPublishDateLongDateFormatted } from "@utils/pages";
import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

type NewsCardProps = {
    updatedAt: string;
    createdAt: string;
    publishDate: string;
    contentful_id: string;
    shortTitle: {
        raw: string;
    };
    introText: {
        raw: string;
    };
    hero: {
        backgroundImage: ContentfulImageData;
    };
    type: NewsType[];
    destination?: string;
    slug?: string;
};

type Props = {
    data: {
        id: string;
        contentful_id?: string;
        generalContent: NewsCardProps;
        professionalEndInvestorContent: {
            content: NewsCardProps;
        };
        professionalIntermediaryContent: {
            content: NewsCardProps;
        };
        retailContent: {
            content: NewsCardProps;
        };
    };
    preview?: boolean;
    options?: any;
};

const NewsCard = ({ data, options }: Props) => {
    const { t } = useTranslation();
    const store = useContextStore();
    const invContent: NewsCardProps = useInvestorContentWithSlug(data);
    if (!invContent) {
        return null;
    }
    const {
        hero,
        shortTitle,
        introText,
        publishDate,
        updatedAt,
        createdAt,
        type,
        destination,
        slug,
    } = invContent;

    const getImage = () => {
        if (!hero?.backgroundImage) return null;

        const { image, alt, id } = hero.backgroundImage;

        return <Image alt={alt} id={id} image={image} />;
    };

    const publicationDate = getPublishDateLongDateFormatted(
        publishDate,
        createdAt,
        updatedAt,
        store.language,
    );

    const newsType = mapContentfulNewsType(type) || "";

    return (
        <_Card
            Cta={<CallToAction href={slug}>{t("read more")}</CallToAction>}
            className={options?.className}
            destination={destination}
            image={getImage()}
            introText={
                <RichText
                    options={{ inline: true, characterLimit: 80 }}
                    textNode={introText}
                />
            }
            newsType={t(getNewsTypeLabel(newsType))}
            newsTypeIcon={getNewsTypeIcon(newsType)}
            publishDate={publicationDate}
            title={
                <RichText
                    options={{ inline: true, characterLimit: 80 }}
                    textNode={shortTitle}
                />
            }
        />
    );
};

export default NewsCard;

export const query = graphql`
    fragment NewsCard on ContentfulPageTypeNewsOrAnnouncementPage {
        id
        __typename
        contentful_id
        generalContent {
            ...NewsCardContent
        }
        professionalEndInvestorContent {
            content {
                ...NewsCardContent
            }
        }
        professionalIntermediaryContent {
            content {
                ...NewsCardContent
            }
        }
        retailContent {
            content {
                ...NewsCardContent
            }
        }
    }
    fragment NewsCardContent on ContentfulPageContentNewsAndAnnouncements {
        id
        contentful_id
        __typename
        updatedAt
        publishDate
        createdAt
        hero {
            backgroundImage {
                ...ImageConstrained
            }
        }
        introText {
            raw
        }
        shortTitle {
            raw
        }
        type {
            id
            name
            values
        }
    }
`;
