import { useCanonicalUrl, useRouting } from "@hooks";
import { useLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

type Props = {
    lang: string;
    data: {
        title?: string;
        description?: string;
        keywords?: string[];
        no_index?: boolean;
        no_follow?: boolean;
    };
    theme: string;
    hero: any;
    canonicalUrl: string;

    // optional
    pageType?: string;
    siteName?: string;
};

export type MetaData = {
    title: string;
    description: string;
    keywords: string[];
};
export const getMetaData = (pageData, seoData): MetaData => {
    const title = seoData?.title || pageData?.title || "";
    if (seoData) return { ...seoData, title };
    return { title, description: "", keywords: [] };
};

const getHeroData = (hero: any) => {
    if (!hero || !hero?.backgroundImage) return undefined;
    const backgroundImage = hero.backgroundImage || hero.backgroundImages[0];
    return {
        alt: backgroundImage.alt || "",
        mimeType: backgroundImage.image?.mimeType,
        imageUrl: backgroundImage.image?.url,
        width: backgroundImage.image?.width,
        height: backgroundImage.image?.height,
    };
};
const getRobotsText = (no_index?: boolean, no_follow?: boolean) => {
    if (no_index && no_follow) return "noindex,nofollow";
    if (!no_index && no_follow) return "index,nofollow";
    if (no_index && !no_follow) return "noindex,follow";
    return "index,follow";
};

const PageHead = ({
    lang,
    data,
    theme,
    hero,
    canonicalUrl,
    pageType = "website",
    siteName = "Degroof Petercam Asset Management",
}: Props) => {
    const { title, description, keywords, no_follow, no_index } = data;
    const { alt, imageUrl, mimeType, width, height } = getHeroData(hero) || {};
    const robotsMetaValue = getRobotsText(no_index, no_follow);
    const location = useLocation();
    const { displayCanonical } = useCanonicalUrl();
    const { getAlternateLanguagePaths } = useRouting();
    const hrefLangs = getAlternateLanguagePaths();

    return (
        <Helmet>
            <html lang={lang} />
            <title>{title}</title>
            <meta content={lang} name="og:locale" />
            <meta content={title} name="og:title" />
            <meta content={title} name="twitter:title" />
            <meta content={description} name="og:description" />
            <meta content={description} name="twitter:description" />
            <meta content={description} name="description" />
            <meta content={imageUrl} name="og:image" />
            <meta content={width} name="og:image:width" />
            <meta content={height} name="og:image:height" />
            <meta content={mimeType} name="og:image:type" />
            <meta content={imageUrl} name="twitter:image" />
            <meta content={alt} name="og:image:alt" />
            <meta content={alt} name="twitter:image:alt" />
            <meta content={canonicalUrl} name="og:url" />
            <meta content="summary_large_image" name="twitter:card" />
            <meta content={keywords && keywords.join(", ")} name="keywords" />
            <meta
                content="width=device-width, initial-scale=1.0"
                name="viewport"
            />
            <meta content={robotsMetaValue} name="robots" />
            <meta content={robotsMetaValue} name="googlebot" />
            {hrefLangs &&
                Object.entries(hrefLangs).map(([language, path]) => (
                    <link
                        href={location.origin + path}
                        hrefLang={language}
                        key={`meta-hreflang-${language}`}
                        rel="alternate"
                    />
                ))}
            {pageType && <meta content={pageType} name="og:type" />}
            {siteName && <meta content={siteName} name="og:site_name" />}
            <body data-theme={theme} />
            {displayCanonical && <link href={canonicalUrl} rel="canonical" />}
        </Helmet>
    );
};

export default PageHead;

export const query = graphql`
    fragment SEO on ContentfulComposeSeo {
        id
        description
        noInternalIndex
        no_follow
        no_index
        title
        searchKeywords
        keywords
        name
    }
`;
