import { ContactPoint as _ContactPoint } from "@2po-dpam/components";
import { graphql } from "gatsby";
import React from "react";

export type ContactOfficeData = {
    email: string;
    address?: string;
    cityName?: string;
    phoneNumber?: string;
};

type Props = {
    data: ContactOfficeData;
};

const ContactOffice = ({
    data: { email, address, cityName, phoneNumber },
}: Props) => (
    <_ContactPoint
        address={address}
        email={email}
        phone={phoneNumber}
        title={cityName}
    />
);

export default ContactOffice;

export const query = graphql`
    fragment ContactOffice on ContentfulTopicOffice {
        id
        __typename
        contentful_id
        address
        email
        phoneNumber
        cityName
    }
`;
