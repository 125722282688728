import { Modal, Typography } from "@2po-dpam/components";
import {
    OnboardingPopupFields,
    OnboardingPopupStepper,
    RichText,
} from "@components";
import {
    useBrowserLanguage,
    useCookies,
    useFocusTrap,
    useInvestorLabels,
    useIsMobile,
    useMarketsLanguages,
    usePageContext,
    usePopupData,
    useRouting,
} from "@hooks";
import { useLocation } from "@reach/router";
import { CookieKey, OnboardOptions } from "@types";
import { graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const getProgressBars = (progress: number) => {
    const list = Array(4).fill({ activated: false });
    return list.map((el, idx) =>
        idx <= progress - 1
            ? { activated: true, current: idx === progress - 1 }
            : el,
    );
};

const getFocusableElements = (id: string) => [
    `${id}-select-investortype-trigger`,
    `${id}-select-market-trigger`,
    `${id}-select-language-trigger`,
    `${id}-btn-submit`,
];

type Props = {
    isOpen: boolean;
    id?: string;
    onClose: () => void;
    currentLanguage: string;
};

const OnboardingPopup = ({
    id = "OnboardingPopup",
    isOpen,
    onClose,
    currentLanguage,
}: Props) => {
    const { setCookie } = useCookies();
    const isMobile = useIsMobile();
    const { i18n } = useTranslation();
    const location = useLocation();
    const { marketLanguages } = usePageContext();
    const { getPath } = useRouting();
    const [formValues, setFormValues] = useState({
        investorType: "",
        market: "",
        language: "",
    });
    const popupData = usePopupData(formValues.language, formValues.market);
    const { getLabels } = useInvestorLabels();
    const { getLanguage } = useBrowserLanguage();
    const [touched, setTouched] = useState<OnboardOptions[]>([]);
    const [progress, setProgress] = useState(1);

    const { markets, languages } = useMarketsLanguages(
        marketLanguages,
        currentLanguage,
        formValues.market,
    );
    const investorTypes = Object.entries(getLabels(formValues?.market)).map(
        ([key, value]) => ({
            label: value,
            value: key,
        }),
    );

    useFocusTrap(getFocusableElements(id), id, isOpen);

    const progressBars = getProgressBars(progress);

    const markTouched = (field: OnboardOptions) =>
        !touched.includes(field) && setTouched([...touched, field]);

    const handleChangeMarket = (market: string) => {
        if (isMobile) {
            setTouched(["market"]);
            setFormValues({
                market,
                investorType: "",
                language: "",
            });
            return;
        }

        setTouched(["market", "language"]);
        setFormValues({
            market,
            investorType: "",
            language: getLanguage(market),
        });
    };

    const handleChangeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        markTouched("language");
        setFormValues({ ...formValues, language });
    };

    const handleChangeInvestortype = (investorType: string) => {
        markTouched("investorType");
        setFormValues({ ...formValues, investorType });
    };

    useEffect(() => {
        if (touched.includes("investorType")) {
            setProgress(4);
        } else if (touched.includes("language")) {
            setProgress(3);
        } else if (touched.includes("market")) {
            setProgress(2);
        } else {
            setProgress(1);
        }
    }, [touched]);

    useEffect(() => {
        i18n?.changeLanguage?.(formValues.language || "en");
    }, [formValues.language]);

    const handleSetPreferences = () => {
        Object.entries(formValues).forEach(([key, value]) =>
            setCookie(key as CookieKey, value),
        );

        const isMarketPt = formValues.market === "pt";
        const marketToUse = isMarketPt ? "be" : formValues.market;

        const newPath = getPath({
            investor: formValues.investorType,
            market: marketToUse,
            language: formValues.language,
        });
        onClose();
        if (newPath !== location?.pathname) navigate(newPath);
    };

    const {
        title,
        buttonLabel,
        countryLabel,
        languageLabel,
        investorTypeLabel,
        disclaimerText,
        introductionText,
    } = popupData;

    const defaultOnboardingProps = {
        buttonLabel,
        countryLabel,
        currentLanguage,
        disclaimerText,
        formValues,
        handleChangeInvestortype,
        handleChangeLanguage,
        handleChangeMarket,
        handleSetPreferences,
        investorTypeLabel,
        investorTypes,
        languageLabel,
        languages,
        markets,
        touched,
    };

    return (
        <Modal closable={false} id={id} isOpen={isOpen}>
            <Typography color="main" variant="h4">
                {title}
            </Typography>
            <RichText
                options={{ color: "main" }}
                textNode={{ text: introductionText }}
            />
            {isMobile ? (
                <OnboardingPopupStepper {...defaultOnboardingProps} />
            ) : (
                <OnboardingPopupFields
                    {...defaultOnboardingProps}
                    progress={progress}
                    progressBars={progressBars}
                />
            )}
        </Modal>
    );
};

export default OnboardingPopup;

export const query = graphql`
    fragment IntroPopup on ContentfulConfigIntroductionaryPopup {
        node_locale
        id
        title
        introductionText {
            raw
        }
        countryLabel
        languageLabel
        investorTypeLabel
        disclaimerText {
            raw
            references {
                ...EmbeddedCTA
            }
        }
        buttonLabel
    }
`;
